import React from "react";
import {
  Grid,
  Typography,
  Box,
  Container,
  Card,
  CardContent,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
} from "@mui/material";
import featureImage from "../../assets/featureImage2.png";
import xanderLogo from "../../assets/xander_logo_big-removebg-preview.png";
import { Button } from "@mui/material";
import featureImage6 from "../../assets/featureImage6.jpg";
import dogeImage from "../../assets/coinImage20.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Header, Footer } from "../../screens/components";
import coinImage11 from "../../assets/coinImage11.png";
import backgroundImage6 from "../../assets/backgroundImage6.png";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import { useTheme } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import backgroundImage from "../../assets/removedBackground3.png";
import { useNavigate } from "react-router";

const CryptoInfoCard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const learnMore = () => {
    navigate("/faq");
    window.scrollTo(0, 0);
  };

  const moveToSell = () => {
    navigate("/sell_crypto");
    window.scrollTo(0, 0);
  };

  return (
    <Container maxWidth="xl">
      <Card
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: isMobile ? "column" : "row",
          p: 2,
          backgroundColor: "#ffa3a3",
          opacity: 0.8,
          borderRadius: "20px",
          boxShadow: 3,
          mt: 5,
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", mb: isMobile ? 2 : 0 }}
        >
          <EmailIcon
            sx={{ fontSize: 40, color: "black", mr: isMobile ? 0 : 2 }}
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            textAlign: isMobile ? "center" : "left",
            mb: isMobile ? 2 : 0,
          }}
        >
          <Typography variant="h6" component="div">
            Unlock the Value of Your Crypto Assets
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Sell your crypto quickly and securely with our platform
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "black",
              color: "white",
              mr: 1,
              borderRadius: "8px",
              textDecoration: "none",
              textTransform: "none",
              mb: isMobile ? 1 : 0,
            }}
            onClick={moveToSell}
          >
            Sell
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: "black",
              borderColor: "black",
              mr: 1,
              borderRadius: "8px",
              textDecoration: "none",
              textTransform: "none",
            }}
            onClick={learnMore}
          >
            Learn More
          </Button>
        </Box>
        <IconButton>
          <CloseIcon />
        </IconButton>
      </Card>
    </Container>
  );
};

const SellCryptoNow = ({}) => {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        backgroundImage: `url(${backgroundImage6})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        marginTop: { xs: 10, sm: 1 },
        paddingY: { xs: 8, sm: 20 },
        paddingX: { xs: 3, sm: 15 },
      }}
    >
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom>
            <i>Sell Your Crypto</i>
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ fontSize: 18 }}>
            Easily convert your cryptocurrency into cash with our secure and
            efficient service.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const BuyCryptoSecurely = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        paddingY: { xs: 4, sm: 8 },
        paddingX: { xs: 3, sm: 15 },
        background: "#fff",
      }}
    >
      <Grid container spacing={4} alignItems="center">
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ textAlign: { xs: "center", sm: "left" } }}
        >
          <img
            src={featureImage}
            alt="Doge"
            style={{
              width: matches ? "550px" : "380px",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography marginY={2} variant="h3" gutterBottom>
            Unlock the Power of Selling Crypto with Ease and Security
          </Typography>
          <Typography
            marginY={3}
            variant="body1"
            sx={{ fontSize: 18 }}
            gutterBottom
          >
            Selling your cryptocurrency on our platform is a breeze. We
            prioritize simplicity and security to ensure a seamless experience
            for our users.
          </Typography>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} sm={6} textAlign="left">
              <Typography
                variant="h2"
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "36px", sm: "48px" },
                }}
                gutterBottom
              >
                100%
              </Typography>
              <Typography variant="body1" sx={{ fontSize: "18px" }}>
                Receive secure payments directly to your preferred account.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} textAlign="left">
              <Typography
                variant="h2"
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "36px", sm: "48px" },
                }}
                gutterBottom
              >
                100%
              </Typography>
              <Typography variant="body1" sx={{ fontSize: "18px" }}>
                Receive secure payments directly to your preferred account.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const SimplifyCrypto = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        paddingY: { xs: 1, sm: 2 },
        paddingX: { xs: 3, sm: 15 },
        background: "#fff",
      }}
    >
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} sm={6}>
          <Typography marginY={2} variant="h3" gutterBottom>
            Simplify Crypto Selling with Our Easy-to-Use Platform
          </Typography>
          <Typography
            marginY={3}
            variant="body1"
            sx={{ fontSize: "18px" }}
            gutterBottom
          >
            With our platform, you can sell your crypto quickly and securely.
            Enjoy hassle-free transactions and get cash in your account in no
            time.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ textAlign: { xs: "center", sm: "right" } }}
        >
          <img
            src={featureImage6}
            alt="Doge"
            style={{
              width: matches ? "600px" : "350px",
              borderRadius: 12,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const StartSellingNow = ({}) => {
  const matches = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();

  const learnMore = () => {
    navigate("/faq");
    window.scrollTo(0, 0);
  };

  const moveToSell = () => {
    navigate("/sell_crypto");
    window.scrollTo(0, 0);
  };

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        backgroundImage: `url(${backgroundImage6})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        paddingY: { xs: 8, sm: 20 },
        paddingX: { xs: 3, sm: 15 },
      }}
    >
      <Card
        sx={{
          paddingX: 1,
          paddingY: 8,
          backgroundColor: "transparent",
          borderRadius: "16px",
          border: "1px solid black",
        }}
      >
        <CardContent>
          <Typography variant="h4" align="center" gutterBottom>
            Start Selling Your Cryptocurrency
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{ mb: 3, fontSize: 18 }}
          >
            Convert your crypto into cash quickly and securely on our platform.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <Button
              variant="contained"
              sx={{
                borderRadius: "15px",
                textTransform: "none",
                color: "white",
                paddingX: 2,
                paddingY: 1,
                fontSize: 16,
                minWidth: 100,
                backgroundColor: "#000",
              }}
              onClick={moveToSell}
            >
              Sell
            </Button>
            <Button
              variant="outlined"
              color="primary"
              sx={{
                borderRadius: "15px",
                textTransform: "none",
                borderColor: "#000",
                paddingX: 2,
                paddingY: 1,
                minWidth: 120,
                fontSize: 16,
                color: "#000",
              }}
              onClick={learnMore}
            >
              Learn More
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

const ExchangeCryptoComponent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container maxWidth="xl">
      <Card
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          p: 3,
          backgroundColor: "#FFFFFF",
          borderRadius: "16px",
          boxShadow: 3,
          border: "1px solid black",
          mt: 3,
        }}
      >
        <CardContent
          sx={{
            flexGrow: 1,
            textAlign: isMobile ? "center" : "left",
            mb: isMobile ? 2 : 0,
          }}
        >
          <Typography variant="h4" component="div" sx={{ mb: 2 }}>
            Why Exchange Your Crypto with Us?
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ mb: 3, fontSize: 18 }}
          >
            Exchange your cryptocurrencies with ease and convenience on our
            platform, providing you with access to a wide range of digital
            assets.
          </Typography>
          <Button
            variant="outlined"
            sx={{
              color: "black",
              borderColor: "black",
              textTransform: "none",
              borderRadius: "15px",
            }}
          >
            Learn More
          </Button>
        </CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: isMobile ? "100%" : "auto",
          }}
        >
          <img
            src={coinImage11}
            alt="Crypto Icon"
            style={{
              maxWidth: "100%",
              height: "auto",
              borderRadius: "50%",
              width: isMobile ? "50%" : "auto",
            }}
          />
        </Box>
      </Card>
    </Container>
  );
};

const faqQuestions = [
  {
    question: "How to sell crypto?",
    answer:
      "To sell crypto, you need to create an account, select the crypto you want to sell, enter the amount, and follow the instructions to complete the transaction.",
  },
  {
    question: "What are the fees?",
    answer:
      "Our fees vary depending on the type and amount of crypto you are selling. You can find detailed information about our fees on our website.",
  },
  {
    question: "How long does it take?",
    answer:
      "The time it takes to sell crypto depends on various factors, including network congestion and the type of crypto. Generally, it takes a few minutes to a few hours.",
  },
  {
    question: "Is it safe to sell?",
    answer:
      "We prioritize the security of our users' transactions. Our platform is built with advanced security measures to ensure the safety of your crypto assets.",
  },
  {
    question: "What if I make a mistake?",
    answer:
      "If you make a mistake while selling crypto, please contact our support team immediately. We will assist you in resolving any issues or errors.",
  },
];

const FAQComponent1 = () => {
  const navigate = useNavigate();
  const moveToSupport = () => {
    navigate("/support");
    window.scrollTo(0, 0);
  };

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "visible",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "-300px -50px",
        backgroundPosition: "top",
        //minHeight: "100vh",
        padding: 5,
        zIndex: 4,
      }}
    >
      <Container maxWidth="xl">
        <Typography variant="h3" component="h1" gutterBottom>
          Sell
        </Typography>
        <Typography
          variant="subtitle1"
          component="p"
          sx={{ marginBottom: 10, fontSize: 18 }}
          gutterBottom
        >
          Find answers to common questions about selling crypto on our platform.
        </Typography>

        {faqQuestions.map((e, index) => {
          return (
            <Accordion
              key={index}
              sx={{
                backgroundColor: "transparent",
                borderTop: "1px solid #000",
                borderBottom: "1px solid #000",
                borderLeft: "none",
                borderRight: "none",
              }}
              defaultExpanded
              disableGutters
              square
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  backgroundColor: "transparent",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                <Typography
                  sx={{ fontSize: 21 }}
                  variant="subtitle1"
                  fontWeight="bold"
                >
                  {e.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: "transparent",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                <Typography sx={{ fontSize: 18 }}>{e.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
        <Card
          sx={{
            p: 3,
            backgroundColor: "transparent",
            border: "none",
            boxShadow: 0,
          }}
        >
          <CardContent>
            <Typography variant="h4" align="left" gutterBottom>
              Still have questions?
            </Typography>
            <Typography
              variant="body1"
              align="left"
              sx={{ mb: 3, fontSize: 18 }}
            >
              Contact our support team for further assistance.
            </Typography>
            <Button
              onClick={moveToSupport}
              variant="outlined"
              sx={{
                color: "black",
                borderColor: "black",
                borderRadius: "14px",
                textTransform: "none",
                fontSize: 18,
              }}
            >
              Contact
            </Button>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

const SellCryptoPage = () => {
  return (
    <>
      <Header />
      <SellCryptoNow />
      <BuyCryptoSecurely />
      <ExchangeCryptoComponent />
      <CryptoInfoCard />
      <SimplifyCrypto />
      <StartSellingNow />
      <FAQComponent1 />
      <Footer />
    </>
  );
};

export default SellCryptoPage;
