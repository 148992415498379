import React, { useState } from "react";
import DenseTransactionsTable from "./DenseTransactionsTable";
import LiteTransactionsTable from "./LiteTransactionsTable";
import { INITIALIZE_TRANSACTION } from "../../config/urls";
import { getTokenFromStorage } from "../../modules/utilities";
import { fetchData } from "../../modules/utilities/util_query";
import { CircularProgress, Box, Alert, Snackbar } from "@mui/material";
import { ALERT_TYPES } from "../../config/alerts";
import { extractErrorMessage } from "../../modules/utilities/responseHandlers";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import lodashDebounce from "lodash.debounce";
import tableBackgroundQImage from "../../assets/tableBackgroundQ.png";
import tableBackgroundCImage from "../../assets/tableBackgroundC.png";

const rowsPerPage = 5;

const statusColors = {
  confirmed: "success",
  settled: "primary",
  pending: "warning",
  rejected: "error",
  review: "info",
  initiated: "secondary",
};

const TransactionsComponent = () => {
  const [view, setView] = useState("dense");
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState("");

  let payload_data = {};
  const token = getTokenFromStorage();
  const result = useQuery({
    queryKey: [
      "transactions",
      {
        url: `${INITIALIZE_TRANSACTION}?start_date=${filterStartDate}&end_date=${filterEndDate}&search_term=${debouncedSearchText}`, // this url also serves as the GET transactions URL
        payload_data,
        authenticate: true,
        token,
      },
    ],
    queryFn: fetchData,
    retry: false,
    select: (res) => {
      let transactions = res?.data?.detail?.data?.transactions || [];
      return { transactions };
    },
    onError: (error) => {},
  });

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const debouncedHandleSearch = React.useCallback(
    lodashDebounce((nextValue) => {
      setDebouncedSearchText(nextValue);
    }, 500),
    []
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const toggleFilterDialog = (desc) => {
    // if the filter dialog is open, and
    // you close it, ensure to clear all the date fields filters
    if (filterOpen && desc != "apply") {
      setFilterEndDate("");
      setFilterStartDate("");
      setStartDate("");
      setEndDate("");
    }
    setFilterOpen(!filterOpen);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const applyFilters = () => {
    toggleFilterDialog("apply");

    setFilterStartDate(startDate);
    setFilterEndDate(endDate);
  };

  // Calculate the current slice of data to display
  const currentData = transactions.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  React.useEffect(() => {
    let transactions = result?.data?.transactions || [];
    setTransactions(transactions);
  }, [result?.data?.transactions]);

  React.useEffect(() => {
    debouncedHandleSearch(searchText);
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchText, debouncedHandleSearch]);

  const refreshData = () => {
    result.refetch();
  };
  const setDenseView = () => {
    setView("dense");
  };
  const setLiteView = () => {
    setView("lite");
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${tableBackgroundQImage})`,
        backgroundSize: "contain",
        backgroundPosition: "center bottom", // This aligns the image to the bottom
        backgroundRepeat: "no-repeat",
        minHeight: "90vh",
        padding: "20px",
        position: "relative", // This ensures the content is positioned relative to this container
      }}
    >
      <Box
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={severity}>
            {message}
          </Alert>
        </Snackbar>
        {result?.isPending ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {view === "dense" && (
              <DenseTransactionsTable
                setLiteView={setLiteView}
                transactions={transactions}
                refreshData={refreshData}
                statusColors={statusColors}
              />
            )}
            {view === "lite" && (
              <LiteTransactionsTable
                setDenseView={setDenseView}
                transactions={transactions}
                refreshData={refreshData}
                statusColors={statusColors}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default TransactionsComponent;
