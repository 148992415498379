import React, { useState } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Button,
  Paper,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import transactionArrowVerticalImage from "../../assets/transactionArrowVertical.png";
import transactionArrowHorizontalImage from "../../assets/transactionArrowHorizontal.png";

const ExchangeComponent = () => {
  const [selectedSenderOption, setSelectedSenderOption] = useState("CRYPTO");
  const [selectedReceiverOption, setSelectedReceiverOption] =
    useState("CRYPTO");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSenderChange = (event) => {
    setSelectedSenderOption(event.target.value);
  };

  const handleReceiverChange = (event) => {
    setSelectedReceiverOption(event.target.value);
  };

  return (
    <Box sx={{ margin: "auto", p: { xs: 1, sm: 2 } }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: { xs: 2, sm: 5 },
          mb: { xs: 2, sm: 3 },
        }}
      >
        <Typography
          variant={isMobile ? "h6" : "h5"}
          sx={{ fontWeight: "bold" }}
        >
          Exchange
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "center",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Select
          value={selectedSenderOption}
          onChange={handleSenderChange}
          displayEmpty
          IconComponent={() => <KeyboardArrowDownIcon />}
          sx={{
            height: "40px",
            borderColor: "green",
            color: "green",
            textTransform: "none",
            borderRadius: "20px",
            px: 2,
            mx: 2,
            my: 2,
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "green",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "green",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "green",
            },
            "& .MuiSelect-select": {
              paddingRight: "32px !important",
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                borderRadius: "10px",
                marginTop: "5px",
              },
            },
          }}
        >
          <MenuItem value="CRYPTO">CRYPTO</MenuItem>
          <MenuItem value="CASH">CASH</MenuItem>
        </Select>
        <Box
          component="img"
          src={transactionArrowVerticalImage}
          alt="Vertical Arrow"
          sx={{ width: 40, height: 38 }}
        />
        <Select
          value={selectedReceiverOption}
          onChange={handleReceiverChange}
          displayEmpty
          IconComponent={() => <KeyboardArrowDownIcon />}
          sx={{
            height: "40px",
            borderColor: "green",
            color: "green",
            textTransform: "none",
            borderRadius: "20px",
            px: 2,
            mx: 2,
            my: 1,
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "green",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "green",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "green",
            },
            "& .MuiSelect-select": {
              paddingRight: "32px !important",
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                borderRadius: "10px",
                marginTop: "5px",
              },
            },
          }}
        >
          <MenuItem value="CRYPTO">CRYPTO</MenuItem>
          <MenuItem value="CASH">CASH</MenuItem>
        </Select>
      </Box>

      <Paper
        elevation={0}
        sx={{
          p: 2,
          mb: 2,
          border: "1px solid",
          borderColor: "grey.300",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            Select Wallet
          </Typography>
          <AddIcon color="action" />
        </Box>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          Click here to add
        </Typography>
      </Paper>

      <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
        <Box
          component="img"
          src={transactionArrowHorizontalImage}
          alt="Horizontal Arrow"
          sx={{ width: 40, height: 54 }}
        />
      </Box>

      <Paper
        elevation={0}
        sx={{
          p: 2,
          mb: 2,
          border: "1px solid",
          borderColor: "grey.300",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            Recipient account
          </Typography>
          <AddIcon color="action" />
        </Box>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          Click here to add
        </Typography>
      </Paper>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <Button
          variant="contained"
          sx={{
            gap: "10px",
            width: "129.15px",
            height: "40px",
            backgroundColor: "#EEFCE9",
            border: "none",
            borderRadius: "15px",
            color: "#000",
            textTransform: "none",
            boxShadow: "none", // Removes the shadow
            "&:hover": {
              backgroundColor: "#EEFCE9",
              boxShadow: "none", // Ensures no shadow on hover
            },
            mb: 2,
          }}
        >
          Convert
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          mt: { xs: 2, sm: 15 },
          mb: { xs: 2, sm: 10 },
        }}
      >
        <InfoOutlinedIcon
          fontSize="small"
          sx={{ mr: 1, color: "text.secondary" }}
        />
        <Typography
          variant="caption"
          align="justify"
          sx={{
            fontSize: 10,
            display: "inline-block",
            lineHeight: 1.5,
            color: "#000",
          }}
        >
          We Offer A Suite Of Quick Conversion Of Crypto To Any Kind Of Fiat
          Currencies With Exchange Rates As Low As 2% Depending On Volume, You
          Can Across The Exchange Rate After Verification
        </Typography>
      </Box>
    </Box>
  );
};

export default ExchangeComponent;
