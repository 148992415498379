// import PropTypes from "prop-types";
// import { useMemo } from "react";

// // material-ui
// import { useTheme } from "@mui/material/styles";
// import { Box, Drawer, useMediaQuery } from "@mui/material";

// // project import
// import DrawerHeader from "./DrawerHeader";
// import DrawerContent from "./DrawerContent";
// import MiniDrawerStyled from "./MiniDrawerStyled";
// import { drawerWidth } from "../../config";

// // ==============================|| MAIN LAYOUT - DRAWER ||============================== //

// const MainDrawer = ({ open, handleDrawerToggle, window }) => {
//   const theme = useTheme();
//   const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));

//   // responsive drawer container
//   const container =
//     window !== undefined ? () => window().document.body : undefined;

//   // header content
//   const drawerContent = useMemo(() => <DrawerContent />, []);
//   const drawerHeader = useMemo(() => <DrawerHeader open={open} />, [open]);

//   return (
//     <Box
//       component="nav"
//       //sx={{ flexShrink: { md: 0 }, zIndex: 1300 }}
//       aria-label="mailbox folders"
//     >
//       {!matchDownMD ? (
//         <MiniDrawerStyled variant="permanent" open={open}>
//           {drawerHeader}
//           {drawerContent}
//         </MiniDrawerStyled>
//       ) : (
//         <Drawer
//           container={container}
//           variant="temporary"
//           open={open}
//           onClose={handleDrawerToggle}
//           ModalProps={{ keepMounted: true }}
//           sx={{
//             display: { xs: "block", lg: "none" },
//             "& .MuiDrawer-paper": {
//               boxSizing: "border-box",
//               width: drawerWidth,
//               borderRight: `1px solid ${theme.palette.divider}`,
//               backgroundImage: "none",
//               boxShadow: "inherit",
//             },
//           }}
//         >
//           {open && drawerHeader}
//           {open && drawerContent}
//         </Drawer>
//       )}
//     </Box>
//   );
// };

// MainDrawer.propTypes = {
//   open: PropTypes.bool,
//   handleDrawerToggle: PropTypes.func,
//   window: PropTypes.object,
// };

// export default MainDrawer;

import PropTypes from "prop-types";
import { useMemo } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Drawer, useMediaQuery } from "@mui/material";

// project import
import DrawerHeader from "./DrawerHeader";
import DrawerContent from "./DrawerContent";
import MiniDrawerStyled from "./MiniDrawerStyled";
import { drawerWidth } from "../../config";

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

const MainDrawer = ({ open, handleDrawerToggle, window }) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));

  // responsive drawer container
  const container =
    window !== undefined ? () => window().document.body : undefined;

  // header content
  const drawerContent = useMemo(() => <DrawerContent />, []);
  const drawerHeader = useMemo(() => <DrawerHeader open={open} />, [open]);

  return (
    <Box
      component="nav"
      //sx={{ flexShrink: { md: 0 }, zIndex: 1300 }}
      aria-label="mailbox folders"
    >
      {!matchDownMD ? (
        <MiniDrawerStyled
          sx={{
            "& .MuiDrawer-paper": {
              borderRight: "none",
              borderLeft: "none",
              backgroundColor: "#F5F3FE",
              borderRadius: "0px 50px 50px 0px",
            },
          }}
          variant="permanent"
          open={open}
        >
          {drawerHeader}
          {drawerContent}
        </MiniDrawerStyled>
      ) : (
        <Drawer
          container={container}
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: "none", // Remove the right border
              borderLeft: "none", // Remove the left border if needed
              backgroundImage: "none",
              boxShadow: "inherit",
              backgroundColor: "#F5F3FE",
              borderRadius: "0px 50px 50px 0px",
            },
          }}
        >
          {open && drawerHeader}
          {open && drawerContent}
        </Drawer>
      )}
    </Box>
  );
};

MainDrawer.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default MainDrawer;
