// assets
import {
  DashboardOutlined,
  UnorderedListOutlined,
  UserOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import WidgetsIcon from "@mui/icons-material/Widgets";

// icons
const icons = {
  DashboardOutlined,
  UnorderedListOutlined,
  UserOutlined,
  VerifiedOutlinedIcon,
  NotificationsNoneOutlinedIcon,
  AssignmentOutlinedIcon,
  RetweetOutlined,
  WidgetsIcon,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: "group-dashboard",
  title: "",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/",
      icon: icons.WidgetsIcon,
      breadcrumbs: false,
    },
    {
      id: "profile",
      title: "Profile",
      type: "item",
      url: "/profile",
      icon: icons.UserOutlined,
      breadcrumbs: false,
    },
    {
      id: "accounts",
      title: "Accounts",
      type: "item",
      url: "/accounts",
      icon: icons.AssignmentOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: "transactions",
      title: "Transactions",
      type: "item",
      url: "/transactions",
      icon: icons.UnorderedListOutlined,
      breadcrumbs: false,
    },
    {
      id: "convert_crypto",
      title: "Convert Crypto",
      type: "item",
      url: "/convert_crypto",
      icon: icons.RetweetOutlined,
      breadcrumbs: false,
    },
    {
      id: "convert_cash",
      title: "Convert Cash",
      type: "item",
      url: "/convert_cash",
      icon: icons.RetweetOutlined,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
