import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Header, Footer } from "../../screens/components";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import {
  Grid,
  Typography,
  Box,
  Container,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Avatar,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import backgroundImage from "../../assets/backgroundImage4.jpg";
import featureImage4 from "../../assets/featureImage4.png";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import xanderLogoSmall from "../../assets/xfxlogo4.png";

const SupportSection = () => {
  return (
    <Box
      sx={{
        paddingY: { xs: 8, sm: 20 },
        paddingX: { xs: 3, sm: 15 },
        textAlign: "left",
      }}
    >
      <Typography variant="h3" gutterBottom>
        Get Support Now
      </Typography>
      <Typography sx={{ fontSize: 18 }} variant="body1" gutterBottom>
        We’re here to assist you every step of the way. Contact us for help.
      </Typography>
      <Grid container spacing={4} justifyContent="center" sx={{ marginTop: 4 }}>
        <Grid item xs={12} sm={4}>
          <Box sx={{ textAlign: "left" }}>
            <MailOutlinedIcon sx={{ fontSize: 40 }} />
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginTop: 2, fontSize: 32 }}
            >
              Email
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 1, fontSize: 18 }}>
              Send us an email and we’ll get back to you as soon as possible.
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginTop: 1, fontWeight: "bold" }}
            >
              hello@xanderfx.io
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box sx={{ textAlign: "left" }}>
            <CallOutlinedIcon sx={{ fontSize: 40 }} />
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginTop: 2, fontSize: 32 }}
            >
              Phone
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 1, fontSize: 18 }}>
              Give us a call during our business hours for immediate assistance.
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginTop: 1, fontWeight: "bold" }}
            >
              +1 (555) 000-0000
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box sx={{ textAlign: "left" }}>
            <LocationOnOutlinedIcon sx={{ fontSize: 40 }} />
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginTop: 2, fontSize: 32 }}
            >
              Office
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 1, fontSize: 18 }}>
              Visit our office for in-person support and consultations.
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginTop: 1, fontWeight: "bold" }}
            >
              123 Sample St, Sydney NSW 2000 AU
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const FAQSection = () => {
  return (
    <Box
      sx={{
        margin: "0 auto", // Centers the Box
        paddingY: { xs: 3, sm: 6 },
        paddingX: { xs: 3, sm: 15 },
      }}
    >
      <Typography variant="h3" gutterBottom>
        FAQs
      </Typography>
      <Typography variant="body1" gutterBottom>
        Find answers to common issues and solutions to help resolve your
        problems quickly.
      </Typography>

      <Accordion
        sx={{
          backgroundColor: "transparent",
          borderBottom: "1px solid black",
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">How to create an account?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To create an account, simply click on the "Sign Up" button and
            follow the instructions provided. You will need to provide some
            basic information and complete the verification process.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{
          backgroundColor: "transparent",
          borderBottom: "1px solid black",
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography variant="h6">How to deposit funds?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To deposit funds, go to your account dashboard and click on the
            "Deposit" button. Select the desired payment method and follow the
            instructions to complete the transaction.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{
          backgroundColor: "transparent",
          borderBottom: "1px solid black",
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography variant="h6">How to withdraw funds?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To withdraw funds, navigate to your account settings and click on
            the "Withdraw" option. Select the preferred withdrawal method and
            follow the prompts to complete the process.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{
          backgroundColor: "transparent",
          borderBottom: "1px solid black",
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography variant="h6">How to buy cryptocurrencies?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To buy cryptocurrencies, go to the trading section and select the
            desired cryptocurrency. Enter the amount you wish to purchase and
            complete the transaction by following the provided instructions.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{
          backgroundColor: "transparent",
          borderBottom: "1px solid black",
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography variant="h6">How to sell cryptocurrencies?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To sell cryptocurrencies, navigate to the trading section and choose
            the cryptocurrency you want to sell. Enter the amount you wish to
            sell and follow the instructions to complete the transaction.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

const ContactSection = () => {
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        paddingY: { xs: 8, sm: 20 },
        paddingX: { xs: 3, sm: 15 },
        textAlign: "left",
        color: "#fff",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the color and opacity as needed
          zIndex: 1,
        },
        "& > *": {
          position: "relative",
          zIndex: 2,
        },
      }}
    >
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} sm={6}>
          <Typography variant="h3" component="h1" gutterBottom>
            Need Further Assistance? <br /> Contact Us.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Our support team is here to help you with any questions or concerns.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{
              borderRadius: "15px",
              textTransform: "none",
              paddingX: 2,
              paddingY: 1,
              minWidth: 180,
              fontSize: 16,
              color: "#fff",
              marginTop: 2,
              backgroundColor: "#000", // make button background black
              "&:hover": {
                backgroundColor: "#333", // darken on hover
              },
            }}
          >
            Contact
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const TestimonialSection = () => {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        paddingY: { xs: 8, sm: 20 },
        paddingX: { xs: 3, sm: 15 },
      }}
    >
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={featureImage4}
              alt="Crypto Platform Logo"
              style={{
                width: matches ? "550px" : "350px",
                borderRadius: "8px",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
            {Array(5)
              .fill(0)
              .map((_, index) => (
                // <StarIcon key={index} sx={{ color: "#FFD700" }} />
                <StarIcon key={index} sx={{ color: "#000" }} />
              ))}
          </Box>
          <Typography variant="body1" gutterBottom>
            The support team at XanderFX has been incredibly helpful and
            responsive. They guided me through the process of exchanging my
            cryptocurrencies seamlessly.
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", marginRight: 2 }}
            >
              <Typography variant="body1">Chibuike</Typography>
              <Typography variant="body2" color="textSecondary">
                CEO, xanderfx
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ marginX: 2, borderColor: "black" }}
            />
            <Avatar
              alt="xanderfx"
              src={xanderLogoSmall}
              sx={{ width: 40, height: 40 }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const QuestionsSection = () => {
  return (
    <Box
      sx={{
        paddingY: { xs: 4, sm: 8 },
        paddingX: { xs: 3, sm: 15 },
      }}
    >
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} sm={6}>
          <Typography variant="h3" component="h1" gutterBottom>
            Still have questions?
          </Typography>
          <Typography variant="body1" gutterBottom>
            Contact our support team for further assistance.
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              borderRadius: "15px",
              textTransform: "none",
              paddingX: 2,
              paddingY: 1,
              fontSize: 16,
              color: "#000",
              borderColor: "#000",
              marginTop: 2,
            }}
          >
            Contact
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const SupportPage = () => {
  return (
    <>
      <Header />
      <SupportSection />
      <FAQSection />
      <QuestionsSection />
      <TestimonialSection />
      <ContactSection />
      <Footer />
    </>
  );
};

export default SupportPage;
