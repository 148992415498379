import React, { useState, useRef, useEffect } from "react";
import { Grid, Typography, Box, Container, Divider } from "@mui/material";
import featureImage from "../../assets/featureImage.png";
import xanderLogo from "../../assets/xander_logo_big.png";
import xanderLogoSmall from "../../assets/xfxlogo4.png";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Link,
  TextField,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { keyframes } from "@emotion/react";
import backgroundImage from "../../assets/backgroundImage.png";
import coinImage2 from "../../assets/coinImage2.png";
import bitcoinImage from "../../assets/bitcoinImage.png";
import coinImage3 from "../../assets/coinImage3.png";
import coinImage4 from "../../assets/coinImage4.png";
import coinImage5 from "../../assets/coinImage5.png";
import coinImage6 from "../../assets/coinImage6.png";
import coinImage7 from "../../assets/coinImage7.png";
import coinImage8 from "../../assets/coinImage8.png";
import coinImage9 from "../../assets/coinImage9.png";
import coinImage10 from "../../assets/coinImage10.png";
import coinImage11 from "../../assets/coinImage11.png";
import coinImage12 from "../../assets/coinImage12.png";
import coinImage14 from "../../assets/coinImage14.png";
import coinImage17 from "../../assets/coinImage17.png";
import coinImage18 from "../../assets/coinImage18.png";
import coinImage19 from "../../assets/coinImage19.png";
import coinImage20 from "../../assets/coinImage20.png";
import coinImage21 from "../../assets/coinImage21.png";
import coinImage22 from "../../assets/coinImage22.png";
import coinImage23 from "../../assets/coinImage23.png";
import coinImage24 from "../../assets/coinImage24.png";
import coinImage25 from "../../assets/coinImage25.png";
import tripleCoinImage from "../../assets/tripleCoinImage.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import BoltIcon from "@mui/icons-material/Bolt";
import { Header, Footer } from "../../screens/components";
import { useNavigate } from "react-router";

const createScrollAnimation = (direction) => keyframes`
  0% {
    transform: translateX(${direction === "left" ? "0" : "-50%"});
  }
  100% {
    transform: translateX(${direction === "left" ? "-50%" : "0"});
  }
`;

const FeatureSectionSimple = () => {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <Container sx={{ padding: "15px", marginBottom: "55px" }} maxWidth="xl">
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h3" gutterBottom>
            Simplified Crypto Transactions <i>Made Easy</i> with Our Platform
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "18px" }} gutterBottom>
            Experience hassle-free crypto to cash and cash to crypto
            transactions with our user-friendly platform. Buy, sell, and
            exchange cryptocurrencies with ease.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={xanderLogo}
              alt="Crypto Platform Logo"
              style={{
                width: matches ? "550px" : "350px",
                borderRadius: "8px",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const FeatureSection = () => {
  return (
    <Container sx={{ padding: "20px" }} maxWidth="xl">
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12} md={6}>
          <Typography
            variant="h4"
            sx={{ marginTop: { xs: "5px", md: "60px" } }}
            gutterBottom
          >
            Simplify <i>Crypto-to-Cash</i> Transactions with Our Fast and Secure
            Platform
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ fontSize: "18px", marginTop: { xs: "5px", md: "40px" } }}
          >
            Experience hassle-free crypto-to-cash transactions with our
            user-friendly platform. Convert your cryptocurrencies into cash
            quickly and securely.
          </Typography>
          <Box
            sx={{
              display: "flex",
              mt: 2,
              marginTop: { xs: "5px", md: "60px" },
            }}
          >
            <Box sx={{ mr: 4 }}>
              <Typography variant="h6">
                <BoltIcon sx={{ fontSize: "48px" }} />
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", fontSize: "20px" }}
              >
                Ease of Use
              </Typography>
              <Typography variant="body2" sx={{ fontSize: "16px" }}>
                Our platform provides a seamless and intuitive interface, making
                it easy for anyone to convert crypto to cash.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6">
                <BoltIcon sx={{ fontSize: "48px" }} />
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", fontSize: "20px" }}
              >
                Security
              </Typography>
              <Typography variant="body2" sx={{ fontSize: "16px" }}>
                Rest assured knowing that your crypto-to-cash transactions are
                protected by advanced security measures.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img
              src={featureImage}
              alt="Technology fuels creativity"
              style={{
                maxWidth: "100%",
                maxHeight: "600px",
                borderRadius: "8px",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const HeroSection = () => {
  const navigate = useNavigate();

  const getStarted = () => {
    navigate("/signup");
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        marginTop: "-10px",
        padding: "0 20px",
        position: "relative",
        zIndex: 1,
      }}
    >
      <Container maxWidth="md">
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          sx={{ fontWeight: "bold" }}
        >
          Convert <i>fast and secure</i>, crypto to cash exchange
        </Typography>
        <Typography variant="h6" component="p" gutterBottom>
          Convert your cryptocurrencies to cash quickly and securely.
        </Typography>
        <Button
          variant="contained"
          sx={{
            borderRadius: "15px",
            padding: "15px 35px",
            backgroundColor: "#000",
            textTransform: "none",
            fontSize: 18,
          }}
          onClick={getStarted}
        >
          Get Started
        </Button>
      </Container>
    </Box>
  );
};

const CryptoCarousel = ({
  images,
  direction = "left",
  speed = 10,
  marginTop = 0,
}) => {
  const carouselRef = useRef(null);
  const scrollAnimation = createScrollAnimation(direction);

  useEffect(() => {
    const carousel = carouselRef.current;
    let interval;

    if (carousel) {
      interval = setInterval(() => {
        if (direction === "left") {
          carousel.scrollLeft += 1;
        } else {
          carousel.scrollLeft -= 1;
        }
      }, 10);
    }

    return () => clearInterval(interval);
  }, [direction]);

  return (
    <Box
      sx={{
        overflow: "hidden",
        whiteSpace: "nowrap",
        display: "flex",
        alignItems: "center",
        marginTop: -25,
        marginBottom: 15,
        zIndex: 4,
        position: "relative",
        "&:hover": {
          animationPlayState: "paused",
        },
        "& div": {
          display: "inline-flex",
          animation: `${scrollAnimation} ${speed}s linear infinite`,
        },
      }}
    >
      <Box>
        {images.concat(images).map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`crypto-${index}`}
            style={{ width: "350px", height: "350px" }}
          />
        ))}
      </Box>
    </Box>
  );
};

const BuyAndSellSection = ({}) => {
  const matches = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();

  const getStarted = () => {
    navigate("/signup");
    window.scrollTo(0, 0);
  };

  const learnMore = () => {
    navigate("/faq");
    window.scrollTo(0, 0);
  };

  return (
    <Container sx={{ padding: "15px", marginBottom: "10px" }} maxWidth="xl">
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h2" gutterBottom>
            <i>Buy and sell</i> crypto instantly
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ fontSize: "18px" }}>
            Join our platform and experience fast and secure crypto
            transactions.
          </Typography>
          <Box sx={{ display: "flex", gap: 2, paddingY: 2 }}>
            <Button
              variant="contained"
              sx={{
                borderRadius: "15px",
                textTransform: "none",
                color: "white",
                paddingX: 2,
                paddingY: 1,
                fontSize: 16,
                minWidth: 150,
                backgroundColor: "#000",
              }}
              onClick={getStarted}
            >
              Sign Up
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderRadius: "15px",
                textTransform: "none",
                borderColor: "#000",
                paddingX: 2,
                paddingY: 1,
                minWidth: 180,
                fontSize: 16,
                color: "#000",
              }}
              onClick={learnMore}
            >
              Learn More
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingLeft: { xs: 0, md: 25 },
            }}
          >
            <img
              src={tripleCoinImage}
              alt="Crypto Platform Logo"
              style={{
                width: matches ? "550px" : "350px",
                borderRadius: "8px",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const NewsletterSubscription = () => {
  return (
    <Container maxWidth="xl" sx={{ marginBottom: { xs: 5, md: 20 } }}>
      <Typography variant="h3" gutterBottom>
        Subscribe to Our <i>Newsletter</i>
      </Typography>
      <Typography variant="body1" sx={{ fontSize: "18px" }} gutterBottom>
        Stay updated with the latest market insights and news.
      </Typography>
      <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
        <Box spacing={2}>
          <TextField
            label="Your Email"
            variant="outlined"
            sx={{
              borderRadius: 15,
              marginRight: 1,
              marginBottom: 1,
              minWidth: 350,
            }}
            InputProps={{
              sx: {
                height: "45px",
                borderRadius: 15,
                borderColor: "#000",
              },
            }}
          />
          <Button
            variant="contained"
            sx={{
              borderRadius: "15px",
              textTransform: "none",
              color: "white",
              paddingX: 2,
              paddingY: 1,
              fontSize: 16,
              minWidth: 150,
              backgroundColor: "#000",
            }}
          >
            Get Updates
          </Button>
        </Box>
        <Typography variant="caption" display="block" sx={{ mt: 1 }}>
          By subscribing, you agree to our Terms and Conditions.
        </Typography>
      </Box>
    </Container>
  );
};

const row1Images = [
  bitcoinImage,
  coinImage2,
  coinImage3,
  coinImage4,
  coinImage5,
  coinImage6,
  coinImage7,
  coinImage8,
  coinImage9,
  coinImage10,
  coinImage11,
  coinImage12,
];

const row2Images = [
  coinImage14,
  coinImage17,
  coinImage18,
  coinImage19,
  coinImage20,
  coinImage21,
  coinImage22,
  coinImage23,
  coinImage24,
  coinImage25,
];

const HomePage = () => {
  return (
    <>
      <Header />
      <HeroSection />
      <CryptoCarousel
        images={row1Images}
        direction="left"
        speed={70}
        marginTop={-50}
      />
      <CryptoCarousel images={row2Images} direction="right" speed={70} />
      <FeatureSectionSimple />
      <FeatureSection />
      <BuyAndSellSection />
      <NewsletterSubscription />
      <Footer />
    </>
  );
};

export default HomePage;
