import React, { useState } from "react";
import {
  TextField,
  Paper,
  Button,
  Typography,
  Link,
  useMediaQuery,
  useTheme,
  Box,
  Container,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import logoSrc from "../../assets/xfx-logo-41.png";
import GoogleIcon from "@mui/icons-material/Google";
import axios from "axios";
import { SIGNUP_URL } from "../../config/urls";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { ALERT_TYPES } from "../../config/alerts";
import { LoadingButton } from "@mui/lab";
import { extractErrorMessage } from "../../modules/utilities/responseHandlers";
import { useNavigate } from "react-router";
import backgroundImage from "../../assets/signup_background.png";

const StyledRoot = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: "600px",
  backgroundColor: "transparent",
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(4),
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
}));

const StyledForm = styled("form")(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(3),
}));

const StyledButton = styled(LoadingButton)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: "#0F172A",
  color: theme.palette.common.white,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const StyledGoogleButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.text.primary,
  "&:hover": {
    backgroundColor: theme.palette.grey[300],
  },
}));

function SignUp() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirm_password: "",
  });
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const onFormSubmit = (event) => {
    const payload = {
      username: formData.email,
      email: formData.email,
      password: formData.password,
      confirm_password: formData.confirm_password,
    };

    if (
      !formData?.email ||
      !formData?.password ||
      !formData?.confirm_password
    ) {
      setMessage("Please fill all relevant fields");
      setSeverity("error");
      setOpen(true);
      return;
    }

    if (formData?.password != formData?.confirm_password) {
      setMessage("Passwords do not match");
      setSeverity("error");
      setOpen(true);
      return;
    }

    setLoading(true);
    axios
      .post(SIGNUP_URL, payload)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setLoading(false);
          setSeverity(ALERT_TYPES.SUCCESS);
          setMessage(
            "Account created successfully, A verification code has been sent to your email"
          );
          setOpen(true);
          setTimeout(() => {
            localStorage.setItem("email", formData?.email);
            navigate("/verify");
          }, 3000);
        } else {
          setLoading(false);
          setSeverity(ALERT_TYPES.ERROR);
          setMessage("Could not sign up with this credentials");
          setOpen(true);
        }
      })
      .catch((error) => {
        setLoading(false);

        // Start with a default message
        let message = "An error occurred while logging in";

        // Check if there's a response and data, and collect all messages
        const messages = extractErrorMessage(error);

        // Join all messages into a single string if there are any
        if (messages.length > 0) {
          message = messages.join(" ");
        }

        setMessage(message);
        setSeverity(ALERT_TYPES?.ERROR);
        setOpen(true);
      });
  };

  return (
    <StyledRoot>
      <CssBaseline />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <Grid container component="main" sx={{ minHeight: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          md={6}
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            justifyContent: "flex-end",
            height: "100%",
            backgroundImage: `url(${logoSrc})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "471px 471px",
            backgroundPosition: "center",
          }}
        >
          <StyledBox sx={{ marginX: "auto" }}>
            <Typography sx={{ color: "#000", p: 5, textAlign: "center" }}>
              “This Platform has saved me countless hours of work and helped me
              easily transact with my clients faster than ever before.” John
              Daniels
            </Typography>
          </StyledBox>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
            marginY: "auto",
          }}
        >
          {!isMobile && (
            <Link
              href="/login"
              underline="hover"
              sx={{
                position: "absolute",
                top: theme.spacing(2),
                right: theme.spacing(2),
                color: "#000",
                fontWeight: "bold",
              }}
            >
              Login
            </Link>
          )}
          <StyledBox sx={{ paddingY: isMobile ? 5 : 15, paddingX: 5 }}>
            {isMobile && (
              <div>
                <img
                  src={logoSrc}
                  alt="Logo"
                  style={{ width: 150, height: 150 }}
                />
              </div>
            )}
            <Typography component="h4" variant="h4" sx={{ fontWeight: "bold" }}>
              Create an account
            </Typography>
            <Typography
              component="p"
              variant="subtitle"
              sx={{ color: "#475569" }}
            >
              Enter your email below to create your account
            </Typography>
            <StyledForm noValidate>
              <TextField
                variant="outlined"
                //margin="dense"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                value={formData?.email}
                onChange={handleChange}
                autoComplete="email"
                autoFocus
                sx={{ height: 36, my: 2 }}
              />
              <TextField
                variant="outlined"
                required
                //margin="dense"
                fullWidth
                id="password"
                label="Password"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleChange}
                autoComplete="confirmPassword"
                autoFocus
                sx={{ height: 36, my: 2 }}
              />
              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                name="confirm_password"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                value={formData?.confirm_password}
                onChange={handleChange}
                autoComplete="current-password"
                sx={{ height: 36, my: 2 }}
              />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <StyledButton
                  sx={{ textTransform: "none", width: "100%", marginY: 2 }}
                  onClick={onFormSubmit}
                  variant="contained"
                  loading={isLoading}
                >
                  Sign Up
                </StyledButton>
              </div>
              <Divider sx={{ marginY: 1 }}>
                <Typography sx={{ color: "#757F8F", fontSize: 10 }}>
                  OR CONTINUE WITH
                </Typography>
              </Divider>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#d5d5d5",
                    color: "#000",
                    border: "none",
                    width: "100%",
                    marginY: 1,
                  }}
                  type="submit"
                  variant="contained"
                  startIcon={<GoogleIcon />}
                >
                  Google
                </Button>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  underline="none"
                  sx={{ color: "#CBD5E1" }}
                  variant="body2"
                >
                  By clicking continue, you agree to our Terms of Service and
                  Privacy Policy.
                </Typography>
              </div>
              {isMobile && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Link
                    href="/login"
                    underline="none"
                    sx={{ color: "#000" }}
                    variant="body2"
                  >
                    Already have an account, Go to Login
                  </Link>
                </div>
              )}
            </StyledForm>
          </StyledBox>
        </Grid>
      </Grid>
    </StyledRoot>
  );
}

export default SignUp;
