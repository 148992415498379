import React, { useState, useMemo } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Paper,
  IconButton,
  Chip,
  useMediaQuery,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import { Link } from "@mui/icons-material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import moment from "moment";
import { RestrictedLengthTypography } from "../../components";
import externalLink from "../../../assets/external-link.png";
import { useNavigate } from "react-router";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";

const EmptyState = () => {
  const theme = useTheme();

  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(4),
        minHeight: 400,
        textAlign: "center",
      }}
    >
      <AssignmentOutlinedIcon
        sx={{
          fontSize: 64,
          color: "#000",
          marginBottom: theme.spacing(2),
        }}
      />
      <Typography variant="h6" gutterBottom>
        No transactions yet
      </Typography>
      <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
        When you make transactions, they will appear here.
      </Typography>
    </Paper>
  );
};

const AdvancedViewHeader = ({ setLiteView, refreshData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingX: theme.spacing(2),
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          component="h2"
          sx={{ marginRight: theme.spacing(1), fontSize: 16 }}
        >
          Advanced view
        </Typography>
        <IconButton size="small" aria-label="link">
          <Link fontSize="small" />
        </IconButton>
        <IconButton size="small" aria-label="open in new" onClick={setLiteView}>
          <ArrowOutwardIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box>
        <IconButton size="small" aria-label="refresh" onClick={refreshData}>
          <AutorenewIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

const FilterButton = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", paddingX: theme.spacing(2) }}
    >
      <Button
        variant="text"
        startIcon={<FilterAltOutlinedIcon />}
        onClick={handleClick}
        size={isMobile ? "small" : "medium"}
        sx={{
          borderColor: theme.palette.grey[300],
          color: theme.palette.text.primary,
          textTransform: "none",
          "&:hover": {
            backgroundColor: theme.palette.grey[100],
          },
        }}
      >
        {isMobile ? "" : "Filter"}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleClose}>Filter Option 1</MenuItem>
        <MenuItem onClick={handleClose}>Filter Option 2</MenuItem>
        <MenuItem onClick={handleClose}>Filter Option 3</MenuItem>
      </Menu>
    </Box>
  );
};

const TransactionRow = ({
  transaction,
  statusColors,
  handleRowClick,
  sx = {},
}) => (
  <TableRow onClick={handleRowClick} sx={{ ...sx }}>
    <TableCell>
      <img
        src={externalLink}
        alt="External Link"
        style={{ width: 20, height: 20 }}
      />
    </TableCell>
    <TableCell>
      <Typography sx={{ fontSize: 12 }}>{transaction.description}</Typography>
    </TableCell>
    <TableCell>
      <Typography sx={{ fontSize: 12 }} color="textSecondary">
        {moment(transaction?.created_on).fromNow()}
      </Typography>
    </TableCell>
    <TableCell>
      <Typography sx={{ fontSize: 12 }}>
        {transaction?.sender?.short_code}
      </Typography>
    </TableCell>
    <TableCell>
      <Typography sx={{ fontSize: 12 }}>
        {transaction?.recipient?.short_code}
      </Typography>
    </TableCell>
    <TableCell>
      <RestrictedLengthTypography
        text={transaction?.recipient?.account_holder || ""}
        maxLength={15}
        sx={{ fontSize: 12 }}
      />
    </TableCell>
    <TableCell>
      <RestrictedLengthTypography
        text={transaction.invoice_number || ""}
        maxLength={15}
        sx={{ fontSize: 12 }}
      />
    </TableCell>
    <TableCell>
      <RestrictedLengthTypography
        text={transaction?.xanderfx_receiving_facility?.account_holder || ""}
        maxLength={15}
        sx={{ fontSize: 12 }}
      />
    </TableCell>
    <TableCell align="right">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: 1,
        }}
      >
        <Chip
          label={
            transaction.status.charAt(0).toUpperCase() +
            transaction.status.slice(1)
          }
          color={statusColors[transaction.status]}
          sx={{
            marginX: 1,
            width: 111,
            borderRadius: 6,
            height: 19,
            fontSize: 14,
          }}
        />
        <Typography sx={{ fontSize: 12 }}>
          {transaction?.amount_in_dollars}
        </Typography>
      </Box>
    </TableCell>
  </TableRow>
);

const TransactionsTable = ({ transactions, statusColors }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("time");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const navigate = useNavigate();

  const handleRowClick = (transaction) => {
    navigate(`/transactions/${transaction.id}`);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedTransactions = useMemo(() => {
    return [...transactions].sort((a, b) => {
      if (order === "asc") {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      } else {
        return a[orderBy] > b[orderBy] ? -1 : 1;
      }
    });
  }, [transactions, order, orderBy]);

  const paginatedTransactions = sortedTransactions.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const createSortHandler = (property) => () => {
    handleRequestSort(property);
  };

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{ backgroundColor: "transparent" }}
    >
      <Table sx={{ minWidth: 650 }} size={"small"}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {[
              { id: "description", label: "Description" },
              { id: "time", label: "Time" },
              { id: "currencySent", label: "Currency Sent" },
              { id: "currencyReceived", label: "Currency Received" },
              { id: "merchantName", label: "Merchant Name" },
              { id: "transactionId", label: "Transaction ID" },
              { id: "referringDomain", label: "Referring domain" },
              { id: "amount", label: "Amount" },
            ].map((column) => (
              <TableCell
                key={column.id}
                sortDirection={orderBy === column.id ? order : false}
                sx={{ fontWeight: "bold" }}
              >
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : "asc"}
                  onClick={createSortHandler(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedTransactions.map((transaction, index) => (
            <TransactionRow
              key={index}
              transaction={transaction}
              statusColors={statusColors}
              handleRowClick={() => handleRowClick(transaction)}
              sx={{
                cursor: "pointer",
                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
              }}
            />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[25, 35, 50]}
        component="div"
        count={transactions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

const DenseTransactionsTable = ({
  setLiteView,
  refreshData,
  transactions,
  statusColors,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        gap: 3,
      }}
    >
      <Box sx={{ flexGrow: 1, p: { xs: 2, sm: 3 } }}>
        <AdvancedViewHeader
          setLiteView={setLiteView}
          refreshData={refreshData}
        />
        <FilterButton />
        {transactions.length > 0 ? (
          <TransactionsTable
            transactions={transactions}
            statusColors={statusColors}
          />
        ) : (
          <EmptyState />
        )}
      </Box>
    </Box>
  );
};

export default DenseTransactionsTable;
