import React, { useState, useMemo } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Paper,
  Chip,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useNavigate } from "react-router";

const TransactionHistoryComponent = ({
  setDenseView,
  refreshData,
  transactions,
  statusColors,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("date");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const navigate = useNavigate();

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const getOrdinalSuffix = (d) => {
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${month} ${day}${getOrdinalSuffix(day)}, ${year}`;
  };

  const sortedTransactions = useMemo(() => {
    return [...transactions].sort((a, b) => {
      if (order === "asc") {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      } else {
        return a[orderBy] > b[orderBy] ? -1 : 1;
      }
    });
  }, [transactions, order, orderBy]);

  const groupedTransactions = useMemo(() => {
    const grouped = sortedTransactions.reduce((acc, transaction) => {
      const date = formatDate(transaction.created_on);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(transaction);
      return acc;
    }, {});
    return Object.entries(grouped);
  }, [sortedTransactions]);

  const paginatedGroupedTransactions = groupedTransactions.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const paginatedTransactions = sortedTransactions.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Box sx={{ margin: "auto", p: 3 }}>
      <Typography
        sx={{ fontSize: 20, color: "#000", fontWeight: "bold" }}
        variant="h6"
        gutterBottom
      >
        Explore More Coins In Our Database And Explore New Horizons
      </Typography>

      <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold", my: 1 }}>
        Transaction History
      </Typography>

      <Typography
        variant="body1"
        gutterBottom
        sx={{ color: "text.secondary", mb: 2 }}
      >
        You can view your transactions below
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
          backgroundColor: "#F9F9FC",
          paddingX: 2,
          borderRadius: 5,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1" sx={{ mr: 1 }}>
            Default view
          </Typography>
          <IconButton size="small" onClick={setDenseView}>
            <ArrowOutwardIcon fontSize="small" />
          </IconButton>
        </Box>
        <IconButton size="small" onClick={refreshData}>
          <AutorenewIcon fontSize="small" />
        </IconButton>
      </Box>

      <TableContainer
        component={Paper}
        elevation={0}
        sx={{ backgroundColor: "transparent" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="transaction history table">
          <TableHead>
            <TableRow>
              {[
                { id: "date", label: "Date" },
                { id: "id", label: "Transaction ID" },
                {
                  id: "amount",
                  label: "Amount in Dollars",
                  icon: (
                    <CurrencyExchangeIcon fontSize="small" sx={{ mr: 1 }} />
                  ),
                },
                {
                  id: "account",
                  label: "Sending Currency",
                  icon: (
                    <AccountBalanceWalletIcon fontSize="small" sx={{ mr: 1 }} />
                  ),
                },
                {
                  id: "currency",
                  label: "Receiving Currency",
                  icon: <AttachMoneyIcon fontSize="small" sx={{ mr: 1 }} />,
                },
              ].map((column) => (
                <TableCell
                  key={column.id}
                  align={
                    column.id !== "date" && column.id !== "id"
                      ? "right"
                      : "left"
                  }
                  sx={{ fontWeight: "bold" }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => handleRequestSort(column.id)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent:
                          column.id !== "date" && column.id !== "id"
                            ? "flex-end"
                            : "flex-start",
                      }}
                    >
                      {column.icon}
                      {column.label}
                    </Box>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedGroupedTransactions.map(([date, transactions]) => (
              <React.Fragment key={date}>
                {transactions.map((row, index) => (
                  <TableRow
                    key={row.id}
                    onClick={(row) => {
                      navigate(`/transactions/${row?.id}`);
                    }}
                    sx={{
                      cursor: "pointer",
                      "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    {index === 0 ? (
                      <TableCell
                        sx={{
                          borderTop: "1px solid #ddd",
                          borderBottom: "none",
                        }}
                        component="th"
                        scope="row"
                      >
                        {index === 0 ? date : ""}
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{ border: "none" }}
                        component="th"
                        scope="row"
                      >
                        {""}
                      </TableCell>
                    )}
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {row.id}
                        <Chip
                          label={
                            row.status.charAt(0).toUpperCase() +
                            row.status.slice(1)
                          }
                          size="small"
                          color={statusColors[row.status]}
                          sx={{
                            marginX: 1,
                            width: 111,
                            borderRadius: 6,
                            height: 26,
                            fontSize: 14,
                          }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      {row?.amount_in_dollars}
                    </TableCell>
                    <TableCell align="right">
                      {row?.sender?.type == "wallet"
                        ? row?.sender?.wallet_type
                        : row?.sender?.currency}
                    </TableCell>
                    <TableCell align="right">
                      {row?.recipient?.type == "wallet"
                        ? row?.recipient?.wallet_type
                        : row?.recipient?.currency}
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={groupedTransactions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default TransactionHistoryComponent;
