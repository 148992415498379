import React, { useState } from "react";
import { Grid, Typography, Box, Container, Divider } from "@mui/material";
import xanderLogoSmall from "../../assets/xfxlogo4.png";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Link,
  Tooltip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router";

export const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const goToHome = () => {
    navigate("/home");
    window.scrollTo(0, 0);
  };

  const menuItems = [
    { text: "Buy Crypto", link: "/buy_crypto" },
    { text: "Sell Crypto", link: "/sell_crypto" },
    { text: "Exchange Crypto", link: "/exchange" },
  ];

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          paddingY: 2,
          paddingX: { xs: 0, md: 10 },
          zIndex: 13,
          backgroundColor: "#fff",
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
          >
            {menuItems.map((item, index) => (
              <Button
                key={index}
                href={item.link}
                sx={{
                  mx: 1,
                  textTransform: "none",
                  fontSize: 20,
                  color: "#000",
                }}
              >
                {item.text}
              </Button>
            ))}
          </Box>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ display: { xs: "flex", md: "none" }, color: "#000" }}
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              component="img"
              src={xanderLogoSmall}
              alt="Logo"
              onClick={goToHome}
              sx={{ height: 50, width: 50 }}
            />
          </Box>
          <Button
            variant="contained"
            href="/signup"
            sx={{
              borderRadius: "15px",
              textTransform: "none",
              backgroundColor: "#000",
              height: { xs: "35px", md: "50px" },
              paddingX: { xs: 3, md: 5 },
              marginX: { xs: 0, md: "12%" },
              fontSize: { xs: 12, md: 18 },
              display: { xs: "none", md: "flex" },
            }}
          >
            Signup
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
        <Box sx={{ width: 200 }}>
          <IconButton onClick={handleDrawerToggle}>
            <CloseIcon />
          </IconButton>
          <List>
            {[
              ...menuItems,
              { text: "Sign Up", link: "/signup" },
              { text: "Login", link: "/login" },
            ].map((item, index) => (
              <ListItem button key={index} component="a" href={item.link}>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export const Footer = () => {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <Container maxWidth="xl" sx={{ marginTop: "25px", padding: 5 }}>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} md={8} p={2}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: { xs: "center", md: "space-between" },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Box
              component="img"
              src={xanderLogoSmall}
              alt="Logo"
              sx={{
                height: 40,
                width: 40,
                mr: 2,
              }}
            />
          </Box>
          <Box marginY={3} sx={{ textAlign: { xs: "center", md: "left" } }}>
            <Link
              href="/buy_crypto"
              color="inherit"
              sx={{
                display: { xs: "block", md: "inline-block" },
                mr: 3,
                mb: { xs: 2, sm: 0 },
                fontWeight: "bold",
                textDecoration: "none",
              }}
            >
              Buy Crypto
            </Link>
            <Link
              href="/sell_crypto"
              color="inherit"
              sx={{
                display: { xs: "block", md: "inline-block" },
                mr: 3,
                mb: { xs: 2, sm: 0 },
                fontWeight: "bold",
                textDecoration: "none",
              }}
            >
              Sell Crypto
            </Link>
            <Link
              href="/exchange"
              color="inherit"
              sx={{
                display: { xs: "block", md: "inline-block" },
                mr: 3,
                mb: { xs: 2, sm: 0 },
                fontWeight: "bold",
                textDecoration: "none",
              }}
            >
              Exchange Crypto
            </Link>
            <Link
              href="/support"
              color="inherit"
              sx={{
                display: { xs: "block", md: "inline-block" },
                mr: 3,
                mb: { xs: 2, sm: 0 },
                fontWeight: "bold",
                textDecoration: "none",
              }}
            >
              Support
            </Link>
            <Link
              href="/faq"
              color="inherit"
              sx={{
                display: { xs: "block", md: "inline-block" },
                mb: { xs: 2, sm: 0 },
                fontWeight: "bold",
                textDecoration: "none",
              }}
            >
              FAQs
            </Link>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ display: { xs: "none", md: "block" } }} />
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 1 }}
      >
        <Grid
          item
          xs={12}
          md={8}
          sx={{ textAlign: { xs: "center", md: "left" } }}
        >
          <Link
            href="#"
            color="inherit"
            sx={{
              display: { xs: "block", md: "inline-block" },
              mr: 3,
              mb: { xs: 2, sm: 0 },
            }}
          >
            Privacy Policy
          </Link>
          <Link
            href="#"
            color="inherit"
            sx={{
              display: { xs: "block", md: "inline-block" },
              mr: 3,
              mb: { xs: 2, sm: 0 },
            }}
          >
            Terms and Conditions
          </Link>
          <Link
            href="#"
            color="inherit"
            sx={{
              display: { xs: "block", md: "inline-block" },
              mr: 3,
              mb: { xs: 2, sm: 0 },
            }}
          >
            Cookie Policy
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          container
          justifyContent={{ xs: "center", sm: "flex-end" }}
          spacing={2}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            sx={{ mt: 4 }}
          >
            © 2024 xanderfx. All rights reserved.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export const RestrictedLengthTypography = ({
  text,
  maxLength = 10,
  sx = {},
}) => {
  const displayText =
    text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

  return (
    <Tooltip title={text} arrow placement="top">
      <Typography
        sx={{
          fontSize: 12,
          //color: "#644DED",
          maxWidth: "100px", // Adjust this value as needed
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          ...sx,
        }}
      >
        {displayText}
      </Typography>
    </Tooltip>
  );
};
