// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Card,
//   CardContent,
//   Typography,
//   Grid,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Divider,
//   CircularProgress,
// } from "@mui/material";
// import pinUrl from "../../assets/pinx.png";
// import Toolbar from "@mui/material/Toolbar";
// import ExchangeOverviewChart from "../../components/ExchangeOverviewChart";
// import BitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
// import { DASHBOARD } from "../../config/urls";
// import { fetchData } from "../../modules/utilities/util_query";
// import {
//   formatCurrencyNumber,
//   getTokenFromStorage,
// } from "../../modules/utilities";
// import { useQuery } from "@tanstack/react-query";

// const PinIcon = () => (
//   <Box
//     component="img"
//     sx={{
//       width: 20,
//       height: 20,
//       marginRight: 1,
//       marginBottom: 2,
//     }}
//     alt="Pin"
//     src={pinUrl}
//   />
// );

// const BitcoinInfo = () => {
//   return (
//     <Box display="flex" alignItems="center" paddingY={1}>
//       <BitcoinIcon
//         sx={{ marginRight: 1, fontWeight: "bold", borderColor: "#000" }}
//       />{" "}
//       <Box>
//         <Typography sx={{ fontWeight: "bold" }}>Bitcoin</Typography>
//         <Typography variant="body2">1.220000 BTC...</Typography>
//       </Box>
//     </Box>
//   );
// };

// const Dashboard = (props) => {
//   // State and other logic would go here
//   // const [data, setData] = React.useState(null);
//   const [firstName, setFirstName] = useState("");
//   const [bitcoinPrice, setBitcoinPrice] = useState("");
//   const [ethereumPrice, setEthereumPrice] = useState("");

//   let payload_data = {};
//   const token = getTokenFromStorage();
//   const result = useQuery({
//     queryKey: [
//       "dashboard",
//       {
//         url: `${DASHBOARD}`,
//         payload_data,
//         authenticate: true,
//         token,
//       },
//     ],
//     queryFn: fetchData,
//     retry: false,
//     select: (res) => {
//       let dashboardData = res?.data?.detail?.data;
//       return { dashboardData };
//     },
//     onError: (error) => {},
//   });

//   useEffect(() => {
//     let dashboardData = result?.data?.dashboardData;
//     setBitcoinPrice(dashboardData?.bitcoin);
//     setEthereumPrice(dashboardData?.etherium);
//     setFirstName(dashboardData?.first_name);
//   }, [result?.data?.dashboardData]);

//   return (
//     <Box padding={3}>
//       {result?.isLoading ? (
//         <>
//           <Box sx={{ display: "flex", justifyContent: "center" }}>
//             <CircularProgress />
//           </Box>
//         </>
//       ) : (
//         <>
//           <Typography variant="h4" gutterBottom>
//             Welcome, {firstName}
//           </Typography>
//           <Grid container spacing={3}>
//             {/* Stat Cards */}
//             <Grid item xs={12} md={6} lg={4}>
//               <Card
//                 sx={{
//                   borderRadius: "10%",
//                   paddingY: 5,
//                   paddingX: 2,
//                   boxShadow: 2,
//                 }}
//               >
//                 <CardContent>
//                   <PinIcon />
//                   <Typography
//                     sx={{ marginY: 1, fontWeight: "bold" }}
//                     variant="h6"
//                   >
//                     BTC price
//                   </Typography>
//                   <Typography
//                     sx={{ marginY: 1, fontWeight: "bold" }}
//                     variant="h4"
//                   >
//                     $ {formatCurrencyNumber(bitcoinPrice)}
//                   </Typography>
//                   <Typography
//                     variant="body2"
//                     sx={{ marginY: 1, color: "#777" }}
//                   >
//                     +20% month over month
//                   </Typography>
//                 </CardContent>
//               </Card>
//             </Grid>
//             <Grid item xs={12} md={6} lg={4}>
//               <Card
//                 sx={{
//                   borderRadius: "10%",
//                   paddingY: 5,
//                   paddingX: 2,
//                   boxShadow: 2,
//                 }}
//               >
//                 <CardContent>
//                   <PinIcon />
//                   <Typography
//                     sx={{ marginY: 1, fontWeight: "bold" }}
//                     variant="h6"
//                   >
//                     Ethereum price
//                   </Typography>
//                   <Typography
//                     sx={{ marginY: 1, fontWeight: "bold" }}
//                     variant="h4"
//                   >
//                     $ {formatCurrencyNumber(ethereumPrice)}
//                   </Typography>
//                   <Typography
//                     variant="body2"
//                     sx={{ marginY: 1, color: "#777" }}
//                   >
//                     +33% month over month
//                   </Typography>
//                 </CardContent>
//               </Card>
//             </Grid>

//             {/* Transactions List */}
//             <Grid item xs={12} md={6} lg={4}>
//               <Paper>
//                 <Box padding={2}>
//                   <Typography variant="h6" gutterBottom>
//                     Transactions
//                   </Typography>
//                   <BitcoinInfo />
//                   <Divider />
//                   <BitcoinInfo />
//                   <Divider />
//                   {/* ... */}
//                 </Box>
//               </Paper>
//             </Grid>

//             {/* Bar Chart would go here, using your chart library of choice */}
//             <Grid item xs={12} md={6} lg={8}>
//               <Card>
//                 <CardContent>
//                   <Typography variant="h6" component="div">
//                     Exchange Overview
//                   </Typography>
//                   <ExchangeOverviewChart />
//                 </CardContent>
//               </Card>
//             </Grid>

//             {/* Price Chart Table */}
//             <Grid item xs={12} md={12} lg={4}>
//               <TableContainer
//                 component={Paper}
//                 sx={{ borderRadius: "5%", boxShadow: 2 }}
//               >
//                 <Toolbar>
//                   <Typography>Price Chart</Typography>
//                 </Toolbar>
//                 <Table aria-label="simple table">
//                   <TableHead>
//                     <TableRow>
//                       <TableCell>Cryptocurrency</TableCell>
//                       <TableCell align="right">Price</TableCell>
//                       <TableCell align="right">Change</TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     {/* Replace this with your data */}
//                     <TableRow>
//                       <TableCell component="th" scope="row">
//                         Bitcoin
//                       </TableCell>
//                       <TableCell align="right">$4321</TableCell>
//                       <TableCell align="right">+84%</TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell component="th" scope="row">
//                         Doge
//                       </TableCell>
//                       <TableCell align="right">$4033</TableCell>
//                       <TableCell align="right">-8%</TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell component="th" scope="row">
//                         Solona
//                       </TableCell>
//                       <TableCell align="right">$3128</TableCell>
//                       <TableCell align="right">+2%</TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell component="th" scope="row">
//                         Luna
//                       </TableCell>
//                       <TableCell align="right">$2014</TableCell>
//                       <TableCell align="right">+33%</TableCell>
//                     </TableRow>
//                     {/* ... other rows */}
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             </Grid>
//           </Grid>
//         </>
//       )}
//     </Box>
//   );
// };

// export default Dashboard;

import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Bar,
  BarChart,
  Area,
  AreaChart,
  Sector,
} from "recharts";
import {
  Typography,
  Paper,
  Grid,
  Box,
  useTheme,
  ButtonGroup,
  Button,
  useMediaQuery,
  CircularProgress,
  styled,
} from "@mui/material";
import TripOriginSharpIcon from "@mui/icons-material/TripOriginSharp";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { DASHBOARD } from "../../config/urls";
import { getTokenFromStorage } from "../../modules/utilities";
import { lighten } from "@mui/material/styles";

const fetchDashboardData = async (timeRange) => {
  const token = getTokenFromStorage();
  const response = await axios.get(`${DASHBOARD}?time_range=${timeRange}`, {
    headers: {
      Authorization: `Bearer ${token}`, // Add the token to the Authorization header
    },
  });
  return response.data;
};

const formatCurrency = (value) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

const formatCompactCurrency = (value) => {
  if (value >= 1000000) {
    return `$${(value / 1000000).toFixed(0)}M`;
  } else if (value >= 1000) {
    return `$${(value / 1000).toFixed(0)}K`;
  } else {
    return `$${value.toFixed(0)}`;
  }
};

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  "& .MuiButton-root": {
    backgroundColor: "#fff",
    color: "#000",
    borderColor: "#ccc",
    textTransform: "none",
    fontSize: "12px",
    fontWeight: 500,
    padding: "4px 12px",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
    "&.active": {
      backgroundColor: "#f0f0f0",
    },
  },
  "& .MuiButton-root:first-of-type": {
    borderTopLeftRadius: "20px",
    borderBottomLeftRadius: "20px",
  },
  "& .MuiButton-root:last-child": {
    borderTopRightRadius: "20px",
    borderBottomRightRadius: "20px",
  },
}));

const legendItems = [
  { name: "BITCOIN", color: "#C686F8" },
  { name: "ETHEREUM", color: "#8571F4" },
  { name: "SOLANA", color: "#45D0EE" },
];

const COLORS = {
  bitcoin: "#C686F8",
  ethereum: "#8571F4",
  solana: "#45D0EE",
};

const getPieColors = (selectedCoin) => {
  const primaryColor = COLORS[selectedCoin];
  const secondaryColor = lighten(primaryColor, 0.6); // Creates a lighter version of the primary color
  return [primaryColor, secondaryColor];
};

const PieCustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#fff",
          padding: "5px 10px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      >
        <p style={{ margin: 0 }}>{`${
          payload[0].name
        } Market Share : ${payload[0].value.toFixed(2)}%`}</p>
      </div>
    );
  }
  return null;
};

const CryptoDashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [timePeriod, setTimePeriod] = useState("30d");
  const [selectedCoin, setSelectedCoin] = useState("BITCOIN");

  const { data, isLoading, error } = useQuery({
    queryKey: ["dashboardData", timePeriod],
    queryFn: () => fetchDashboardData(timePeriod),
    refetchInterval: 300000, // Refetch every 5 minutes
  });

  const chartHeight = isMobile ? 200 : 300;
  const chartWidth = 300;

  const CustomBar = (props) => {
    const { x, y, width, height, fill } = props;
    return (
      <g>
        <path
          d={`
            M ${x},${y + height}
            L ${x},${y + 5}
            Q ${x},${y} ${x + 5},${y}
            L ${x + 5},${y}
            Q ${x + 10},${y} ${x + 10},${y + 5}
            L ${x + 10},${y + height}
            Z
          `}
          fill={fill}
        />
      </g>
    );
  };

  const gapAngle = 3;

  const renderActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
      props;

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          cornerRadius={5}
        />
      </g>
    );
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography color="error">Error fetching data</Typography>;

  const { price_data, market_share, summary } = data?.detail?.data;

  const marketShakerData = Object.keys(price_data?.bitcoin || []).map(
    (_, index) => ({
      name: price_data?.bitcoin[index]?.timestamp || "N/A",
      BITCOIN: price_data?.bitcoin[index]?.price || 0,
      ETHEREUM: price_data?.ethereum[index]?.price || 0,
      SOLANA: price_data?.solana[index]?.price || 0,
    })
  );

  const selectedCoinLowerCase = (selectedCoin || "bitcoin").toLocaleLowerCase();
  const selectedCoinData = [
    {
      name:
        selectedCoinLowerCase.charAt(0).toUpperCase() +
        selectedCoinLowerCase.slice(1).toLowerCase(),
      value: market_share[selectedCoinLowerCase] || 0,
    },
    {
      name: "Other Coins",
      value: 100 - market_share[selectedCoinLowerCase] || 0,
    },
  ];

  const selectedCoinTrendData = Object.keys(
    price_data[selectedCoinLowerCase] || []
  ).map((_, index) => ({
    name: price_data[selectedCoinLowerCase][index].timestamp,
    value: price_data[selectedCoinLowerCase][index].price,
  }));

  return (
    <Box sx={{ flexGrow: 1, paddingX: 4, paddingY: 2 }}>
      <Typography
        variant="body"
        gutterBottom
        sx={{ fontSize: 20, fontWeight: "bold" }}
      >
        Explore More Coins In Our Database And Explore New Horizons
      </Typography>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ marginY: 3, fontWeight: "bold" }}
      >
        Top Three Market Shakers
      </Typography>

      <Grid container spacing={2}>
        <Grid item marginY={1} xs={12}>
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            justifyContent="space-between"
            alignItems={isMobile ? "flex-start" : "center"}
            mb={2}
            gap={2}
          >
            <StyledButtonGroup variant="outlined" size="small">
              <Button
                onClick={() => setTimePeriod("12m")}
                className={timePeriod === "12m" ? "active" : ""}
              >
                12 months
              </Button>
              <Button
                onClick={() => setTimePeriod("30d")}
                className={timePeriod === "30d" ? "active" : ""}
              >
                30 days
              </Button>
              <Button
                onClick={() => setTimePeriod("7d")}
                className={timePeriod === "7d" ? "active" : ""}
              >
                Last 7 days
              </Button>
              <Button
                onClick={() => setTimePeriod("24h")}
                className={timePeriod === "24h" ? "active" : ""}
              >
                Last 24 hours
              </Button>
            </StyledButtonGroup>
            <Box>
              <Box display="flex" flexWrap="wrap" gap={2}>
                {legendItems.map((item) => (
                  <Button
                    key={item.name}
                    variant="outlined"
                    sx={{
                      border: "1px solid #F1F4F9",
                      color: "#000",
                      fontWeight: "bold",
                      backgroundColor:
                        selectedCoin === item.name ? "#f0f0f0" : "#fff",
                    }}
                    startIcon={
                      <TripOriginSharpIcon style={{ color: item.color }} />
                    }
                    onClick={() => setSelectedCoin(item.name)}
                  >
                    {item.name}
                  </Button>
                ))}
              </Box>
            </Box>
          </Box>
          <ResponsiveContainer width="100%" height={chartHeight}>
            <BarChart
              data={marketShakerData}
              barGap={2} // Reduce gap between bars in a group
              barCategoryGap={10} // Reduce gap between bar groups
            >
              <CartesianGrid
                horizontal={true}
                vertical={false}
                stroke="#F1F4F9"
                strokeWidth={1}
              />
              <XAxis
                dataKey="name"
                tick={isMobile ? { fontSize: 10 } : {}}
                axisLine={false}
                tickLine={false}
              />
              <YAxis
                tickFormatter={(value) => formatCompactCurrency(value)}
                tick={isMobile ? { fontSize: 10 } : {}}
                axisLine={false}
                tickLine={false}
              />
              <Tooltip
                formatter={(value, name) => [formatCurrency(value), name]}
              />
              {(selectedCoin === "" || selectedCoin === "BITCOIN") && (
                <Bar dataKey="BITCOIN" fill="#C686F8" shape={<CustomBar />} />
              )}
              {(selectedCoin === "" || selectedCoin === "ETHEREUM") && (
                <Bar dataKey="ETHEREUM" fill="#8571F4" shape={<CustomBar />} />
              )}
              {(selectedCoin === "" || selectedCoin === "SOLANA") && (
                <Bar dataKey="SOLANA" fill="#45D0EE" shape={<CustomBar />} />
              )}
            </BarChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", marginY: 2 }}
            gutterBottom
          >
            {selectedCoinLowerCase.charAt(0).toUpperCase() +
              selectedCoinLowerCase.slice(1)}{" "}
            in Focus
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "center",
              justifyContent: "space-between",
              height: isMobile ? "auto" : "300px", // Adjust this value as needed
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                flexShrink: 0,
                width: isMobile ? "100%" : "60%",
                height: isMobile ? "300px" : "100%",
                maxWidth: "300px", // Adjust this value as needed
              }}
            >
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    activeIndex={[0, 1]}
                    activeShape={renderActiveShape}
                    data={selectedCoinData}
                    cx="50%"
                    cy="50%"
                    innerRadius="30%"
                    outerRadius="95%"
                    startAngle={90}
                    endAngle={-270}
                    paddingAngle={gapAngle}
                    dataKey="value"
                  >
                    {selectedCoinData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={getPieColors(selectedCoinLowerCase)[index]}
                        cornerRadius={5}
                      />
                    ))}
                  </Pie>
                  <Tooltip content={<PieCustomTooltip />} />
                </PieChart>
              </ResponsiveContainer>
            </Box>
            <Box
              sx={{
                flexGrow: 0,
                flexShrink: 1,
                flexBasis: isMobile ? "auto" : "40%",
                ml: isMobile ? 0 : 2,
                mt: isMobile ? 2 : 0,
                textAlign: isMobile ? "center" : "left",
              }}
            >
              <Typography variant="body2" paragraph>
                {summary}
              </Typography>
              <Typography variant="body2">
                Monthly <strong>20%</strong>
              </Typography>
              <Typography variant="body2">
                Weekly <strong>7%</strong>
              </Typography>
              <Typography variant="body2">
                24 hours <strong>14%</strong>
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <ResponsiveContainer width="100%" height={chartHeight}>
            <AreaChart data={selectedCoinTrendData}>
              <defs>
                <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="0%"
                    stopColor={COLORS[selectedCoinLowerCase] || "bitcoin"}
                    stopOpacity={0.16}
                  />
                  <stop
                    offset="100%"
                    stopColor={COLORS[selectedCoinLowerCase] || "bitcoin"}
                    stopOpacity={0.002}
                  />
                </linearGradient>
              </defs>
              <CartesianGrid
                horizontal={true}
                vertical={false}
                stroke="#F1F4F9"
                strokeWidth={1}
              />
              <XAxis
                dataKey="name"
                axisLine={false}
                tickLine={false}
                tick={{ fontSize: isMobile ? 10 : 12, fill: "#666" }}
              />
              <Tooltip
                formatter={(value, name) => [formatCurrency(value), name]}
              />
              <Area
                type="monotone"
                dataKey="value"
                stroke={COLORS[selectedCoinLowerCase] || "bitcoin"}
                strokeWidth={2}
                fillOpacity={1}
                fill="url(#colorValue)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CryptoDashboard;
