import React from "react";
import { Box, Typography, IconButton, Divider, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowLeftImage from "../../assets/arrow-left-circle.png";

const HeaderUI = ({
  title = "Settings",
  subTitle = "Manage your account settings.",
  navLink = "/",
}) => {
  const navigate = useNavigate();
  const goTo = () => {
    navigate(navLink);
  };

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 2,
        }}
      >
        <Stack sx={{ flexGrow: 1 }}>
          <Typography variant="h4" color="inherit" noWrap>
            {title}
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ mb: 1 }}>
            {subTitle}
          </Typography>
        </Stack>

        <IconButton onClick={goTo} color="inherit">
          <img
            src={ArrowLeftImage}
            alt="Back"
            style={{ width: 50, height: 50 }}
          />
        </IconButton>
      </Box>
      <Divider />
    </Box>
  );
};

export default HeaderUI;
