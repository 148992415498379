import React from "react";
import {
  Card,
  CardContent,
  Avatar,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  IconButton,
  Link,
} from "@mui/material";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import PurpleBackgroundCard from "../../assets/purpleBackgroundCard.png";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import ShareTwoToneIcon from "@mui/icons-material/ShareTwoTone";
import ErrorOutlineTwoToneIcon from "@mui/icons-material/ErrorOutlineTwoTone";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { getUserFromStorage, removeUser } from "../../modules/utilities";
import { Link as RouterLink } from "react-router-dom";

const ProfileSettingsCard = () => {
  const localStorageUser = getUserFromStorage();

  const handleLogout = async () => {
    // logout
    removeUser();
    window.location.reload();
  };

  const menuItems = [
    { icon: <SettingsTwoToneIcon />, text: "Account", to: "/profile/account" },
    {
      icon: <VerifiedUserOutlinedIcon />,
      text: "KYC Verification",
      to: "/profile/kyc-verification",
    },
    {
      icon: <EmailOutlinedIcon />,
      text: "Email & Number",
      to: "/profile/email_number",
    },
    {
      icon: <LockOutlinedIcon />,
      text: "Password",
      to: "/profile/change_password",
    },
    { icon: <ShareTwoToneIcon />, text: "About us", to: "/about-us" },
    {
      icon: <ErrorOutlineTwoToneIcon />,
      text: "Log out",
      onClick: handleLogout,
    },
  ];

  const ListItemComponent = React.forwardRef(
    ({ onClick, to, ...props }, ref) => {
      const CustomComponent = to ? RouterLink : "div";
      return <CustomComponent ref={ref} to={to} onClick={onClick} {...props} />;
    }
  );

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        p: { xs: 1, sm: 2, md: 3 },
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          backgroundImage: `url(${PurpleBackgroundCard})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          paddingY: 3,
        }}
      >
        <Card
          sx={{
            maxHeight: "782.6px",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(10px)",
            borderRadius: "30px",
            border: "1px solid #fff",
            overflow: "hidden",
            margin: { xs: 2, sm: "auto" },
          }}
        >
          <CardContent
            sx={{
              flex: 1,
              overflow: "auto",
              p: { xs: 2, sm: 3 },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {/* Close button */}
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
              <IconButton sx={{ color: "#fff" }}>
                <HighlightOffTwoToneIcon />
              </IconButton>
            </Box>

            {/* User info */}
            <Box display="flex" alignItems="center" mb={3}>
              <Avatar
                src={`${localStorageUser?.user?.profile?.kyc?.image_url || ""}`}
                sx={{ width: 60, height: 60, mr: 2 }}
              />
              <Box>
                <Typography variant="h5" sx={{ color: "#fff" }}>
                  {`${localStorageUser?.user?.last_name || ""} ${
                    localStorageUser?.user?.first_name || ""
                  }`}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: localStorageUser?.user?.profile?.kyc?.verified
                      ? "#4CAF50"
                      : "#F44336",
                  }}
                >
                  {localStorageUser?.user?.profile?.kyc?.verified
                    ? "Verified"
                    : "Requires Verification"}
                </Typography>
              </Box>
            </Box>

            {/* Menu items */}
            <List sx={{ mb: 2, maxWidth: "527px" }}>
              {menuItems.map((item, index) => (
                <ListItem
                  key={index}
                  disableGutters
                  component={ListItemComponent}
                  to={item.to}
                  onClick={item.onClick}
                  sx={{
                    color: "#fff",
                    textDecoration: "none",
                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline",
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{ color: "#fff", fontSize: 20, minWidth: 40 }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    primaryTypographyProps={{ fontSize: 16 }}
                  />
                </ListItem>
              ))}
            </List>

            {/* Info text */}
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: "auto",
                maxWidth: 320,
              }}
            >
              <InfoOutlinedIcon
                sx={{ fontSize: 12, mr: 1, color: "#000", mt: "2px" }}
              />
              <Typography
                variant="caption"
                sx={{ color: "#000", fontSize: 10, lineHeight: 1.2 }}
              >
                We Offer A Suite Of Quick Conversion Of Crypto To Cash And Vice
                Versa, Charges Range From As Low As 2%-4% Depending On Volume.
                You Can Access The Exchange/Transfer After Verification
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default ProfileSettingsCard;
