// import React from "react";
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route,
//   Navigate,
//   useLocation,
// } from "react-router-dom";
// import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import SignInScreen from "./screens/Auth/SignInScreen";
// import DashboardScreen from "./screens/Dashboard";
// import SignUpScreen from "./screens/Auth/SignUpScreen";
// import MainLayout from "./layouts/MainLayout";
// import Error404 from "./screens/Error404";
// import Profile from "./screens/Profile";
// import Verification from "./screens/Verification";
// import Accounts from "./screens/Accounts";
// import CreateAccount from "./screens/CreateAccount";
// import CreateWallet from "./screens/CreateWallet";
// import Transactions from "./screens/Transactions";
// import TransactionDetail from "./screens/TransactionDetail";
// import ConvertCrypto from "./screens/ConvertCrypto";
// import ConvertCash from "./screens/ConvertCash";
// import VerifyScreen from "./screens/Auth/VerifyScreen";
// import ForgotPassword from "./screens/Auth/ForgotPassword";
// import ConvertCryptoConfirmation from "./screens/ConvertCryptoConfirmation";
// import ConvertCashConfirmation from "./screens/ConvertCashConfirmation";
// import HomePage from "./screens/HomePage";
// import FAQPage from "./screens/FAQPage";
// import BuyCryptoPage from "./screens/BuyCryptoPage";
// import ExchangePage from "./screens/ExchangePage";
// import SupportPage from "./screens/SupportPage";

// const queryClient = new QueryClient();

// function RequireAuth({ children }) {
//   let location = useLocation();
//   const storedData = JSON.parse(localStorage.getItem("user")) || {};
//   if (!storedData.token) {
//     return <Navigate to="/login" state={{ from: location }} replace />;
//   }

//   return children;
// }

// function RedirectIfLoggedIn({ children }) {
//   const storedData = JSON.parse(localStorage.getItem("user")) || {};
//   if (storedData.token) {
//     return <Navigate to="/" replace />;
//   }

//   return children;
// }

// function App() {
//   return (
//     <QueryClientProvider client={queryClient}>
//       <Router>
//         <Routes>
//           <Route
//             path="/"
//             element={
//               <RequireAuth>
//                 <MainLayout />
//               </RequireAuth>
//             }
//           >
//             <Route index element={<DashboardScreen />} />
//             <Route path="profile" element={<Profile />} />
//             <Route path="verification" element={<Verification />} />
//             <Route path="accounts" element={<Accounts />} />
//             <Route path="accounts/create" element={<CreateAccount />} />
//             <Route path="accounts/create_wallet" element={<CreateWallet />} />
//             <Route path="transactions" element={<Transactions />} />
//             <Route path="transactions/:id" element={<TransactionDetail />} />
//             <Route path="convert_crypto" element={<ConvertCrypto />} />
//             <Route
//               path="convert_crypto/:id/confirmation"
//               element={<ConvertCryptoConfirmation />}
//             />
//             <Route path="convert_cash" element={<ConvertCash />} />
//             <Route
//               path="convert_cash/:id/confirmation"
//               element={<ConvertCashConfirmation />}
//             />
//             <Route path="not-found" element={<Error404 />} />
//           </Route>
//           <Route
//             path="/login"
//             element={
//               <RedirectIfLoggedIn>
//                 <SignInScreen />
//               </RedirectIfLoggedIn>
//             }
//           />
//           <Route
//             path="/signup"
//             element={
//               <RedirectIfLoggedIn>
//                 <SignUpScreen />
//               </RedirectIfLoggedIn>
//             }
//           />
//           <Route
//             path="/verify"
//             element={
//               <RedirectIfLoggedIn>
//                 <VerifyScreen />
//               </RedirectIfLoggedIn>
//             }
//           />
//           <Route
//             path="/forgot_password"
//             element={
//               <RedirectIfLoggedIn>
//                 <ForgotPassword />
//               </RedirectIfLoggedIn>
//             }
//           />
//           <Route
//             path="/home"
//             element={
//               <RedirectIfLoggedIn>
//                 <HomePage />
//               </RedirectIfLoggedIn>
//             }
//           />
//           <Route
//             path="/buy_crypto"
//             element={
//               <RedirectIfLoggedIn>
//                 <BuyCryptoPage />
//               </RedirectIfLoggedIn>
//             }
//           />
//           <Route
//             path="/faq"
//             element={
//               <RedirectIfLoggedIn>
//                 <FAQPage />
//               </RedirectIfLoggedIn>
//             }
//           />
//           <Route
//             path="/exchange"
//             element={
//               <RedirectIfLoggedIn>
//                 <ExchangePage />
//               </RedirectIfLoggedIn>
//             }
//           />
//           <Route
//             path="/support"
//             element={
//               <RedirectIfLoggedIn>
//                 <SupportPage />
//               </RedirectIfLoggedIn>
//             }
//           />
//           <Route path="*" element={<Navigate to="/not-found" />} />
//         </Routes>
//       </Router>
//     </QueryClientProvider>
//   );
// }

// export default App;
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import SignInScreen from "./screens/Auth/SignInScreen";
import DashboardScreen from "./screens/Dashboard";
import SignUpScreen from "./screens/Auth/SignUpScreen";
import MainLayout from "./layouts/MainLayout";
import Error404 from "./screens/Error404";
import Profile from "./screens/Profile";
import Verification from "./screens/Verification";
import Accounts from "./screens/Accounts";
import CreateAccount from "./screens/CreateAccount";
import CreateWallet from "./screens/CreateWallet";
import Transactions from "./screens/Transactions";
import TransactionDetail from "./screens/TransactionDetail";
import ConvertCrypto from "./screens/ConvertCrypto";
import ConvertCash from "./screens/ConvertCash";
import VerifyScreen from "./screens/Auth/VerifyScreen";
import ForgotPassword from "./screens/Auth/ForgotPassword";
import ConvertCryptoConfirmation from "./screens/ConvertCryptoConfirmation";
import ConvertCashConfirmation from "./screens/ConvertCashConfirmation";
import HomePage from "./screens/HomePage";
import FAQPage from "./screens/FAQPage";
import BuyCryptoPage from "./screens/BuyCryptoPage";
import SellCryptoPage from "./screens/SellCryptoPage";
import ExchangePage from "./screens/ExchangePage";
import SupportPage from "./screens/SupportPage";
import ProfileAccounts from "./screens/ProfileAccount";
import ProfileChangePassword from "./screens/ProfileChangePassword";
import ProfileEmail from "./screens/ProfileEmail";
import ProfileKYC from "./screens/ProfileKYC";

const queryClient = new QueryClient();

function RequireAuth({ children }) {
  let location = useLocation();
  const storedData = JSON.parse(localStorage.getItem("user")) || {};
  if (!storedData.token) {
    return <Navigate to="/home" state={{ from: location }} replace />;
  }

  return children;
}

function RedirectIfLoggedIn({ children }) {
  const storedData = JSON.parse(localStorage.getItem("user")) || {};
  if (storedData.token) {
    return <Navigate to="/" replace />;
  }

  return children;
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <MainLayout />
              </RequireAuth>
            }
          >
            <Route index element={<DashboardScreen />} />
            <Route path="profile/account" element={<ProfileAccounts />} />
            <Route path="profile/kyc-verification" element={<ProfileKYC />} />
            <Route path="profile/email_number" element={<ProfileEmail />} />
            <Route
              path="profile/change_password"
              element={<ProfileChangePassword />}
            />
            <Route path="profile" element={<Profile />} />
            <Route path="accounts" element={<Accounts />} />
            <Route path="accounts/create" element={<CreateAccount />} />
            <Route path="accounts/create_wallet" element={<CreateWallet />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="transactions/:id" element={<TransactionDetail />} />
            <Route path="convert_crypto" element={<ConvertCrypto />} />
            <Route
              path="convert_crypto/:id/confirmation"
              element={<ConvertCryptoConfirmation />}
            />
            <Route path="convert_cash" element={<ConvertCash />} />
            <Route
              path="convert_cash/:id/confirmation"
              element={<ConvertCashConfirmation />}
            />
            <Route path="not-found" element={<Error404 />} />
          </Route>
          <Route
            path="/login"
            element={
              <RedirectIfLoggedIn>
                <SignInScreen />
              </RedirectIfLoggedIn>
            }
          />
          <Route
            path="/signup"
            element={
              <RedirectIfLoggedIn>
                <SignUpScreen />
              </RedirectIfLoggedIn>
            }
          />
          <Route
            path="/verify"
            element={
              <RedirectIfLoggedIn>
                <VerifyScreen />
              </RedirectIfLoggedIn>
            }
          />
          <Route
            path="/forgot_password"
            element={
              <RedirectIfLoggedIn>
                <ForgotPassword />
              </RedirectIfLoggedIn>
            }
          />
          <Route
            path="/home"
            element={
              <RedirectIfLoggedIn>
                <HomePage />
              </RedirectIfLoggedIn>
            }
          />
          <Route path="/buy_crypto" element={<BuyCryptoPage />} />
          <Route path="/sell_crypto" element={<SellCryptoPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/exchange" element={<ExchangePage />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="*" element={<Navigate to="/not-found" />} />
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
