import React, { useState } from "react";
import HeaderUI from "../../components/common/Header";
import {
  Paper,
  Typography,
  Box,
  Divider,
  Button,
  CircularProgress,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import {
  getTokenFromStorage,
  formatCurrencyNumber,
  convertDateStringtoLocalDateString,
} from "../../modules/utilities";
import { fetchData } from "../../modules/utilities/util_query";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { ALERT_TYPES } from "../../config/alerts";
import { extractErrorMessage } from "../../modules/utilities/responseHandlers";
import { TRANSACTION_DETAIL } from "../../config/urls";
import { useParams } from "react-router";

const statusColors = {
  confirmed: "success",
  settled: "primary",
  pending: "warning",
  rejected: "error",
  review: "info",
  initiated: "secondary",
};

const TransactionDetail = () => {
  // const transaction = {
  //   amountUSD: "$2000 USD",
  //   btcAmount: "0.23 BTC = $2000",
  //   referenceCode: "JTP GFRFR",
  //   pricePerBTC: "1 BTC = $69,000 USD",
  //   convertedAmount: "$2000",
  //   networkFee: "USD 0.99",
  //   status: "Pending",
  //   date: "4:30 AM - Jun 12, 2024",
  // };
  const { id } = useParams();
  const [transaction, setTransaction] = useState({});
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");

  let payload_data = {};
  const token = getTokenFromStorage();
  const result = useQuery({
    queryKey: [
      `transaction_detail/${id}`,
      {
        url: `${TRANSACTION_DETAIL}${id}/`,
        payload_data,
        authenticate: true,
        token,
      },
    ],
    queryFn: fetchData,
    retry: false,
    select: (res) => {
      let transaction = res?.data?.detail?.data?.transaction || {};
      return { transaction };
    },
    onError: (error) => {},
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const showConversionRate = () => {
    let value;
    if (transaction?.transaction_type == "convert_crypto") {
      value = transaction?.amount_converted / transaction?.amount;
    } else if (transaction?.transaction_type == "convert_cash") {
      value = transaction?.amount / transaction?.amount_converted;
    }

    return formatCurrencyNumber(value);
  };

  React.useEffect(() => {
    let transaction = result?.data?.transaction || [];
    setTransaction(transaction);
  }, [result?.data?.transaction]);

  return (
    <>
      <HeaderUI
        title="Transaction Detail"
        subTitle="You can view details of this transaction below"
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      {result?.isPending ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <Paper elevation={3} sx={{ p: 3, maxWidth: 400, mx: "auto", mt: 4 }}>
            <Typography variant="h6" sx={{ mb: 2, textAlign: "center" }}>
              Transaction Details
            </Typography>
            <Typography
              variant="h4"
              sx={{ mb: 2, textAlign: "center", fontWeight: "bold" }}
            >
              {formatCurrencyNumber(transaction?.amount)}{" "}
              {transaction?.sender?.short_code}
            </Typography>
            <Divider sx={{ width: "60%", mx: "auto" }} />
            <Typography sx={{ mb: 3, textAlign: "center" }}>
              {formatCurrencyNumber(transaction?.amount_converted)}{" "}
              {transaction?.recipient?.short_code}
            </Typography>
            <Box
              sx={{
                my: 1,
                display: "flex",
                justifyContent: "space-between",
                px: 7,
              }}
            >
              <Typography variant="subtitle2" color="text.secondary">
                Reference code
              </Typography>
              <Typography variant="body2">
                {transaction.invoice_number}
              </Typography>
            </Box>
            <Divider sx={{ width: "80%", mx: "auto" }} />
            <Box
              sx={{
                my: 1,
                display: "flex",
                justifyContent: "space-between",
                px: 7,
              }}
            >
              <Typography variant="subtitle2" color="text.secondary">
                Price per{" "}
                {transaction?.transaction_type == "convert_crypto"
                  ? transaction?.sender?.short_code
                  : transaction?.recipient?.short_code}
              </Typography>
              <Typography variant="body2">{showConversionRate()}</Typography>
            </Box>
            <Divider sx={{ width: "80%", mx: "auto" }} />
            <Box
              sx={{
                my: 1,
                display: "flex",
                justifyContent: "space-between",
                px: 7,
              }}
            >
              <Typography variant="subtitle2" color="text.secondary">
                Convert
              </Typography>
              <Typography variant="body2">
                {formatCurrencyNumber(transaction.amount_converted)}{" "}
                {transaction?.recipient?.short_code}
              </Typography>
            </Box>
            <Divider sx={{ width: "80%", mx: "auto" }} />
            <Box
              sx={{
                my: 1,
                display: "flex",
                justifyContent: "space-between",
                px: 7,
              }}
            >
              <Typography variant="subtitle2" color="text.secondary">
                Network Fee
              </Typography>
              <Typography variant="body2">{transaction.networkFee}</Typography>
            </Box>
            <Divider sx={{ width: "80%", mx: "auto" }} />
            <Box
              sx={{
                my: 1,
                display: "flex",
                justifyContent: "space-between",
                px: 7,
              }}
            >
              <Typography variant="subtitle2" color="text.secondary">
                Status
              </Typography>
              <Typography variant="body2">
                {transaction.status && (
                  <Button
                    variant="outlined"
                    color={statusColors[transaction.status.toLowerCase()]}
                    size="small"
                    sx={{ ml: 1 }}
                  >
                    {transaction.status.charAt(0).toUpperCase() +
                      transaction.status.slice(1)}
                  </Button>
                )}
              </Typography>
            </Box>
            <Divider sx={{ width: "80%", mx: "auto" }} />
            <Box
              sx={{
                my: 1,
                display: "flex",
                justifyContent: "space-between",
                px: 7,
              }}
            >
              <Typography variant="subtitle2" color="text.secondary">
                Date
              </Typography>
              <Typography variant="body2">
                {convertDateStringtoLocalDateString(transaction.created_on)}
              </Typography>
            </Box>
            <Divider sx={{ width: "80%", mx: "auto" }} />
          </Paper>
        </>
      )}
    </>
  );
};

export default TransactionDetail;
