//export const BASE_API_URL = "http://127.0.0.1:8000/api/v1";
export const BASE_API_URL = "https://creditdev.pythonanywhere.com/api/v1";

export const LOGIN_URL = `${BASE_API_URL}/auth/login/`;
export const SIGNUP_URL = `${BASE_API_URL}/auth/signup/`;
export const VERIFY_SIGNUP_URL = `${BASE_API_URL}/auth/verify/`;
export const RESEND_VERIFICATION_URL = `${BASE_API_URL}/auth/resend/verification/`;
export const LOGOUT_URL = `${BASE_API_URL}/auth/logout/`;
export const FORGOT_PASSWORD_URL = `${BASE_API_URL}/auth/forgot_password/`;
export const FORGOT_PASSWORD_OTP_URL = `${BASE_API_URL}/auth/forgot_passwod/otp/`;
export const CHANGE_PASSWORD_URL = `${BASE_API_URL}/auth/change_password/`;
export const PROFILE_URL = `${BASE_API_URL}/auth/profile/`;
export const GET_CREATE_BANK_ACCOUNT_URL = `${BASE_API_URL}/core/accounts/`;
export const GET_CREATE_WALLET_URL = `${BASE_API_URL}/core/wallets/`;
export const GET_CREATE_KYC = `${BASE_API_URL}/core/kyc/`;
export const GET_CONVERSION_RATES = `${BASE_API_URL}/core/conversion/`;
export const INITIALIZE_TRANSACTION = `${BASE_API_URL}/core/transaction/`;
export const TRANSACTION_DETAIL = `${BASE_API_URL}/core/transaction/`;
export const CONFIRM_TRANSACTION = `${BASE_API_URL}/core/transaction/confirm/`;
export const RESEND_OTP = `${BASE_API_URL}/core/transaction/otp/resend/`;
export const GET_TRANSACTION_FACILITIES = `${BASE_API_URL}/core/transaction/facilities/`;
export const DASHBOARD = `${BASE_API_URL}/core/dashboard/`;
