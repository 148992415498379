import React, { useState } from "react";
import {
  TextField,
  Paper,
  Button,
  Typography,
  Link,
  useMediaQuery,
  useTheme,
  Container,
  Box,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import logoSrc from "../../assets/xfxlogo42.png";
import GoogleIcon from "@mui/icons-material/Google";
import axios from "axios";
import { RESEND_VERIFICATION_URL, VERIFY_SIGNUP_URL } from "../../config/urls";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { ALERT_TYPES } from "../../config/alerts";
import { LoadingButton } from "@mui/lab";
import { extractErrorMessage } from "../../modules/utilities/responseHandlers";
import { useNavigate } from "react-router";
import backgroundImage from "../../assets/signup_background.png";

const StyledRoot = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: "600px",
  backgroundColor: "transparent",
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(4),
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
}));

const StyledForm = styled("form")(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(3),
}));

const StyledButton = styled(LoadingButton)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: "#0F172A",
  color: theme.palette.common.white,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const StyledGoogleButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.text.primary,
  "&:hover": {
    backgroundColor: theme.palette.grey[300],
  },
}));

function VerifyScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [disableEmail, setDisableEmail] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    otp_code: "",
  });
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const resendEmail = () => {
    const payload = {
      email: formData.email,
    };

    if (!formData?.email) {
      setMessage("Please provide an email");
      setSeverity("error");
      setOpen(true);
      return;
    }

    setLoading(true);
    axios
      .post(RESEND_VERIFICATION_URL, payload)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setLoading(false);
          setSeverity(ALERT_TYPES.SUCCESS);
          setMessage("Verification mail has been sent successfully.");
          setOpen(true);
        }
      })
      .catch((error) => {
        setLoading(false);

        // Start with a default message
        let message = "An error occurred while verifying account";

        // Check if there's a response and data, and collect all messages
        const messages = extractErrorMessage(error);

        // Join all messages into a single string if there are any
        if (messages.length > 0) {
          message = messages.join(" ");
        }

        setMessage(message);
        setSeverity(ALERT_TYPES?.ERROR);
        setOpen(true);
      });
  };

  const onFormSubmit = (event) => {
    const payload = {
      email: formData.email,
      otp_code: formData.otp_code,
    };

    if (!formData?.email || !formData?.otp_code) {
      setMessage("Please fill all relevant fields");
      setSeverity("error");
      setOpen(true);
      return;
    }

    setLoading(true);
    axios
      .post(VERIFY_SIGNUP_URL, payload)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setLoading(false);
          setSeverity(ALERT_TYPES.SUCCESS);
          setMessage(
            "Verification Successful, You will be redirected to the dashboard shortly"
          );
          setOpen(true);
          setTimeout(() => {
            let user = response.data.detail.data;
            localStorage.setItem("user", JSON.stringify(user));
            navigate("/");
            // navigate("/login");
          }, 3000);
        } else {
          setLoading(false);
          setSeverity(ALERT_TYPES.ERROR);
          setMessage("Could not verify this account");
          setOpen(true);
        }
      })
      .catch((error) => {
        setLoading(false);

        // Start with a default message
        let message = "An error occurred while verifying account";

        // Check if there's a response and data, and collect all messages
        const messages = extractErrorMessage(error);

        // Join all messages into a single string if there are any
        if (messages.length > 0) {
          message = messages.join(" ");
        }

        setMessage(message);
        setSeverity(ALERT_TYPES?.ERROR);
        setOpen(true);
      });
  };

  React.useEffect(() => {
    const email = localStorage.getItem("email");
    if (email) {
      setDisableEmail(true);
      setFormData({ ...formData, email });
    }
  }, []);

  return (
    <StyledRoot>
      <CssBaseline />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          md={6}
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            justifyContent: "flex-end",
            height: "100%",
            backgroundImage: `url(${logoSrc})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "471px 471px",
            backgroundPosition: "center",
          }}
        >
          <Box sx={{ textAlign: "center" }}></Box>
          <Typography sx={{ color: "#000", textAlign: "center" }}>
            Lets be sure its you!
          </Typography>
          <Typography sx={{ color: "#000", p: 5, textAlign: "center" }}>
            "Remember a calm mind is more powerful than an anxious one"
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
            marginY: "auto",
          }}
        >
          {!isMobile && (
            <Link
              href="/signup"
              underline="hover"
              sx={{
                position: "absolute",
                top: theme.spacing(-5),
                right: theme.spacing(2),
                color: "#000",
                fontWeight: "bold",
              }}
            >
              Sign Up
            </Link>
          )}

          <StyledBox sx={{ paddingY: isMobile ? 5 : 15, paddingX: 5 }}>
            {isMobile && (
              <div>
                <img
                  src={logoSrc}
                  alt="Logo"
                  style={{ width: 150, height: 150 }}
                />
              </div>
            )}
            <Typography component="h4" variant="h4" sx={{ fontWeight: "bold" }}>
              Verify your account
            </Typography>
            <Typography
              component="p"
              variant="subtitle"
              sx={{ color: "#475569" }}
            >
              Please enter the O.T.P code sent to your email
            </Typography>
            <StyledForm noValidate>
              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                autoComplete="email"
                disabled={disableEmail}
                autoFocus
              />
              <TextField
                variant="outlined"
                required
                margin="dense"
                fullWidth
                id="otp_code"
                label="O.T.P Code"
                value={formData.otp_code}
                onChange={handleChange}
                name="otp_code"
                autoComplete="otpCode"
                autoFocus
              />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <StyledButton
                  sx={{ textTransform: "none", width: "100%", marginY: 1 }}
                  onClick={onFormSubmit}
                  loading={isLoading}
                  variant="contained"
                >
                  Verify
                </StyledButton>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <LoadingButton
                  underline="none"
                  sx={{ color: "#000", textTransform: "none" }}
                  variant="body2"
                  onClick={resendEmail}
                >
                  Didn't get a code? Click here to resend
                </LoadingButton>
              </div>
            </StyledForm>
          </StyledBox>
        </Grid>
      </Grid>
    </StyledRoot>
  );
}

export default VerifyScreen;
