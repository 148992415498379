import React, { useState, useRef, useEffect } from "react";
import { Grid, Typography, Box, Container, Divider } from "@mui/material";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Link,
  TextField,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import backgroundImage from "../../assets/removedBackground3.png";
import { Header, Footer } from "../../screens/components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router";
import backgroundImage2 from "../../assets/660bff3ee13d3805c152fdd7_Screenshot.png";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const faqQuestions = [
  {
    question: "What payment methods are accepted?",
    answer:
      "We accept various payment methods, including credit/debit cards, bank transfers, and digital wallets.",
  },
  {
    question: "What are the buying limits?",
    answer:
      "The buying limits depend on your account verification level. Please refer to our account verification guide for more information.",
  },
  {
    question: "How long does it take to process a purchase?",
    answer:
      "The processing time for a purchase may vary depending on the payment method chosen. Generally, it takes between 1 to 3 business days.",
  },
  {
    question: "Can I sell my crypto?",
    answer:
      "Yes, you can sell your crypto on our platform. Simply navigate to the sell section and follow the instructions.",
  },
  {
    question: "Is my personal information secure?",
    answer:
      "We take the security of your personal information seriously. Our platform utilizes advanced encryption and security measures to protect your data.",
  },
];

const faqQuestions2 = [
  {
    question: "What are withdrawal options?",
    answer:
      "Withdrawal options include bank transfer and cryptocurrency wallet. You can choose the option that suits you best.",
  },
  {
    question: "What are the fees?",
    answer:
      "Our fees vary depending on the transaction size and type. Please refer to our fee schedule for more information.",
  },
  {
    question: "How fast are the transactions?",
    answer:
      "Transaction speed depends on network congestion and the cryptocurrency being traded. We strive to process transactions as quickly as possible.",
  },
  {
    question: "Is my personal information secure?",
    answer:
      "We take the security of your personal information seriously. Our platform uses advanced encryption technology to protect your data.",
  },
  {
    question: "Can I cancel a transaction?",
    answer:
      "Once a transaction is initiated, it cannot be canceled. Please double-check all details before confirming a transaction.",
  },
];

const faqQuestions3 = [
  {
    question: "How can I contact support?",
    answer:
      "If you need assistance, you can reach our support team through the contact form on our website.",
  },
  {
    question: "Are there any limits?",
    answer:
      "Yes, there are limits on the amount of cryptocurrency you can sell. These limits may vary depending on your account verification level.",
  },
  {
    question: "How long does verification take?",
    answer:
      "Verification typically takes 1-2 business days. You will receive an email notification once your account is verified.",
  },
  {
    question: "What happens if there's an issue with my transaction?",
    answer:
      "In the rare event of an issue with your transaction, please contact our support team for assistance. We will work to resolve the issue promptly.",
  },
  {
    question: "Can I sell any cryptocurrency?",
    answer:
      "We support a wide range of cryptocurrencies for selling. Please check our platform for the list of supported cryptocurrencies.",
  },
];

const faqQuestions4 = [
  {
    question: "How does the exchange work?",
    answer:
      "Our exchange platform allows you to easily buy, sell, and exchange cryptocurrencies. Simply choose the currency you want to trade, enter the amount, and complete the transaction.",
  },
  {
    question: "Which currencies are supported?",
    answer:
      "We support a wide range of cryptocurrencies, including Bitcoin, Ethereum, Litecoin, and more. You can view the full list of supported currencies on our website.",
  },
  {
    question: "What are the exchange rates?",
    answer:
      "Our exchange rates are competitive and updated in real-time to ensure you get the best value for your cryptocurrencies. The rates may vary based on market conditions.",
  },
  {
    question: "Are there any fees?",
    answer:
      "Yes, there are fees associated with certain transactions on our platform. The fees may vary depending on the type of transaction and the currency you are trading.",
  },
  {
    question: "How can I contact support?",
    answer:
      "If you have any further questions or need assistance, you can contact our support team through email or live chat. We are available 24/7 to help you.",
  },
];

const faqQuestions5 = [
  {
    question: "How do I create an account?",
    answer:
      "To create an account, simply click on the 'Sign Up' button and follow the instructions provided. You will need to provide some basic information and complete the verification process.",
  },
  {
    question: "Is my account secure?",
    answer:
      "Yes, we take the security of your account very seriously. We use advanced encryption technology to protect your personal and financial information, and we have strict security measures in place to prevent unauthorized access.",
  },
  {
    question: "How can I contact customer support?",
    answer:
      "If you have any questions or need assistance, you can contact our customer support team by clicking on the 'Contact' button and filling out the contact form. Our team will get back to you as soon as possible.",
  },
  {
    question: "Is the platform available worldwide?",
    answer:
      "Yes, our platform is available to users worldwide. However, please note that certain features and services may be subject to regional restrictions or regulations.",
  },
  {
    question: "How do I buy cryptocurrencies?",
    answer:
      "To buy cryptocurrencies, you can navigate to the 'Buy' section of our platform and choose the cryptocurrency you want to purchase. Follow the instructions provided to complete the transaction.",
  },
];

const FAQComponent1 = () => {
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "visible",
        backgroundImage: `url(${backgroundImage})`,
        //backgroundSize: "cover",
        backgroundPosition: "-300px -50px",
        backgroundPosition: "top",
        paddingY: { xs: 5, sm: 10 },
        paddingX: { xs: 3, sm: 15 },
        //minHeight: "100vh",

        zIndex: 4,
      }}
    >
      <Container maxWidth="xl">
        <Typography variant="h3" component="h1" gutterBottom>
          FAQs
        </Typography>
        <Typography
          variant="subtitle1"
          component="p"
          sx={{
            marginBottom: 10,
            fontSize: 18,
            maxWidth: { xs: "100%", sm: "60%" },
          }}
          gutterBottom
        >
          Find answers to the most common questions about buying crypto,
          including payment methods, limits, and processing time.
        </Typography>

        {faqQuestions.map((e, index) => {
          return (
            <Accordion
              key={index}
              sx={{
                backgroundColor: "transparent",
                borderTop: "1px solid #000",
                //borderBottom: "1px solid #000",
                borderLeft: "none",
                borderRight: "none",
              }}
              defaultExpanded={index === 0}
              disableGutters
              square
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  backgroundColor: "transparent",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  {e.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: "transparent",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                <Typography>{e.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Container>
    </Box>
  );
};

const FAQComponentSection = () => {
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        marginTop: "10%",
        paddingY: { xs: 5, sm: 10 },
        paddingX: { xs: 3, sm: 15 },
      }}
    >
      <Typography variant="h2" component="h1" gutterBottom>
        Welcome to FAQ
      </Typography>
      <Typography variant="h6" component="p">
        Find answers to the most common questions about our crypto to cash
        platform.
      </Typography>
    </Box>
  );
};

const FAQComponent3 = () => {
  const navigate = useNavigate();
  const moveToSupport = () => {
    navigate("/support");
    window.scrollTo(0, 0);
  };
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "visible",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "-300px -50px",
        backgroundPosition: "top",
        paddingY: { xs: 5, sm: 10 },
        paddingX: { xs: 3, sm: 15 },
        //minHeight: "100vh",
        //padding: 5,
        zIndex: 4,
      }}
    >
      <Container maxWidth="xl">
        <Typography variant="h3" component="h1" gutterBottom>
          FAQs
        </Typography>
        <Typography
          variant="subtitle1"
          component="p"
          sx={{ marginBottom: 10, fontSize: 18 }}
          gutterBottom
        >
          Find answers to common inquiries about the exchange process, supported
          currencies, exchange rates, and applicable fees.
        </Typography>

        {faqQuestions4.map((e, index) => {
          return (
            <Accordion
              key={index}
              sx={{
                backgroundColor: "transparent",
                borderTop: "1px solid #000",
                borderBottom: "1px solid #000",
                borderLeft: "none",
                borderRight: "none",
              }}
              defaultExpanded
              disableGutters
              square
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  backgroundColor: "transparent",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  {e.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: "transparent",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                <Typography>{e.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
        <Card
          sx={{
            p: 3,
            backgroundColor: "transparent",
            border: "none",
            boxShadow: 0,
          }}
        >
          <CardContent>
            <Typography variant="h4" align="left" gutterBottom>
              Still have questions?
            </Typography>
            <Typography variant="body1" align="left" sx={{ mb: 3 }}>
              Contact our support team for further assistance.
            </Typography>
            <Button
              onClick={moveToSupport}
              variant="outlined"
              sx={{
                color: "black",
                borderColor: "black",
                borderRadius: "14px",
                textTransform: "none",
              }}
            >
              Contact
            </Button>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

const FAQComponent4 = () => {
  const faqData = [
    {
      question: "How do I create an account?",
      answer:
        "To create an account, simply click on the 'Sign Up' button and follow the instructions provided. You will need to provide some basic information and complete the verification process.",
    },
    {
      question: "Is my account secure?",
      answer:
        "Yes, we take the security of your account very seriously. We use advanced encryption technology to protect your personal and financial information, and we have strict security measures in place to prevent unauthorized access.",
    },
    {
      question: "How can I contact customer support?",
      answer:
        "If you have any questions or need assistance, you can contact our customer support team by clicking on the 'Contact' button and filling out the contact form. Our team will get back to you as soon as possible.",
    },
    {
      question: "Is the platform available worldwide?",
      answer:
        "Yes, our platform is available to users worldwide. However, please note that certain features and services may be subject to regional restrictions or regulations.",
    },
    {
      question: "How do I buy cryptocurrencies?",
      answer:
        "To buy cryptocurrencies, you can navigate to the 'Buy' section of our platform and choose the cryptocurrency you want to purchase. Follow the instructions provided to complete the transaction.",
    },
  ];

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        paddingY: { xs: 8, sm: 20 },
        paddingX: { xs: 3, sm: 15 },
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "-5px",
        padding: "0 10px",
        zIndex: 1,
      }}
    >
      <Grid container spacing={4} alignItems="center">
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography marginY={2} variant="h3" textAlign={"left"} gutterBottom>
            Frequently Asked Questions
          </Typography>
          <Typography
            marginY={3}
            variant="body1"
            sx={{ fontSize: "18px" }}
            gutterBottom
            textAlign={"left"}
          >
            Find answers to common questions about account setup, security
            measures, customer support, and platform availability.
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              borderRadius: "15px",
              textTransform: "none",
              borderColor: "#000",
              paddingX: 2,
              paddingY: 1,
              minWidth: 180,
              fontSize: 16,
              color: "#000",
              marginTop: 2,
            }}
          >
            Contact
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          {faqData.map((e, index) => {
            return (
              <Accordion
                key={index}
                sx={{
                  backgroundColor: "transparent",
                  borderTop: "1px solid #000",
                  //borderBottom: "1px solid #000",
                  borderLeft: "none",
                  borderRight: "none",
                }}
                defaultExpanded
                disableGutters
                square
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: "transparent",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    {e.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: "transparent",
                    borderLeft: "none",
                    borderRight: "none",
                    textAlign: "left",
                  }}
                >
                  <Typography>{e.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
};

const FAQComponent2 = () => {
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        paddingY: { xs: 5, sm: 10 },
        paddingX: { xs: 3, sm: 15 },
      }}
    >
      <Typography variant="h3" textAlign={"center"} gutterBottom>
        FAQs
      </Typography>
      <Typography
        variant="body1"
        sx={{ mb: 3, textAlign: "center", fontSize: 18 }}
      >
        Find answers to common questions about selling crypto on our platform.
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {faqQuestions2.map((faq, index) => (
            <Accordion
              key={index}
              sx={{
                backgroundColor: "transparent",
                borderTop: "1px solid #000",
                // borderBottom: "1px solid #000",
                borderLeft: "none",
                borderRight: "none",
              }}
              disableGutters
              square
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  backgroundColor: "transparent",
                  borderLeft: "none",
                  borderRight: "none",
                }}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: "transparent",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                <Typography>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
        <Grid item xs={12} md={6}>
          {faqQuestions3.map((faq, index) => (
            <Accordion
              key={index}
              sx={{
                backgroundColor: "transparent",
                borderTop: "1px solid #000",
                //borderBottom: "1px solid #000",
                borderLeft: "none",
                borderRight: "none",
              }}
              disableGutters
              square
            >
              <AccordionSummary
                sx={{
                  backgroundColor: "transparent",
                  borderLeft: "none",
                  borderRight: "none",
                }}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: "transparent",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                <Typography>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

const ConnectSection = () => {
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        paddingY: { xs: 8, sm: 20 },
        paddingX: { xs: 3, sm: 15 },
        textAlign: "center",
        backgroundImage: `url(${backgroundImage2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "-5px",
        padding: "0 10px",
        zIndex: 1,
      }}
    >
      <Grid container spacing={4} alignItems="center">
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography marginY={2} variant="h3" textAlign={"left"} gutterBottom>
            Get in Touch
          </Typography>
          <Typography
            marginY={3}
            variant="body1"
            sx={{ fontSize: "18px" }}
            gutterBottom
            textAlign={"left"}
          >
            If you have any questions or need further assistance, please don't
            hesitate to contact us.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <EmailIcon />
              </Grid>
              <Grid item>
                <Typography textAlign={"left"} variant="h6">
                  Email
                </Typography>
                <Link
                  sx={{ color: "#000", textDecoration: "none" }}
                  href="mailto:support@XanderFX.io"
                >
                  support@xanderFX.io
                </Link>
              </Grid>
            </Grid>
            <Box mt={2}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <PhoneIcon />
                </Grid>
                <Grid item>
                  <Typography variant="h6" textAlign={"left"}>
                    Phone
                  </Typography>
                  <Link
                    sx={{ color: "#000", textDecoration: "none" }}
                    href="tel:+15551234567"
                  >
                    +1 (555) 123-4567
                  </Link>
                </Grid>
              </Grid>
            </Box>
            <Box mt={2}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <LocationOnIcon />
                </Grid>
                <Grid item>
                  <Typography variant="h6" textAlign={"left"}>
                    Office
                  </Typography>
                  <Typography>
                    123 Main St, Sydney NSW 2000, Australia
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const FAQPage = () => {
  return (
    <>
      <Header />
      <FAQComponentSection />
      <FAQComponent1 />
      <FAQComponent2 />
      <FAQComponent3 />
      <FAQComponent4 />
      <ConnectSection />
      <Footer />
    </>
  );
};

export default FAQPage;
