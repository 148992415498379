import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Avatar,
} from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import CurrencyExchangeTwoToneIcon from "@mui/icons-material/CurrencyExchangeTwoTone";

export const WalletCard = ({ wallet, handleClick, selected }) => {
  return (
    <Card
      sx={{
        maxWidth: 545,
        m: 2,
        boxShadow: 3,
        borderRadius: 2,
        borderColor: selected ? "#000" : "#e0e0e0",
        borderWidth: 2.5,
        borderStyle: "solid",
        cursor: "pointer",
        transition: "border-color 300ms",
        "&:hover": {
          borderColor: "#000",
          borderWidth: 2.5,
        },
      }}
      onClick={handleClick}
    >
      <CardContent sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Avatar sx={{ bgcolor: "#000", width: 60, height: 60 }}>
          <CurrencyExchangeTwoToneIcon sx={{ fontSize: 35 }} />
        </Avatar>
        <div>
          <Typography variant="h6" component="div">
            {wallet.account_holder}
          </Typography>
          <Typography color="text.secondary">
            Wallet Address: {wallet.wallet_address}
          </Typography>
          <Typography color="text.secondary" sx={{ fontSize: 14 }}>
            Coin: {wallet.wallet_type}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export const CurrencyAccountCard = ({ account, handleClick, selected }) => {
  return (
    <Card
      sx={{
        maxWidth: 545,
        m: 2,
        boxShadow: 3,
        borderRadius: 2,
        borderColor: selected ? "#000" : "#e0e0e0",
        borderWidth: 2.5,
        borderStyle: "solid",
        cursor: "pointer",
        transition: "border-color 300ms",
        "&:hover": {
          borderColor: "#000",
          borderWidth: 2.5,
        },
      }}
      onClick={handleClick}
    >
      <CardContent sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Avatar sx={{ bgcolor: "#000", width: 60, height: 60 }}>
          <AccountBalanceOutlinedIcon sx={{ fontSize: 35 }} />
        </Avatar>
        <div>
          <Typography variant="h6" component="div">
            {account.bank_name} ({account.account_type})
          </Typography>
          <Typography color="text.secondary">
            Account Number: {account.account_number}
          </Typography>
          {account.routing_number && (
            <Typography color="text.secondary">
              Routing Number: {account.routing_number}
            </Typography>
          )}
          {account.iban && (
            <Typography color="text.secondary">IBAN: {account.iban}</Typography>
          )}
          <Typography color="text.secondary" sx={{ fontSize: 14 }}>
            Currency: {account.currency}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};
