import toast from "react-hot-toast";

/**
 *
 * function to handle api errors
 *
 * this function has side effects
 *
 * @args error - the error response from the api
 * @return null
 *
 */
export function handleApiError(error, customJSX) {
  let message = error?.response?.data?.detail
    ? error?.response?.data?.detail
    : error.toString();
  if (customJSX) {
    toast.custom(customJSX);
  } else {
    toast.error(message);
  }
  return;
}

/**
 *
 * function to handle api success
 *
 * this function has side effects
 *
 * @args response - success response from the api
 * @return null
 *
 */
export function handleApiSuccess(response, customJSX) {
  let message = response?.message || "Success";
  if (customJSX) {
    toast.custom(customJSX);
  } else {
    toast.success(message);
  }
  return;
}

export function extractErrorMessage(error) {
  let messages = [];

  if (error?.response?.data?.detail?.data) {
    if (Array.isArray(error?.response?.data?.detail?.data)) {
      messages.push(...error.response.data.detail?.data);
      return messages;
    }
  }

  if (error?.response?.data?.detail) {
    for (const key in error.response.data?.detail?.data) {
      if (error.response.data.detail.data[key].length > 0) {
        // Add each message from each key
        // messages.push(`${key}: ${error.response.data.detail.data[key][0]}`);
        if (
          error.response.data.detail.data[key][0] == "This field is required."
        ) {
          messages.push(`${key.replace(/_/g, " ")} is required.`);
        } else {
          messages.push(`${error.response.data.detail.data[key][0]}`);
        }
      }
    }
  }

  if (messages.length <= 0 && error?.response?.data?.detail?.description) {
    messages.push(`${error?.response?.data?.detail?.description}`);
  }

  return messages;
}
