// material-ui
import React from "react";
import {
  Box,
  IconButton,
  Link,
  useMediaQuery,
  Typography,
} from "@mui/material";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";

// project import
import Search from "./Search";
import Profile from "./Profile";
import MobileSection from "./MobileSection";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderLinks = () => {
  return (
    <Box sx={{ width: "100%", ml: { xs: 0, md: 1 } }}>
      <Typography>
        <Link
          href="/"
          style={{ marginRight: 15, color: "black", textDecoration: "none" }}
        >
          Dashboard
        </Link>
        <Link
          href="/convert_crypto"
          style={{ marginRight: 15, color: "black", textDecoration: "none" }}
        >
          Convert Crypto
        </Link>
        <Link
          href="/accounts"
          style={{ marginRight: 15, color: "black", textDecoration: "none" }}
        >
          Accounts
        </Link>
        {/* <Link
          href="/settings"
          style={{ color: "black", textDecoration: "none" }}
        >
          Settings
        </Link> */}
      </Typography>
    </Box>
  );
};

const ManagePaymentLinks = () => {
  return (
    <Box sx={{ width: "2%", ml: { xs: 0, md: 1 }, mr: { xs: 2, md: 1 } }}>
      <Typography>
        <IconButton
          //onClick={() => navigate('/manage-payment')}
          style={{ color: "black" }}
        >
          <NotificationsActiveOutlinedIcon />
        </IconButton>
      </Typography>
    </Box>
  );
};

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <>
      {!matchesXs && <HeaderLinks />}
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}

      {/* <Notification /> */}
      <ManagePaymentLinks />
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
