import React, { useState } from "react";
import HeaderUI from "../../components/common/Header";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Divider,
  Tab,
  CircularProgress,
  Tabs,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { fetchData } from "../../modules/utilities/util_query";
import { GET_CREATE_BANK_ACCOUNT_URL } from "../../config/urls";
import { getTokenFromStorage } from "../../modules/utilities";

const Accounts = (props) => {
  const [tabValue, setTabValue] = useState(0);
  const [bankAccounts, setBankAccounts] = useState([]); // Example state for bank accounts
  const [cryptoWallets, setCryptoWallets] = useState([]); // Example state for crypto wallets
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const navigate = useNavigate();

  let payload_data = {};
  const token = getTokenFromStorage();
  const result = useQuery({
    queryKey: [
      "accounts",
      {
        url: `${GET_CREATE_BANK_ACCOUNT_URL}`,
        payload_data,
        authenticate: true,
        token,
      },
    ],
    queryFn: fetchData,
    retry: false,
    select: (res) => {
      let wallets = res?.data?.detail?.data?.wallets;
      let accounts = res?.data?.detail?.data?.accounts;
      // setCryptoWallets(wallets);
      // setBankAccounts(accounts);
      return { wallets, accounts };
    },
    onError: (error) => {},
  });

  const createAccount = () => {
    navigate("/accounts/create");
  };

  const createWallet = () => {
    navigate("/accounts/create_wallet");
  };

  React.useEffect(() => {
    setBankAccounts(result?.data?.accounts || []);
    setCryptoWallets(result?.data?.wallets || []);
  }, [result?.data?.accounts, result?.data?.wallets]);

  return (
    <>
      <HeaderUI title="Accounts" subTitle="Manage your account settings." />
      {result?.isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              sx={{
                borderColor: "divider",
                backgroundColor: "#F1F5F9",
                marginTop: 1,
                height: "40px",
                width: 380,
                paddingX: 1,
                borderRadius: 3,
              }}
              indicatorColor="none"
            >
              <Tab
                label="Bank Accounts"
                sx={{
                  textTransform: "none",
                  color: "#000",
                  backgroundColor: tabValue === 0 ? "white" : "#F1F5F9",
                  fontWeight: tabValue === 0 ? "bold" : "normal",
                  textUnderline: "none",
                  borderRadius: 3,
                  width: 190,
                  height: "38px",
                  minHeight: "38px",
                  marginY: 0.5,
                }}
              />
              <Tab
                label="Crypto Wallets"
                sx={{
                  textTransform: "none",
                  color: "#000",
                  backgroundColor: tabValue === 1 ? "white" : "#F1F5F9",
                  fontWeight: tabValue === 1 ? "bold" : "normal",
                  borderRadius: 3,
                  width: 190,
                  height: "38px",
                  minHeight: "38px",
                  marginY: 0.5,
                }}
              />
            </Tabs>
            <Box
              sx={{
                mt: 1,
                p: 1,
                border: 1,
                borderColor: "#E2E8F0",
                borderRadius: "6px",
                bgcolor: "background.paper",
              }}
            >
              {tabValue === 0 &&
                (bankAccounts.length > 0 ? (
                  bankAccounts.map((account) => (
                    <Box sx={{ mt: 2, px: 2 }}>
                      <Typography variant="h6" component="h2" sx={{ mb: 1 }}>
                        {account.account_holder} {`(${account.currency})`}
                      </Typography>
                      <Typography variant="body1" sx={{ mb: 2 }}>
                        {account.account_number}
                      </Typography>
                      <Divider sx={{ mb: 1 }} />
                    </Box>
                  ))
                ) : (
                  <Typography sx={{ mt: 2, textAlign: "center" }}>
                    No Bank Accounts Available
                  </Typography>
                ))}
              {tabValue === 1 &&
                (cryptoWallets.length > 0 ? (
                  cryptoWallets.map((wallet) => (
                    <Box sx={{ mt: 2, px: 2 }}>
                      <Typography variant="h6" component="h2" sx={{ mb: 1 }}>
                        {wallet.account_holder} {`(${wallet.wallet_type})`}
                      </Typography>
                      <Typography variant="body1" sx={{ mb: 2 }}>
                        {wallet.wallet_address} {/* Wallet details */}
                      </Typography>
                      <Divider sx={{ mb: 1 }} />
                    </Box>
                  ))
                ) : (
                  <Typography sx={{ mt: 2, textAlign: "center" }}>
                    No Crypto Wallets Available
                  </Typography>
                ))}
            </Box>
          </Box>

          <Box
            variant="outlined"
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              p: 1,
              m: { xs: 2, sm: 5 },
            }}
          >
            <Button
              sx={{
                justifyContent: "center",
                textTransform: "none",
                border: "1px solid #000",
                margin: 2,
                padding: { xs: 1, sm: 2 },
                borderRadius: 3,
                width: { xs: "100%", sm: "auto" },
              }}
              onClick={createAccount}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", color: "#000" }}
              >
                <AddIcon sx={{ mr: 1 }} />
                Create Bank Account
              </Box>
              <ChevronRightIcon sx={{ color: "#000" }} />
            </Button>
            <Button
              sx={{
                justifyContent: "center",
                textTransform: "none",
                border: "1px solid #000",
                margin: 2,
                padding: { xs: 1, sm: 2 },
                borderRadius: 3,
                width: { xs: "100%", sm: "auto" },
              }}
              onClick={createWallet}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", color: "#000" }}
              >
                <AddIcon sx={{ mr: 1 }} />
                Create Crypto Wallet
              </Box>
              <ChevronRightIcon sx={{ color: "#000" }} />
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default Accounts;
