import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Divider,
  MenuItem,
} from "@mui/material";
import HeaderUI from "../../components/common/Header";
import AddIcon from "@mui/icons-material/Add";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router";
import { LoadingButton } from "@mui/lab";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { ALERT_TYPES } from "../../config/alerts";
import { extractErrorMessage } from "../../modules/utilities/responseHandlers";
import { GET_CREATE_BANK_ACCOUNT_URL } from "../../config/urls";
import { getTokenFromStorage } from "../../modules/utilities";
import { useMutation } from "@tanstack/react-query";
import { postData } from "../../modules/utilities/util_query";

const ACCOUNT_TYPES = [
  { value: "Business", label: "Business" },
  { value: "Checking", label: "Checking" },
  { value: "Current", label: "Current" },
  { value: "Joint", label: "Joint" },
  { value: "Savings", label: "Savings" },
];

const AccountForm = ({
  accountType,
  formData,
  handleInputChange,
  mutation,
  handleSubmit,
}) => (
  <Box
    sx={{
      width: { xs: "100%", sm: "50%" },
      mt: 1,
      p: 2,
      border: 1,
      borderColor: "#E2E8F0",
      borderRadius: "6px",
      bgcolor: "background.paper",
    }}
  >
    <Typography variant="h6">Add {accountType} Account</Typography>
    <Typography variant="subtitle1" gutterBottom sx={{ color: "#64748B" }}>
      Set up your account
    </Typography>
    <TextField
      fullWidth
      label="Account Holder"
      name="accountHolder"
      value={formData.accountHolder}
      onChange={handleInputChange}
      margin="normal"
    />
    <TextField
      fullWidth
      label={accountType === "Euro" ? "IBAN" : "Account Number"}
      name="accountNumber"
      value={formData.accountNumber}
      onChange={handleInputChange}
      margin="normal"
    />
    {accountType === "Dollar" && (
      <TextField
        fullWidth
        label="ACH or wire routing number"
        name="routingNumber"
        value={formData.routingNumber}
        onChange={handleInputChange}
        margin="normal"
      />
    )}
    <TextField
      fullWidth
      label="Account Type"
      select
      name="accountTypeDetail"
      value={formData.accountTypeDetail}
      onChange={handleInputChange}
      margin="normal"
    >
      {ACCOUNT_TYPES.map((type) => (
        <MenuItem key={type.value} value={type.value}>
          {type.label}
        </MenuItem>
      ))}
    </TextField>
    {accountType === "Naira" && (
      <TextField
        fullWidth
        label="Bank Name"
        name="bankName"
        value={formData.bankName}
        onChange={handleInputChange}
        margin="normal"
      />
    )}
    <LoadingButton
      variant="contained"
      sx={{
        mt: 2,
        textTransform: "none",
        backgroundColor: "#0F172A",
      }}
      loading={mutation?.isPending}
      onClick={handleSubmit}
    >
      Add Account
    </LoadingButton>
  </Box>
);

const AccountSelection = ({ setAccountType }) => (
  <Box sx={{ p: 1, width: { xs: "100%", sm: "50%" } }}>
    <Typography my={1} variant="h6">
      Select an account to create
    </Typography>
    {["Dollar", "Euro", "Naira"].map((type) => (
      <Box
        key={type}
        onClick={() => setAccountType(type)}
        sx={{ cursor: "pointer" }}
      >
        <Typography variant="h6" component="h2" sx={{ mb: 1 }}>
          {type} Account
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Setup Account
        </Typography>
        <Divider sx={{ mb: 1 }} />
      </Box>
    ))}
  </Box>
);

const AccountCreation = () => {
  const [accountType, setAccountType] = useState("");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    accountHolder: "",
    accountNumber: "",
    bankName: "",
    swiftBic: "",
    iban: "",
    routingNumber: "",
    accountTypeDetail: "",
  });
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");

  const mutation = useMutation({
    mutationFn: postData,
    onSuccess: (response) => {
      setSeverity(ALERT_TYPES.SUCCESS);
      setMessage("Account Created Successfully");
      setOpen(true);
      setTimeout(() => navigate("/accounts"), 1000);
    },
    onError: (error) => {
      let message = "An error occurred creating the account";
      const messages = extractErrorMessage(error);
      if (messages.length > 0) {
        message = messages.join(" ");
      }
      setMessage(message);
      setSeverity(ALERT_TYPES?.ERROR);
      setOpen(true);
    },
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const token = getTokenFromStorage();
  const handleSubmit = () => {
    if (!formData?.accountHolder) {
      setMessage("Account Holder is required");
      setSeverity("error");
      setOpen(true);
      return;
    }
    if (!formData?.accountTypeDetail) {
      setMessage("Account Type is required");
      setSeverity("error");
      setOpen(true);
      return;
    }
    if (
      accountType == "Dollar" &&
      (!formData.routingNumber || !formData.accountNumber)
    ) {
      setMessage("Routing number and Account number are required.");
      setSeverity("error");
      setOpen(true);
      return;
    }

    if (
      accountType == "Naira" &&
      (!formData.accountNumber || !formData.bankName)
    ) {
      setMessage("Bank name and Account Number are required for Naira Account");
      setSeverity("error");
      setOpen(true);
      return;
    }

    if (accountType == "Euro" && !formData.accountNumber) {
      setMessage("IBAN is required for Euro account");
      setSeverity("error");
      setOpen(true);
      return;
    }

    mutation.mutate({
      url: GET_CREATE_BANK_ACCOUNT_URL,
      payload_data: {
        account_holder: formData.accountHolder,
        account_number: formData.accountNumber,
        bank_name: formData.bankName,
        routing_number: formData.routingNumber,
        account_type: formData.accountTypeDetail,
        iban: formData.accountNumber,
        currency: accountType,
      },
      token: token,
      authenticate: true,
    });
  };

  const goBack = () => navigate("/accounts");

  return (
    <Box sx={{ p: 4 }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <HeaderUI title="Bank Accounts" subTitle="Manage your bank accounts" />
      {accountType ? (
        <AccountForm
          accountType={accountType}
          formData={formData}
          handleInputChange={handleInputChange}
          mutation={mutation}
          handleSubmit={handleSubmit}
        />
      ) : (
        <AccountSelection setAccountType={setAccountType} />
      )}
    </Box>
  );
};

export default AccountCreation;
