import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Button,
  Divider,
} from "@mui/material";
import HeaderUI from "../../components/common/Header";
import { useQuery } from "@tanstack/react-query";
import { fetchData } from "../../modules/utilities/util_query";
import {
  GET_CREATE_BANK_ACCOUNT_URL,
  GET_CONVERSION_RATES,
  INITIALIZE_TRANSACTION,
} from "../../config/urls";
import {
  getTokenFromStorage,
  formatCurrencyNumber,
  handleConversion,
} from "../../modules/utilities";
import { useMutation } from "@tanstack/react-query";
import { postData } from "../../modules/utilities/util_query";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { ALERT_TYPES } from "../../config/alerts";
import { extractErrorMessage } from "../../modules/utilities/responseHandlers";
import { useNavigate } from "react-router";
import { LoadingButton } from "@mui/lab";

const TransactionForm = () => {
  const [fromAccount, setFromAccount] = useState("");
  const [toAccount, setToAccount] = useState("");
  const [btcAmount, setBtcAmount] = useState("");
  const [shortCode, setShortCode] = useState("");
  const [fromCurrency, setFromCurrency] = useState("");
  const [toCurrency, setToCurrency] = useState("");
  const [convShortCode, setConvShortCode] = useState("");
  const [bankAccounts, setBankAccounts] = useState([]);
  const [cryptoWallets, setCryptoWallets] = useState([]);
  const [conversionRatesMap, setConversionRatesMap] = useState({});
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [receiverType, setReceiverType] = useState("");
  const navigate = useNavigate();

  let payload_data = {};
  const token = getTokenFromStorage();
  const result = useQuery({
    queryKey: [
      "accounts",
      {
        url: `${GET_CREATE_BANK_ACCOUNT_URL}`,
        payload_data,
        authenticate: true,
        token,
      },
    ],
    queryFn: fetchData,
    retry: false,
    select: (res) => {
      let wallets = res?.data?.detail?.data?.wallets;
      let accounts = res?.data?.detail?.data?.accounts;
      // setCryptoWallets(wallets);
      // setBankAccounts(accounts);
      return { wallets, accounts };
    },
    onError: (error) => {},
  });

  const conversionRates = useQuery({
    queryKey: [
      "conversionRates",
      {
        url: `${GET_CONVERSION_RATES}`,
        payload_data,
        authenticate: true,
        token,
      },
    ],
    queryFn: fetchData,
    retry: false,
    select: (res) => {
      let rates = res?.data?.detail?.data;
      return { rates };
    },
    onError: (error) => {},
  });

  React.useEffect(() => {
    setBankAccounts(result?.data?.accounts || []);
    setCryptoWallets(result?.data?.wallets || []);
  }, [result?.data?.accounts, result?.data?.wallets]);

  React.useEffect(() => {
    setConversionRatesMap(conversionRates?.data?.rates || {});
  }, [conversionRates?.data?.rates]);

  const selectCryptoWallet = (value) => {
    setFromAccount(value);
    const selected = cryptoWallets.filter((wallet) => {
      return wallet.id == value;
    });
    if (selected.length > 0) {
      let chosen = selected[0];
      setShortCode(chosen.short_code);
      setFromCurrency(chosen.wallet_type);
    }
  };

  const selectBankAccountorCrypto = (value) => {
    setToAccount(value);
    const selected = [...cryptoWallets, ...bankAccounts].filter((wallet) => {
      return wallet.id == value;
    });
    if (selected.length > 0) {
      let chosen = selected[0];
      setConvShortCode(chosen.short_code);
      if (chosen?.wallet_type) {
        setToCurrency(chosen.wallet_type);
        setReceiverType("wallet");
      } else {
        setToCurrency(chosen.currency);
        setReceiverType("account");
      }
    }
  };

  const mutation = useMutation({
    mutationFn: postData,
    onSuccess: (response) => {
      setSeverity(ALERT_TYPES.SUCCESS);
      setMessage(
        "Transaction Initialized, You will be redirected to the confirmation page in 3 seconds"
      );
      setOpen(true);

      let transactionId = response.data.detail.data.transaction.id;
      setTimeout(
        () => navigate(`/convert_crypto/${transactionId}/confirmation`),
        3000
      );
    },
    onError: (error) => {
      let message = "An error occurred creating the account";
      const messages = extractErrorMessage(error);
      if (messages.length > 0) {
        message = messages.join(" ");
      }
      setMessage(message);
      setSeverity(ALERT_TYPES?.ERROR);
      setOpen(true);
    },
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = () => {
    if (!btcAmount) {
      setMessage("Please enter an amount");
      setSeverity("error");
      setOpen(true);
      return;
    }

    if (!fromAccount) {
      setMessage("Please select sending crypto wallet");
      setSeverity("error");
      setOpen(true);
      return;
    }

    if (!toAccount) {
      setMessage("Please select receiving bank account");
      setSeverity("error");
      setOpen(true);
      return;
    }

    mutation.mutate({
      url: INITIALIZE_TRANSACTION,
      payload_data: {
        amount: btcAmount,
        exchange_range_amount: handleConversion(
          btcAmount,
          fromCurrency,
          toCurrency,
          conversionRatesMap
        ),
        sender: fromAccount,
        receiver: toAccount,
        transaction_type: "convert_crypto",
        receiver_type: receiverType,
      },
      token: token,
      authenticate: true,
    });
  };

  return (
    <>
      <HeaderUI title="Convert Crypto" subTitle="Exchange" />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <Box sx={{ maxWidth: 400, mx: "auto", mt: 4, textAlign: "center" }}>
        <Typography variant="h6" gutterBottom>
          Enter Amount
        </Typography>
        <TextField
          fullWidth
          variant="standard"
          label=""
          value={btcAmount}
          onChange={(e) => setBtcAmount(e.target.value)}
          sx={{
            mb: 2,
            width: "70%",
            input: {
              textAlign: "center",
              fontSize: "2rem",
            },
          }}
        />

        {fromAccount !== "" && toAccount !== "" && btcAmount !== "" && (
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            {formatCurrencyNumber(btcAmount)} {shortCode} = {convShortCode}{" "}
            {formatCurrencyNumber(
              handleConversion(
                btcAmount,
                fromCurrency,
                toCurrency,
                conversionRatesMap
              )
            )}
          </Typography>
        )}
        <TextField
          select
          fullWidth
          label="From Crypto Wallet"
          value={fromAccount}
          onChange={(e) => selectCryptoWallet(e.target.value)}
          variant="outlined"
          sx={{ mb: 2, borderRadius: 3, fontSize: 30 }}
        >
          {cryptoWallets.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.account_holder} ({option.short_code}) -{" "}
              {option.wallet_address}
            </MenuItem>
          ))}
        </TextField>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 2,
          }}
        >
          <Typography variant="body1" component="span" sx={{ mx: 2 }}>
            ⇅
          </Typography>
        </Box>

        <TextField
          select
          fullWidth
          label="To Bank Account or CryptoWallet"
          value={toAccount}
          onChange={(e) => selectBankAccountorCrypto(e.target.value)}
          variant="outlined"
          sx={{ mb: 2, borderRadius: 6 }}
        >
          {[...bankAccounts, ...cryptoWallets].map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.account_holder} - {option.short_code}
            </MenuItem>
          ))}
        </TextField>

        <LoadingButton
          variant="contained"
          sx={{
            mt: 2,
            textTransform: "none",
            backgroundColor: "#0F172A",
          }}
          loading={mutation?.isPending}
          onClick={handleSubmit}
        >
          Continue
        </LoadingButton>
      </Box>
    </>
  );
};

export default TransactionForm;
