import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Provider as ReduxProvider } from "react-redux";
import CustomShadows from "./themes/shadows";

import { store } from "./store";

const theme = createTheme({
  typography: {
    fontFamily: "Varela, Arial, sans-serif",
    h1: {
      fontFamily: "Merriweather, Arial, sans-serif",
    },
    h2: {
      fontFamily: "Merriweather, Arial, sans-serif",
    },
    h3: {
      fontFamily: "Merriweather, Arial, sans-serif",
    },
    h4: {
      fontFamily: "Merriweather, Arial, sans-serif",
    },
    h5: {
      fontFamily: "Merriweather, Arial, sans-serif",
    },
    h6: {
      fontFamily: "Merriweather, Arial, sans-serif",
    },
  },
  customShadows: CustomShadows,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ReduxProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
