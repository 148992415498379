import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Card,
  CardContent,
  Snackbar,
  Alert,
  Divider,
} from "@mui/material";
import HeaderUI from "../../components/common/Header";
import AddIcon from "@mui/icons-material/Add";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router";
import { GET_CREATE_WALLET_URL } from "../../config/urls";
import { getTokenFromStorage } from "../../modules/utilities";
import { ALERT_TYPES } from "../../config/alerts";
import { extractErrorMessage } from "../../modules/utilities/responseHandlers";
import { useMutation } from "@tanstack/react-query";
import { postData } from "../../modules/utilities/util_query";
import { LoadingButton } from "@mui/lab";

const AccountSelection = ({ setAccountType }) => (
  <Box sx={{ p: 1, width: { xs: "100%", sm: "50%" } }}>
    <Typography my={1} variant="h6">
      Select a wallet to create
    </Typography>
    {["Bitcoin", "Ethereum", "USDT"].map((type) => (
      <Box
        key={type}
        onClick={() => setAccountType(type)}
        sx={{ cursor: "pointer", marginBottom: 1 }}
      >
        <Typography variant="h6" component="h2">
          {type} Wallet
        </Typography>
        <Typography variant="body2">Setup Wallet</Typography>
        <Divider />
      </Box>
    ))}
  </Box>
);

const AccountForm = ({
  accountType,
  formData,
  handleInputChange,
  handleSubmit,
  isLoading,
}) => (
  <Box
    sx={{
      width: { xs: "100%", sm: "50%" },
      mt: 1,
      p: 2,
      border: 1,
      borderColor: "#E2E8F0",
      borderRadius: "6px",
      bgcolor: "background.paper",
    }}
    component="form"
    onSubmit={(e) => {
      e.preventDefault();
      handleSubmit();
    }}
  >
    <Typography variant="h6">Add {accountType} Wallet</Typography>
    <Typography variant="subtitle1" gutterBottom sx={{ color: "#64748B" }}>
      Set up your wallet
    </Typography>
    <TextField
      fullWidth
      label="Account Holder"
      name="accountHolder"
      value={formData.accountHolder}
      onChange={handleInputChange}
      margin="normal"
    />
    <TextField
      fullWidth
      label="Wallet Address"
      name="walletAddress"
      value={formData.walletAddress}
      onChange={handleInputChange}
      margin="normal"
    />
    <LoadingButton
      variant="contained"
      type="submit"
      loading={isLoading}
      sx={{
        mt: 2,
        textTransform: "none",
        backgroundColor: "#0F172A",
        "&:hover": {
          backgroundColor: "#233044",
        },
      }}
    >
      Add Wallet
    </LoadingButton>
  </Box>
);

const CreateWallet = () => {
  const [accountType, setAccountType] = useState("");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    accountHolder: "",
    walletAddress: "",
  });
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");

  const mutation = useMutation({
    mutationFn: postData,
    onSuccess: (response) => {
      setSeverity(ALERT_TYPES.SUCCESS);
      setMessage("Wallet Created Successfully");
      setOpen(true);
      setTimeout(() => navigate("/accounts"), 1000);
    },
    onError: (error) => {
      let message = "An error occurred creating the wallet";
      const messages = extractErrorMessage(error);
      if (messages.length > 0) {
        message = messages.join(" ");
      }
      setMessage(message);
      setSeverity(ALERT_TYPES?.ERROR);
      setOpen(true);
    },
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const token = getTokenFromStorage();
  const handleSubmit = () => {
    if (!formData?.accountHolder) {
      setMessage("Account Holder is required");
      setSeverity("error");
      setOpen(true);
      return;
    }

    if (!formData?.walletAddress) {
      setMessage("Wallet Address is required");
      setSeverity("error");
      setOpen(true);
      return;
    }

    mutation.mutate({
      url: GET_CREATE_WALLET_URL,
      payload_data: {
        account_holder: formData?.accountHolder,
        wallet_address: formData?.walletAddress,
        wallet_type: accountType,
      },
      token: token,
      authenticate: true,
    });
  };

  return (
    <Box sx={{ p: 4 }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <HeaderUI title="Crypto Wallet" subTitle="Manage your wallet" />
      {accountType ? (
        <AccountForm
          accountType={accountType}
          formData={formData}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          isLoading={mutation?.isPending}
        />
      ) : (
        <AccountSelection setAccountType={setAccountType} />
      )}
    </Box>
  );
};

export default CreateWallet;
