// import React from "react";
// import { Outlet } from "react-router-dom";

// import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";

// // material-ui
// import { useTheme } from "@mui/material/styles";
// import { Box, Toolbar, useMediaQuery } from "@mui/material";

// // project import
// import Drawer from "./Drawer";
// import Header from "./Header";

// import { openDrawer } from "../store/reducers/menu";

// const MainLayout = () => {
//   const theme = useTheme();
//   const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));
//   const dispatch = useDispatch();

//   const { drawerOpen } = useSelector((state) => state.menu);

//   // drawer toggler
//   const [open, setOpen] = useState(drawerOpen);
//   const handleDrawerToggle = () => {
//     setOpen(!open);
//     dispatch(openDrawer({ drawerOpen: !open }));
//   };

//   // set media wise responsive drawer
//   useEffect(() => {
//     setOpen(!matchDownLG);
//     dispatch(openDrawer({ drawerOpen: !matchDownLG }));

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [matchDownLG]);

//   // look at this use effect hook later
//   useEffect(() => {
//     if (open !== drawerOpen) setOpen(drawerOpen);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [drawerOpen]);

//   return (
//     <>
//       <Box sx={{ display: "flex", width: "100%" }}>
//         <Header open={open} handleDrawerToggle={handleDrawerToggle} />
//         <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
//         <Box
//           component="main"
//           sx={{
//             width: "100%",
//             flexGrow: 1,
//             p: 0,
//           }}
//         >
//           <Toolbar />
//           <Outlet />
//         </Box>
//       </Box>
//     </>
//   );
// };

// export default MainLayout;
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Toolbar,
  useMediaQuery,
  Fab,
  Drawer as MuiDrawer,
  Typography,
} from "@mui/material";

// project import
import Drawer from "./Drawer";
import Header from "./Header";
import { openDrawer } from "../store/reducers/menu";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ExchangeComponent from "../components/ExchangeComponent";

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));
  const dispatch = useDispatch();

  const { drawerOpen } = useSelector((state) => state.menu);

  const [open, setOpen] = useState(drawerOpen);
  const [transactionDrawerOpen, setTransactionDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  const handleTransactionDrawerToggle = () => {
    setTransactionDrawerOpen(!transactionDrawerOpen);
  };

  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));
  }, [matchDownLG, dispatch]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
  }, [drawerOpen]);

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Header
        open={open}
        handleDrawerToggle={handleDrawerToggle}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
      />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="main"
        sx={{
          width: "100%",
          flexGrow: 1,
          p: 0,
          position: "relative",
        }}
      >
        <Toolbar />
        <Outlet />
        <Fab
          variant="extended"
          color="#F5F3FE"
          aria-label="make transaction"
          sx={{
            position: "fixed",
            bottom: 26,
            right: 26,
            background: "#F5F3FE",
            textTransform: "none",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          onClick={handleTransactionDrawerToggle}
        >
          <PointOfSaleIcon sx={{ mr: 1 }} />
          Make a Transaction
        </Fab>
      </Box>
      {/* <MuiDrawer
        anchor="right"
        open={transactionDrawerOpen}
        onClose={handleTransactionDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          sx: {
            width: { xs: "80%", sm: "60%", md: "30%" },
            backgroundColor: "#F8F7FE",
            backdropFilter: "blur(50px)",
            boxShadow: "none",
            border: "none",
            borderRadius: "50px 0 0 50px",
          },
        }}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
          },
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Box sx={{ p: 2 }}>
          <ExchangeComponent />
        </Box>
      </MuiDrawer> */}
      <MuiDrawer
        anchor="right"
        open={transactionDrawerOpen}
        onClose={handleTransactionDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          sx: {
            width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
            paddingTop: { xs: 15, sm: 10 },
            backgroundColor: "#F8F7FE",
            backdropFilter: "blur(50px)",
            boxShadow: "none",
            border: "none",
            borderRadius: "50px 0 0 50px",
          },
        }}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
          },
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "80%",
          }}
        >
          <ExchangeComponent />
        </Box>
      </MuiDrawer>
    </Box>
  );
};

export default MainLayout;
