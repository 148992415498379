// material-ui
import { useTheme } from "@mui/material/styles";
import logoUrl from "../../assets/xfxlogo4.png";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  const theme = useTheme();

  return (
    <>
      <img src={logoUrl} alt="xander" width="40" height="auto" />
    </>
  );
};

export default Logo;
