import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import HeaderUI from "../../components/common/Header";
import { CurrencyAccountCard, WalletCard } from "../../components/cards";
import { CenterFocusStrong } from "@mui/icons-material";
import { useParams } from "react-router";
import {
  CONFIRM_TRANSACTION,
  GET_TRANSACTION_FACILITIES,
  RESEND_OTP,
  TRANSACTION_DETAIL,
} from "../../config/urls";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { ALERT_TYPES } from "../../config/alerts";
import {
  getTokenFromStorage,
  formatCurrencyNumber,
} from "../../modules/utilities";
import { useQuery, useMutation } from "@tanstack/react-query";
import { fetchData, postData } from "../../modules/utilities/util_query";
import { useNavigate } from "react-router";
import { extractErrorMessage } from "../../modules/utilities/responseHandlers";
import { LoadingButton } from "@mui/lab";

const ConfirmationForm = () => {
  const [otpCode, setOtpCode] = useState("");
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [transaction, setTransaction] = useState({});
  const navigate = useNavigate();
  const [action, setAction] = useState("");
  const [wallets, setWallets] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [consolidated, setConsolidated] = useState([]);

  const handleSelection = (accountId) => {
    setSelectedId(accountId);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  let payload_data = {};
  const token = getTokenFromStorage();

  const result = useQuery({
    queryKey: [
      `transaction_detail_${id}`,
      {
        url: `${TRANSACTION_DETAIL}${id}/`,
        payload_data,
        authenticate: true,
        token,
      },
    ],
    queryFn: fetchData,
    retry: false,
    select: (res) => {
      let transaction = res?.data?.detail?.data?.transaction;
      return { transaction };
    },
    onError: (error) => {},
  });

  const facilities = useQuery({
    queryKey: [
      `facilities`,
      {
        url: `${GET_TRANSACTION_FACILITIES}`,
        payload_data,
        authenticate: true,
        token,
      },
    ],
    queryFn: fetchData,
    retry: false,
    select: (res) => {
      let wallets = res?.data?.detail?.data?.wallets;
      let accounts = res?.data?.detail?.data?.accounts;
      return { wallets, accounts };
    },
    onError: (error) => {},
  });

  const mutation = useMutation({
    mutationFn: postData,
    onSuccess: (response) => {
      if (action == "resend") {
        setSeverity(ALERT_TYPES.SUCCESS);
        setMessage("OTP resent successfully");
        setOpen(true);
        setAction("");
        return;
      }
      setSeverity(ALERT_TYPES.SUCCESS);
      setMessage(
        "Your transaction has been validated, and a confirmation receipt has been emailed to you. You will be redirected to the transactions page in 3 seconds."
      );
      setOpen(true);

      setTimeout(() => navigate(`/transactions`), 3000);
    },
    onError: (error) => {
      let message = "An error occurred";
      const messages = extractErrorMessage(error);
      if (messages.length > 0) {
        message = messages.join(" ");
      }
      setMessage(message);
      setSeverity(ALERT_TYPES?.ERROR);
      setOpen(true);
    },
  });

  const handleSubmit = () => {
    if (!otpCode) {
      setMessage("Please enter the OTP code sent your email");
      setSeverity("error");
      setOpen(true);
      return;
    }

    if (!selectedId) {
      setMessage(
        "Please select the wallet or account you made the transfer to"
      );
      setSeverity("error");
      setOpen(true);
      return;
    }
    mutation.mutate({
      url: `${CONFIRM_TRANSACTION}${id}/`,
      payload_data: {
        otp_code: otpCode,
        xanderfx_facility: selectedId,
      },
      token: token,
      authenticate: true,
    });
  };

  const resendOTP = () => {
    setAction("resend");
    mutation.mutate({
      url: `${RESEND_OTP}`,
      payload_data: {},
      token: token,
      authenticate: true,
    });
  };

  React.useEffect(() => {
    if (!facilities?.data || !result?.data?.transaction) {
      return;
    }

    let walletsToFilter = facilities?.data?.wallets || [];
    let accountsToFilter = facilities?.data?.accounts || [];
    let transaction = result?.data?.transaction;

    accountsToFilter = accountsToFilter.filter((account) => {
      return account.currency == transaction?.to_currency;
    });
    walletsToFilter = walletsToFilter.filter((wallet) => {
      return wallet.wallet_type == transaction?.to_currency;
    });
    setWallets(walletsToFilter);
    setAccounts(accountsToFilter);
    setConsolidated([...accountsToFilter, ...walletsToFilter]);
    setTransaction(transaction);
  }, [
    facilities?.data?.wallets,
    facilities?.data?.accounts,
    result?.data?.transaction,
  ]);

  return (
    <>
      <HeaderUI
        title="Confirm Transaction"
        subTitle="Select the wallet or account to which you transferred funds and enter the OTP received in your email"
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <Box sx={{ p: 3 }}>
        {facilities?.isLoading ? (
          <>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h6" gutterBottom>
              Select a Wallet for Transfer
            </Typography>
            <Typography variant="body2"></Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                justifyContent: "center",
              }}
            >
              {consolidated.map((rec) =>
                rec?.wallet_type ? (
                  <WalletCard
                    key={rec.id}
                    wallet={rec}
                    handleClick={() => handleSelection(rec.id)}
                    selectedWalletId={selectedId}
                    selected={rec.id == selectedId}
                  />
                ) : (
                  <CurrencyAccountCard
                    key={rec.id}
                    account={rec}
                    handleClick={() => handleSelection(rec.id)}
                    selectedAccountId={selectedId}
                    selected={rec.id == selectedId}
                  />
                )
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                variant="standard"
                label="OTP Code"
                value={otpCode}
                onChange={(e) => setOtpCode(e.target.value)}
                sx={{
                  mb: 2,
                  justifyContent: "center",
                  width: 450,
                  input: {
                    textAlign: "center",
                    fontSize: "2rem",
                  },
                }}
              />
              <Box>
                {transaction?.status == "initiated" && (
                  <LoadingButton
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{
                      mt: 1.1,
                      mx: 1,
                      textTransform: "none",
                      backgroundColor: "#0F172A",
                    }}
                    loading={mutation?.isPending}
                  >
                    Continue
                  </LoadingButton>
                )}

                {transaction?.status == "initiated" && (
                  <LoadingButton
                    variant="contained"
                    onClick={resendOTP}
                    sx={{
                      mt: 1,
                      textTransform: "none",
                      backgroundColor: "#0F172A",
                    }}
                    loading={mutation?.isPending}
                  >
                    Resend OTP
                  </LoadingButton>
                )}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default ConfirmationForm;
