import React from "react";
import { Header, Footer } from "../../screens/components";
import featureImage from "../../assets/featureImage.png";
import featureImage3 from "../../assets/featureImage3.png";
import {
  Grid,
  Typography,
  Box,
  Button,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import backgroundImage from "../../assets/purpleBackground.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import coin2 from "../../assets/coinImage2.png";
import coin23 from "../../assets/coinImage23.png";
import coin25 from "../../assets/coinImage25.png";
import backgroundImage2 from "../../assets/660bff3ee13d3805c152fdd7_Screenshot.png";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate } from "react-router";

const TradeCryptoEasily = () => {
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "left top",
        paddingY: { xs: 8, sm: 20 },
        paddingX: { xs: 3, sm: 15 },
        marginTop: { xs: 5, md: 0 },
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h3"
            sx={{ maxWidth: { xs: "100%", sm: "60%" } }}
            gutterBottom
          >
            Trade Cryptocurrencies Easily
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" sx={{ fontSize: 18 }} gutterBottom>
            Exchange your cryptocurrencies hassle-free on our platform. Buy,
            sell, and trade with ease.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const ConvertCryptoSection = () => {
  const matches = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();

  const moveToLearnMore = () => {
    navigate("/faq");
    window.scrollTo(0, 0);
  };

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        paddingY: { xs: 8, sm: 20 },
        paddingX: { xs: 3, sm: 15 },
      }}
    >
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} sm={6}>
          <Typography marginY={2} variant="h3" gutterBottom>
            Effortlessly Convert Cryptocurrencies with Real-Time Market Rates
          </Typography>
          <Typography
            marginY={3}
            variant="body1"
            sx={{ fontSize: 18 }}
            gutterBottom
          >
            Our exchange process allows you to easily select and convert
            cryptocurrencies with real-time market rates. Buy, sell, and
            exchange your digital assets hassle-free.
          </Typography>
          <Grid container spacing={2} marginY={3}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", fontSize: "20px" }}
              >
                Easy Steps
              </Typography>
              <Typography variant="body2">
                1. Select the cryptocurrencies you want to exchange.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", fontSize: "20px" }}
              >
                Real-Time Rates
              </Typography>
              <Typography variant="body2">
                2. View the real-time market rates and choose the best
                conversion option.
              </Typography>
            </Grid>
          </Grid>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              borderRadius: "15px",
              textTransform: "none",
              borderColor: "#000",
              paddingX: 2,
              paddingY: 1,
              minWidth: 180,
              fontSize: 16,
              color: "#000",
            }}
            onClick={moveToLearnMore}
          >
            Learn More
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ textAlign: { xs: "center", sm: "right" } }}
        >
          <img
            src={featureImage}
            alt="Doge"
            style={{
              width: matches ? "580px" : "380px",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const StartExchangeSection = () => {
  const matches = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();

  const moveToLearnMore = () => {
    navigate("/faq");
    window.scrollTo(0, 0);
  };

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        paddingY: { xs: 5, sm: 10 },
        paddingX: { xs: 3, sm: 15 },
      }}
    >
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} sm={6}>
          <Typography marginY={2} variant="h3" gutterBottom>
            Start Your Exchange Today!
          </Typography>
          <Typography
            marginY={3}
            variant="body1"
            sx={{ fontSize: 18 }}
            gutterBottom
          >
            Buy, sell, and exchange cryptocurrencies instantly on our secure
            platform.
          </Typography>
          <Button
            variant="contained"
            sx={{
              borderRadius: "15px",
              textTransform: "none",
              color: "white",
              paddingX: 2,
              paddingY: 1,
              fontSize: 16,
              minWidth: 150,
              backgroundColor: "#000",
            }}
            onClick={moveToLearnMore}
          >
            Learn More
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ textAlign: { xs: "center", sm: "right" } }}
        >
          <img
            src={featureImage3}
            alt="Doge"
            style={{
              width: matches ? "580px" : "380px",
              borderRadius: 15,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const ContactSupport = () => {
  const navigate = useNavigate();

  const moveToContact = () => {
    navigate("/support");
    window.scrollTo(0, 0);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingY: { xs: 5, sm: 10 },
        paddingX: { xs: 3, sm: 15 },
        textAlign: "center",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Still have questions?
      </Typography>
      <Typography variant="body1" sx={{ fontSize: 18 }} gutterBottom>
        Contact our support team for further assistance.
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        sx={{
          borderRadius: "15px",
          textTransform: "none",
          borderColor: "#000",
          paddingX: 2,
          paddingY: 1,
          minWidth: 180,
          fontSize: 16,
          color: "#000",
          marginTop: 2,
        }}
        onClick={moveToContact}
      >
        Contact
      </Button>
    </Box>
  );
};

const FAQSection = () => {
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        paddingY: { xs: 5, sm: 10 },
        paddingX: { xs: 3, sm: 15 },
        textAlign: "center",
      }}
    >
      <Typography variant="h3" gutterBottom>
        Frequently Asked Questions
      </Typography>
      <Typography sx={{ fontSize: 18 }} variant="body1" gutterBottom>
        Find answers to common questions about the Exchange process and trading
        on our platform.
      </Typography>

      <Box
        sx={{
          maxWidth: { xs: "100%", sm: "60%" },
          margin: "4% auto",
        }}
      >
        <Accordion
          defaultExpanded
          sx={{
            backgroundColor: "transparent",
            borderRadius: 8,
            border: "1px solid black",
            "&:before": {
              display: "none",
            },
            "&:first-of-type": {
              borderTopLeftRadius: 45,
              borderTopRightRadius: 45,
            },
            "&:last-of-type": {
              borderBottomLeftRadius: 45,
              borderBottomRightRadius: 45,
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              backgroundColor: "transparent",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              How to Buy Cryptocurrency?
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: "transparent" }}>
            <Typography sx={{ textAlign: "left", fontSize: 18 }}>
              To buy cryptocurrency, simply sign up for an account, deposit
              funds, and navigate to the Exchange page. From there, select the
              cryptocurrency you want to buy, enter the desired amount, and
              complete the transaction.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          defaultExpanded
          sx={{
            backgroundColor: "transparent",
            borderRadius: 8,
            border: "1px solid black",
            "&:before": {
              display: "none",
            },
            "&:first-of-type": {
              borderTopLeftRadius: 45,
              borderTopRightRadius: 45,
            },
            "&:last-of-type": {
              borderBottomLeftRadius: 45,
              borderBottomRightRadius: 45,
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            sx={{
              backgroundColor: "transparent",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              How to Sell Cryptocurrency?
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: "transparent" }}>
            <Typography sx={{ textAlign: "left", fontSize: 18 }}>
              To sell cryptocurrency, log in to your account, navigate to the
              Exchange page, select the cryptocurrency you want to sell, enter
              the amount you wish to sell, and complete the transaction.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          defaultExpanded
          sx={{
            backgroundColor: "transparent",
            borderRadius: 8,
            border: "1px solid black",
            "&:before": {
              display: "none",
            },
            "&:first-of-type": {
              borderTopLeftRadius: 45,
              borderTopRightRadius: 45,
            },
            "&:last-of-type": {
              borderBottomLeftRadius: 45,
              borderBottomRightRadius: 45,
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            sx={{
              backgroundColor: "transparent",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              How to Exchange Cryptocurrency?
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: "transparent" }}>
            <Typography sx={{ textAlign: "left", fontSize: 18 }}>
              To exchange cryptocurrency, log in to your account, navigate to
              the Exchange page, select the cryptocurrency you want to exchange,
              enter the amount you wish to exchange, and complete the
              transaction.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          defaultExpanded
          sx={{
            backgroundColor: "transparent",
            borderRadius: 8,
            border: "1px solid black",
            "&:before": {
              display: "none",
            },
            "&:first-of-type": {
              borderTopLeftRadius: 45,
              borderTopRightRadius: 45,
            },
            "&:last-of-type": {
              borderBottomLeftRadius: 45,
              borderBottomRightRadius: 45,
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
            sx={{
              backgroundColor: "transparent",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              How to Withdraw Funds?
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: "transparent" }}>
            <Typography sx={{ textAlign: "left", fontSize: 18 }}>
              To withdraw funds, go to your account settings, select the
              Withdraw option, choose the desired withdrawal method, enter the
              amount, and confirm the transaction. Your funds will be
              transferred to your chosen account.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          defaultExpanded
          sx={{
            backgroundColor: "transparent",
            borderRadius: 8,
            border: "1px solid black",
            "&:before": {
              display: "none",
            },
            "&:first-of-type": {
              borderTopLeftRadius: 45,
              borderTopRightRadius: 45,
            },
            "&:last-of-type": {
              borderBottomLeftRadius: 45,
              borderBottomRightRadius: 45,
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
            sx={{
              backgroundColor: "transparent",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Is My Money Safe?
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: "transparent" }}>
            <Typography sx={{ textAlign: "left", fontSize: 18 }}>
              Yes, we prioritize the security of your funds. We employ
              industry-leading security measures, including encryption and cold
              storage, to ensure the safety of your money.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

const FeaturesSection = () => {
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        paddingY: { xs: 8, sm: 20 },
        paddingX: { xs: 3, sm: 15 },
        textAlign: "center",
        backgroundImage: `url(${backgroundImage2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "-10px",
        padding: "0 20px",
        zIndex: 1,
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box>
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                textAlign: { xs: "left", sm: "center" },
              }}
            >
              Buy, Sell, and Exchange Cryptocurrencies Easily
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box>
            <img
              src={coin2}
              alt="Low Fees Icon"
              style={{ width: 360, height: 360 }}
            />
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginTop: 2, fontSize: 24 }}
            >
              Low Fees for Hassle-Free Transactions
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 1, fontSize: 18 }}>
              Our Exchange service offers a wide range of cryptocurrencies,
              ensuring you have options to choose from.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box>
            <img
              src={coin23}
              style={{ width: 360, height: 360, fontSize: 24 }}
            />
            <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: 2 }}>
              Instant Transactions for Quick and Convenient Exchanges
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 1, fontSize: 18 }}>
              With our Exchange service, you can enjoy instant transactions,
              saving you time and effort.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box>
            <img
              src={coin25}
              alt="Secure Platform Icon"
              style={{ width: 360, height: 360 }}
            />
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginTop: 2, fontSize: 24 }}
            >
              Secure and Reliable Platform for Cryptocurrency Exchange
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 1, fontSize: 18 }}>
              We prioritize the security and reliability of our platform,
              ensuring your transactions are safe and seamless.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const HowToExchange = () => {
  const navigate = useNavigate();

  const moveToSignUp = () => {
    navigate("/signup");
    window.scrollTo(0, 0);
  };

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        paddingY: { xs: 8, sm: 20 },
        paddingX: { xs: 3, sm: 15 },
        textAlign: "center",
        backgroundImage: `url(${backgroundImage2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "-5px",
        padding: "0 10px",
        zIndex: 1,
      }}
    >
      <Grid container spacing={4} alignItems="center">
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography marginY={2} variant="h3" textAlign={"left"} gutterBottom>
            How to Make an Exchange on Our Platform
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              borderRadius: "15px",
              textTransform: "none",
              borderColor: "#000",
              paddingX: 2,
              paddingY: 1,
              minWidth: 180,
              fontSize: 16,
              color: "#000",
              marginTop: 2,
              fontSize: 18,
            }}
            onClick={moveToSignUp}
          >
            Get Started
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Timeline position="center">
            <Step
              title="Choose Currency"
              description="Select the currency you want to exchange and the amount."
            />
            <Step
              title="Review Details"
              description="Review the exchange details, including the current exchange rate."
            />
            <Step
              title="Confirm Exchange"
              description="Confirm the exchange and proceed to complete the transaction."
            />
            <Step
              title="Track Progress"
              description="Track the progress of your exchange in real-time until completed."
            />
          </Timeline>
        </Grid>
      </Grid>
    </Box>
  );
};

const Step = ({ title, description }) => {
  return (
    <TimelineItem sx={{ marginTop: 1 }}>
      <TimelineSeparator>
        <TimelineDot sx={{ backgroundColor: "#000" }}>
          <CheckIcon sx={{ color: "#fff" }} />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: 22 }}>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ fontSize: 18 }}>
          {description}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
};

const ExchangePage = () => {
  return (
    <>
      <Header />
      <TradeCryptoEasily />
      <ConvertCryptoSection />
      <FeaturesSection />
      <HowToExchange />
      <StartExchangeSection />
      <FAQSection />
      <ContactSupport />
      <Footer />
    </>
  );
};

export default ExchangePage;
