import React, { useState, useRef } from "react";
import {
  Card,
  CardContent,
  Avatar,
  Typography,
  Button,
  Box,
  IconButton,
  Alert,
  Snackbar,
  useTheme,
  CardActionArea,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  useMediaQuery,
  Divider,
} from "@mui/material";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import PurpleBackgroundCard from "../../assets/purpleBackgroundCard.png";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { LoadingButton } from "@mui/lab";
import { extractErrorMessage } from "../../modules/utilities/responseHandlers";
import { ALERT_TYPES } from "../../config/alerts";
import axios from "axios";
import {
  getTokenFromStorage,
  getUserFromStorage,
  updateUser,
} from "../../modules/utilities";
import { GET_CREATE_KYC } from "../../config/urls";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ScanImage from "../../assets/scan.png";
import ScanFaceImage from "../../assets/scan-face.png";

const WebcamCapture = ({ onClose, onCapture }) => {
  const webcamRef = React.useRef(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    onCapture(imageSrc);
    onClose();
  }, [webcamRef, onClose, onCapture]);

  return (
    <DialogContent>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width="100%"
      />
      <DialogActions>
        <Button onClick={capture} color="primary">
          Capture
        </Button>
      </DialogActions>
    </DialogContent>
  );
};

const IDPictureButton = ({
  handleClick = () => {},
  titleText = "",
  subText = "",
  image,
}) => {
  return (
    <Card
      variant="outlined"
      sx={{
        display: "flex",
        alignItems: "center",
        borderRadius: "10px",
        boxShadow: "none",
        border: "1px solid rgba(0, 0, 0, 0.23)",
        marginTop: 2,
        width: { sx: "100%", sm: "65%" },
      }}
    >
      <CardActionArea
        onClick={handleClick}
        sx={{ display: "flex", flexGrow: 1, alignItems: "center" }}
      >
        <IconButton sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}>
          <img src={image} alt="Scan" style={{ width: 24, height: 24 }} />
        </IconButton>
        <CardContent
          sx={{ flexGrow: 1, "&:last-child": { paddingBottom: "16px" } }}
        >
          <Typography variant="subtitle1" component="div">
            {titleText}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {subText}
          </Typography>
        </CardContent>
        <IconButton
          sx={{ color: "rgba(0, 0, 0, 0.54)", pointerEvents: "none" }}
        >
          <ChevronRightIcon />
        </IconButton>
      </CardActionArea>
    </Card>
  );
};

const IDFileUploadButton = ({
  handleClick = () => {},
  titleText = "",
  subText = "",
  image,
  selectedFile = null,
}) => {
  const fileInputRef = useRef(null);

  const handleCardClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Card
      variant="outlined"
      sx={{
        display: "flex",
        alignItems: "center",
        borderRadius: "10px",
        boxShadow: "none",
        border: "1px solid rgba(0, 0, 0, 0.23)",
        marginTop: 2,
        width: { sx: "100%", sm: "65%" },
      }}
    >
      <CardActionArea
        onClick={handleCardClick} // Add the onClick handler here
        sx={{ display: "flex", flexGrow: 1, alignItems: "center" }}
      >
        <input
          type="file"
          hidden
          accept=".pdf,image/*"
          onChange={handleClick}
          ref={fileInputRef} // Attach the ref here
        />
        <IconButton sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}>
          <img src={image} alt="Scan" style={{ width: 24, height: 24 }} />
        </IconButton>
        <CardContent
          sx={{ flexGrow: 1, "&:last-child": { paddingBottom: "16px" } }}
        >
          <Typography variant="subtitle1" component="div">
            {selectedFile ? "File Selected" : titleText}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {selectedFile ? selectedFile.name : subText}
          </Typography>
        </CardContent>
        <IconButton
          sx={{ color: "rgba(0, 0, 0, 0.54)", pointerEvents: "none" }}
        >
          <ChevronRightIcon />
        </IconButton>
      </CardActionArea>
    </Card>
  );
};

const CenteredUploadBox = ({ handleFileChange, handleTakePhoto, file }) => (
  <Box
    sx={{
      margin: "0 auto",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    }}
  >
    <Typography sx={{ paddingTop: 3, color: "#fff" }} variant="h5" gutterBottom>
      Upload proof of your identity
    </Typography>
    <Typography
      variant="body2"
      sx={{ color: "#fff", fontSize: 11 }}
      gutterBottom
    >
      Verify your profile
    </Typography>

    <IDFileUploadButton
      titleText="Take a picture of your valid ID (Max Size 3 M.B)"
      subText="To check your personal information is correct"
      handleClick={handleFileChange}
      image={ScanImage}
      selectedFile={file}
    />

    <IDPictureButton
      titleText="Take a selfie of yourself"
      subText="To match your face with your ID photo"
      handleClick={handleTakePhoto}
      image={ScanFaceImage}
    />
  </Box>
);

const ProfileKYC = () => {
  const [localStorageUser, setLocalStorageUser] = useState(
    getUserFromStorage()
  );
  const [imageData, setImageData] = useState(null);
  const [file, setFile] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [open, setOpen] = useState(false); // boolean that controls the dialog
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [avatarUrl, setAvatarUrl] = useState(
    localStorageUser?.user?.profile?.kyc?.image_url || ""
  );

  const handleTakePhoto = async () => {
    setOpen(true);
  };

  const handleCapture = (imgSrc) => {
    setImageData(imgSrc);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size <= 3145728) {
      // 3 MB in bytes
      setFile(file);
    } else {
      alert("File is too large. Max size is 3MB.");
    }
  };

  const handleSubmit = async () => {
    if (!file || !imageData) {
      setMessage("Both a file and an image capture are required.");
      setSeverity("error");
      setOpenAlert(true);
      return;
    }

    setIsSaving(true);
    const formData = new FormData();
    formData.append("valid_id", file);
    formData.append("selfie", imageData);

    try {
      const token = getTokenFromStorage();
      const res = await axios.post(GET_CREATE_KYC, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const user = res?.data?.detail?.data;
      updateUser(user);
      setAvatarUrl(`${user.profile.kyc.image_url}&max=${Math.random()}`);
      setLocalStorageUser(getUserFromStorage());
      setMessage("KYC Uploaded Successfully");
      setSeverity("success");
      setOpenAlert(true);
    } catch (error) {
      let message = extractErrorMessage(error);
      setMessage(message || "Failed to submit KYC data");
      setSeverity("error");
      setOpenAlert(true);
    } finally {
      setIsSaving(false);
    }
  };

  const handleGoBack = () => {
    navigate(-1); // This will navigate back to the previous page
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        p: { xs: 1, sm: 2, md: 3 },
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          backgroundImage: `url(${PurpleBackgroundCard})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          paddingY: 3,
        }}
      >
        <Card
          sx={{
            width: isMobile ? "calc(100% - 32px)" : "80%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(10px)",
            borderRadius: "30px",
            border: "1px solid #fff",
            overflow: "hidden",
            margin: isMobile ? "8px auto" : "auto",
          }}
        >
          {/* Close button */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              px: 2,
              py: 2,
              mb: { xs: 2, sm: 5 },
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "#fff",
                flexGrow: 1,
                textAlign: { xs: "left", sm: "center" },
                fontSize: 18,
              }}
            ></Typography>
            <IconButton
              onClick={handleGoBack}
              sx={{ color: "#fff", marginRight: { xs: 3, sm: 5, md: 10 } }}
            >
              <HighlightOffTwoToneIcon />
            </IconButton>
          </Box>
          <CardContent
            sx={{
              flex: 1,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingX: { xs: 2, sm: 30, md: 30 }, // Increased horizontal padding
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "100%",
                width: "100%",
              }}
            >
              {/* Avatar and Name */}
              <Box display="flex" alignItems="center" mb={1}>
                <Avatar src={avatarUrl} sx={{ width: 60, height: 60, mr: 2 }} />
                <Box>
                  <Typography variant="h5" sx={{ color: "#fff", fontSize: 18 }}>
                    {`${localStorageUser?.user?.last_name || ""}`}{" "}
                    {`${localStorageUser?.user?.first_name || ""}`}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: localStorageUser?.user?.profile?.kyc?.verified
                        ? "#4CAF50"
                        : "#F44336",
                    }}
                  >
                    {localStorageUser?.user?.profile?.kyc?.verified
                      ? "Verified"
                      : "Requires Verification"}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" alignItems="center" mt={3} mb={3}>
                <Box>
                  <Typography variant="h5" sx={{ color: "#fff", fontSize: 24 }}>
                    KYC Verification
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#fff", fontSize: 11 }}
                  >
                    Please follow the steps and provide the requested documents
                    below.
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Typography sx={{ fontSize: 13, color: "#fff" }}>
                  Customer Information
                </Typography>
                <Divider color={"#fff"} />
              </Box>

              <CenteredUploadBox
                handleFileChange={handleFileChange}
                handleTakePhoto={handleTakePhoto}
                file={file}
              />

              <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Take a Selfie</DialogTitle>
                <WebcamCapture
                  onClose={() => setOpen(false)}
                  onCapture={handleCapture}
                />
              </Dialog>

              {/* Display captured image */}
              {imageData && (
                <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
                  <img
                    src={imageData}
                    alt="Captured"
                    style={{
                      maxWidth: "100%",
                      maxHeight: 200,
                      objectFit: "contain",
                    }}
                  />
                </Box>
              )}

              {/* Save button */}
              {file && imageData && (
                <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
                  <LoadingButton
                    variant="contained"
                    sx={{
                      display: "block",
                      mt: 1,
                      textTransform: "none",
                      backgroundColor: "#0F172A",
                    }}
                    onClick={handleSubmit}
                    loading={isSaving}
                  >
                    Save and Submit
                  </LoadingButton>
                </Box>
              )}

              {/* Info text */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  mt: 4,
                  width: { xs: "100%", sm: "70%", md: "60%" },
                  margin: "4% auto",
                }}
              >
                <InfoOutlinedIcon
                  sx={{ fontSize: 12, mr: 1, color: "#000", mt: "2px" }}
                />
                <Typography
                  variant="caption"
                  sx={{ color: "#000", fontSize: 10, lineHeight: 1.2 }}
                >
                  We Offer A Suite Of Quick Conversion Of Crypto To Cash And
                  Vice Versa, Charges Range From As Low As 2%-4% Depending On
                  Volume. You Can Access The Exchange/Transfer After
                  Verification
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      {/* Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProfileKYC;
