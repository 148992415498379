import React, { useState } from "react";
import {
  Card,
  CardContent,
  Avatar,
  Typography,
  TextField,
  Box,
  IconButton,
  Alert,
  Tabs,
  Tab,
  Snackbar,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import PurpleBackgroundCard from "../../assets/purpleBackgroundCard.png";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { LoadingButton } from "@mui/lab";
import { extractErrorMessage } from "../../modules/utilities/responseHandlers";
import { ALERT_TYPES } from "../../config/alerts";
import axios from "axios";
import {
  getTokenFromStorage,
  getUserFromStorage,
  updateUser,
} from "../../modules/utilities";
import { PROFILE_URL } from "../../config/urls";
import { useNavigate } from "react-router-dom";

const CustomTextField = ({ value, onChange, ...props }) => (
  <TextField
    fullWidth
    margin="normal"
    type="password"
    value={value}
    onChange={onChange}
    sx={{
      "& .MuiInputBase-root": {
        height: "40px", // Adjust this value to change the overall height
      },
      "& .MuiInputBase-input": {
        fontSize: "14px", // Adjust this value to change the font size
        padding: "10px 14px", // Adjust padding to vertically center the text
      },
      "& .MuiInputLabel-root": {
        fontSize: "14px", // Adjust label font size
        transform: "translate(14px, 12px) scale(1)", // Adjust label position
      },
      "& .MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.75)", // Adjust shrunk label position
      },
    }}
    {...props}
  />
);

const ProfileSettingsCard = () => {
  const localStorageUser = getUserFromStorage();
  const [tabValue, setTabValue] = useState(0);
  const [formData, setFormData] = useState({
    password: "",
    confirm_password: "",
    first_name: localStorageUser?.user?.first_name,
    last_name: localStorageUser?.user?.last_name,
    email: localStorageUser?.user?.email,
  });
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const token = getTokenFromStorage();
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const onFormSubmit = (event) => {
    const payload = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
    };

    if (!formData?.first_name || !formData?.last_name) {
      setMessage("Please fill all relevant fields");
      setSeverity("error");
      setOpen(true);
      return;
    }

    setIsLoading(true);
    axios
      .put(PROFILE_URL, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setIsLoading(false);
          setSeverity(ALERT_TYPES.SUCCESS);
          setMessage("Updated Successful");
          setOpen(true);

          let user = response.data.detail.data;
          updateUser(user);
        } else {
          setIsLoading(false);
          setSeverity(ALERT_TYPES.ERROR);
          setMessage("Could not update profile");
          setOpen(true);
        }
      })
      .catch((error) => {
        setIsLoading(false);

        // Start with a default message
        let message = "An error occurred updating profile";

        // Check if there's a response and data, and collect all messages
        const messages = extractErrorMessage(error);

        // Join all messages into a single string if there are any
        if (messages.length > 0) {
          message = messages.join(" ");
        }

        setMessage(message);
        setSeverity(ALERT_TYPES?.ERROR);
        setOpen(true);
      });
  };

  const handleGoBack = () => {
    navigate(-1); // This will navigate back to the previous page
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        p: { xs: 1, sm: 2, md: 3 },
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          backgroundImage: `url(${PurpleBackgroundCard})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          paddingY: 3,
        }}
      >
        <Card
          sx={{
            width: isMobile ? "calc(100% - 32px)" : "80%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(10px)",
            borderRadius: "30px",
            border: "1px solid #fff",
            overflow: "hidden",
            margin: isMobile ? "8px auto" : "auto",
          }}
        >
          {/* Close button */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              px: 2,
              py: 2,
              mb: { xs: 2, sm: 5 },
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "#fff",
                flexGrow: 1,
                textAlign: { xs: "left", sm: "center" },
                fontSize: 18,
              }}
            >
              Accounts
            </Typography>
            <IconButton
              onClick={handleGoBack}
              sx={{ color: "#fff", marginRight: { xs: 3, sm: 5, md: 10 } }}
            >
              <HighlightOffTwoToneIcon />
            </IconButton>
          </Box>
          <CardContent
            sx={{
              flex: 1,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingX: { xs: 2, sm: 30, md: 30 }, // Increased horizontal padding
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "100%",
                width: "100%",
              }}
            >
              {/* Avatar and Name */}
              <Box display="flex" alignItems="center" mb={1}>
                <Avatar
                  src={`${
                    localStorageUser?.user?.profile?.kyc?.image_url || ""
                  }`}
                  sx={{ width: 60, height: 60, mr: 2 }}
                />
                <Box>
                  <Typography variant="h5" sx={{ color: "#fff", fontSize: 18 }}>
                    {`${localStorageUser?.user?.last_name || ""}`}{" "}
                    {`${localStorageUser?.user?.first_name || ""}`}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: localStorageUser?.user?.profile?.kyc?.verified
                        ? "#4CAF50"
                        : "#F44336",
                    }}
                  >
                    {localStorageUser?.user?.profile?.kyc?.verified
                      ? "Verified"
                      : "Requires Verification"}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" alignItems="center" mb={3}>
                <Box>
                  <Typography variant="h5" sx={{ color: "#fff", fontSize: 14 }}>
                    Account
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#fff", fontSize: 11 }}
                  >
                    Manage your account settings
                  </Typography>
                </Box>
              </Box>

              {/* Tabs and Form */}
              <Box sx={{ width: "100%" }}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  sx={{
                    borderColor: "divider",
                    backgroundColor: "#F1F5F9",
                    marginTop: 1,
                    height: "40px",
                    width: 90,
                    paddingX: 1,
                    borderRadius: 3,
                  }}
                  indicatorColor="none"
                >
                  <Tab
                    label="Account"
                    sx={{
                      textTransform: "none",
                      color: "#000", // Changed to black
                      backgroundColor: tabValue === 0 ? "white" : "#F1F5F9",
                      fontWeight: tabValue === 0 ? "bold" : "normal",
                      textUnderline: "none",
                      borderRadius: 3,
                      width: 90,
                      height: "38px",
                      minHeight: "38px",
                      marginY: 0.5,
                    }}
                  />
                </Tabs>
                <Box
                  sx={{
                    mt: 1,
                    p: 1.5,
                    border: 1,
                    borderColor: "#E2E8F0",
                    borderRadius: "6px",
                    bgcolor: "background.paper",
                  }}
                >
                  {tabValue === 0 && (
                    <Box sx={{ mt: 0 }}>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ color: "#64748B", fontSize: 11 }}
                      >
                        Make changes to your account here. Click save when
                        you're done.
                      </Typography>
                      <CustomTextField
                        label="First Name"
                        margin="normal"
                        type={"text"}
                        value={formData.first_name}
                        name="first_name"
                        onChange={handleChange}
                      />
                      <CustomTextField
                        label="Last Name"
                        margin="normal"
                        type={"text"}
                        value={formData.last_name}
                        name="last_name"
                        onChange={handleChange}
                      />
                      <LoadingButton
                        variant="contained"
                        sx={{
                          mt: 2,
                          textTransform: "none",
                          backgroundColor: "#0F172A",
                          fontSize: 11,
                        }}
                        loading={isLoading}
                        onClick={onFormSubmit}
                      >
                        Save Changes
                      </LoadingButton>
                    </Box>
                  )}
                </Box>
              </Box>

              {/* Info text */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  mt: 4,
                  width: { xs: "100%", sm: "70%", md: "60%" },
                  margin: "4% auto",
                }}
              >
                <InfoOutlinedIcon
                  sx={{ fontSize: 12, mr: 1, color: "#000", mt: "2px" }}
                />
                <Typography
                  variant="caption"
                  sx={{ color: "#000", fontSize: 10, lineHeight: 1.2 }}
                >
                  We Offer A Suite Of Quick Conversion Of Crypto To Cash And
                  Vice Versa, Charges Range From As Low As 2%-4% Depending On
                  Volume. You Can Access The Exchange/Transfer After
                  Verification
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      {/* Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProfileSettingsCard;
