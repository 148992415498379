import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import featureImage from "../../assets/featureImage2.png";
import xanderLogo from "../../assets/xander_logo_big-removebg-preview.png";
import { Button } from "@mui/material";
import backgroundImage from "../../assets/purpleBackground.png";
import bitCoinImage from "../../assets/660c0b2116b67323a534ae57_19.png";
import dogeImage from "../../assets/coinImage20.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Header, Footer } from "../../screens/components";
import backgroundImage2 from "../../assets/660bff3ee13d3805c152fdd7_Screenshot.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const BuyCryptoNow = ({}) => {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <Box
      sx={{
        marginTop: 20,
        marginBottom: "10px",
        paddingX: { xs: 3, sm: 15 },
      }}
      maxWidth="xl"
    >
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom>
            Buy <i>Crypto</i> Now
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ fontSize: "18px" }}>
            Discover the easiest way to buy cryptocurrencies with our secure
            platform
          </Typography>
          <Box sx={{ display: "flex", gap: 2, paddingY: 2 }}>
            <Button
              variant="contained"
              sx={{
                borderRadius: "15px",
                textTransform: "none",
                color: "white",
                paddingX: 2,
                paddingY: 1,
                fontSize: 16,
                minWidth: 150,
                backgroundColor: "#000",
              }}
            >
              Get Started
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderRadius: "15px",
                textTransform: "none",
                borderColor: "#000",
                paddingX: 2,
                paddingY: 1,
                minWidth: 180,
                fontSize: 16,
                color: "#000",
              }}
            >
              Learn More
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const BuyCryptoWithEase = () => {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        paddingY: { xs: 8, sm: 20 },
        paddingX: { xs: 3, sm: 15 },
      }}
    >
      <Grid container spacing={4} alignItems="center" maxWidth={"xl"}>
        <Grid item xs={12} sm={6}>
          <Typography marginY={2} variant="h3" gutterBottom>
            Buy Crypto with <i>Ease and Confidence</i>
          </Typography>
          <Typography
            marginY={3}
            variant="body1"
            sx={{ fontSize: "18px" }}
            gutterBottom
          >
            Experience the benefits of competitive rates, a wide selection of
            cryptocurrencies, and real-time transactions when you buy crypto
            through our platform.
          </Typography>
          <Grid marginY={2} container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", fontSize: "20px" }}
              >
                Competitive Rates
              </Typography>
              <Typography variant="body2" sx={{ fontSize: 18 }}>
                Get the best rates in the market for your crypto purchases.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", fontSize: "20px" }}
              >
                Wide Selection
              </Typography>
              <Typography variant="body2" sx={{ fontSize: 18 }}>
                Choose from a diverse range of cryptocurrencies to suit your
                investment needs.
              </Typography>
            </Grid>
          </Grid>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              borderRadius: "15px",
              textTransform: "none",
              borderColor: "#000",
              paddingX: 2,
              paddingY: 1,
              minWidth: 180,
              fontSize: 18,
              color: "#000",
            }}
          >
            Learn More
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ textAlign: { xs: "center", sm: "right" } }}
        >
          <img
            src={dogeImage}
            alt="Doge"
            style={{
              width: matches ? "550px" : "350px",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const BuyCryptoWithPaymentMethods = () => {
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        paddingY: { xs: 8, sm: 20 },
        paddingX: { xs: 3, sm: 15 },
        background: "#fff",
      }}
    >
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12}>
          <Typography marginY={2} variant="h3" gutterBottom>
            Buy Crypto with <i>Various Payment Methods</i>
          </Typography>
          <Typography
            marginY={3}
            variant="body1"
            sx={{ fontSize: 18 }}
            gutterBottom
          >
            Our platform allows you to easily buy crypto using different payment
            methods such as credit/debit cards, bank transfers, and more.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} textAlign="left">
          <Typography
            variant="h2"
            sx={{ fontWeight: "bold", fontSize: { xs: "36px", sm: "48px" } }}
            gutterBottom
          >
            100%
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "18px" }}>
            Secure and Convenient Payment Options
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} textAlign="left">
          <Typography
            variant="h2"
            sx={{ fontWeight: "bold", fontSize: { xs: "36px", sm: "48px" } }}
            gutterBottom
          >
            100%
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "18px" }}>
            Access to Your Purchased Crypto
          </Typography>
        </Grid>

        <Grid item xs={12} textAlign="left" sx={{ marginTop: 4 }}>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              borderRadius: "15px",
              textTransform: "none",
              borderColor: "#000",
              paddingX: 2,
              paddingY: 1,
              minWidth: 180,
              fontSize: 16,
              color: "#000",
            }}
          >
            Learn More
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const BuyCryptoWithEase2 = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        paddingY: { xs: 1, sm: 2 },
        paddingX: { xs: 3, sm: 15 },
        background: "#fff",
      }}
    >
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} sm={6}>
          <Typography marginY={2} variant="h3" gutterBottom>
            Buy <i>Crypto</i> with Ease
          </Typography>
          <Typography
            marginY={3}
            variant="body1"
            sx={{ fontSize: "18px" }}
            gutterBottom
          >
            Discover the benefits of using our platform to buy crypto instantly.
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: { xs: "center", sm: "flex-start" } }}
          >
            <Grid item>
              <Button
                variant="contained"
                sx={{
                  borderRadius: "15px",
                  textTransform: "none",
                  backgroundColor: "#000",
                  color: "#fff",
                  paddingX: 3,
                  paddingY: 1,
                  minWidth: 120,
                  fontSize: 16,
                  "&:hover": {
                    backgroundColor: "#333",
                  },
                }}
              >
                Sign Up
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                sx={{
                  borderRadius: "15px",
                  textTransform: "none",
                  borderColor: "#000",
                  paddingX: 3,
                  paddingY: 1,
                  minWidth: 120,
                  fontSize: 16,
                  color: "#000",
                }}
              >
                Learn More
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ textAlign: { xs: "center", sm: "right" } }}
        >
          <img
            src={bitCoinImage}
            alt="Doge"
            style={{
              width: matches ? "550px" : "350px",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const BuyCryptoEasilyAndSecurely = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        backgroundImage: `url(${backgroundImage2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        paddingY: { xs: 8, sm: 20 },
        paddingX: { xs: 3, sm: 15 },
      }}
    >
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} sm={6}>
          <Typography marginY={2} variant="h3" gutterBottom>
            Buy Crypto <i>Easily and Securely</i>
          </Typography>
          <Typography
            marginY={3}
            variant="body1"
            sx={{ fontSize: "18px" }}
            gutterBottom
          >
            Our user-friendly interface makes it simple for both beginners and
            experienced traders to purchase crypto. With just a few clicks, you
            can buy your favorite cryptocurrencies and start investing in the
            future of finance.
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              borderRadius: "15px",
              textTransform: "none",
              borderColor: "#000",
              paddingX: 2,
              paddingY: 1,
              minWidth: 180,
              fontSize: 16,
              color: "#000",
            }}
          >
            Learn More
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ textAlign: { xs: "center", sm: "right" } }}
        >
          <img
            src={xanderLogo}
            alt="Doge"
            style={{
              width: matches ? "550px" : "350px",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const BuyCryptoSecurely = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        paddingY: { xs: 8, sm: 20 },
        paddingX: { xs: 3, sm: 15 },
        background: "#fff",
      }}
    >
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} sm={6}>
          <Typography marginY={2} variant="h3" gutterBottom>
            Ensuring <i>Safe</i> Transactions and Personal Data
          </Typography>
          <Typography
            marginY={3}
            variant="body1"
            sx={{ fontSize: "18px" }}
            gutterBottom
          >
            At our platform, we prioritize the security of your transactions and
            personal data. We employ state-of-the-art security measures to
            safeguard your information and ensure a safe buying experience.
          </Typography>
          <Grid container spacing={1} marginY={3}>
            <Grid item xs={12}>
              <CheckCircleIcon
                sx={{ verticalAlign: "middle", marginRight: 1 }}
              />
              <Typography
                variant="body1"
                sx={{ display: "inline", fontSize: "18px" }}
              >
                Advanced Encryption Technology
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CheckCircleIcon
                sx={{ verticalAlign: "middle", marginRight: 1 }}
              />
              <Typography
                variant="body1"
                sx={{ display: "inline", fontSize: "18px" }}
              >
                Secure Payment Gateways
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CheckCircleIcon
                sx={{ verticalAlign: "middle", marginRight: 1 }}
              />
              <Typography
                variant="body1"
                sx={{ display: "inline", fontSize: "18px" }}
              >
                Two-Factor Authentication
              </Typography>
            </Grid>
          </Grid>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              borderRadius: "15px",
              textTransform: "none",
              borderColor: "#000",
              paddingX: 2,
              paddingY: 1,
              minWidth: 180,
              fontSize: 16,
              color: "#000",
            }}
          >
            Learn More
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ textAlign: { xs: "center", sm: "right" } }}
        >
          <img
            src={featureImage}
            alt="Doge"
            style={{
              width: matches ? "580px" : "380px",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const FAQSection = () => {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        paddingY: { xs: 8, sm: 20 },
        paddingX: { xs: 3, sm: 15 },
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <Typography marginY={2} variant="h3" gutterBottom>
            FAQs
          </Typography>
          <Typography
            marginY={3}
            variant="body1"
            sx={{ fontSize: "18px" }}
            gutterBottom
          >
            Learn how to buy crypto on our platform and take advantage of its
            benefits.
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              borderRadius: "15px",
              textTransform: "none",
              borderColor: "#000",
              paddingX: 2,
              paddingY: 1,
              minWidth: 180,
              fontSize: 16,
              color: "#000",
              marginTop: 2,
            }}
          >
            Contact
          </Button>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              borderRadius: 2,
              padding: 3,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              How to Buy Crypto
            </Typography>
            <Typography variant="body2" gutterBottom>
              To buy crypto on our platform, simply sign up, deposit funds, and
              choose the cryptocurrency you want to purchase. You can then
              proceed to complete the transaction and receive your crypto in
              your wallet.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Is It Safe?
            </Typography>
            <Typography variant="body2" gutterBottom>
              Yes, our platform ensures the safety and security of your
              transactions. We use advanced encryption technology and follow
              strict security protocols to protect your funds and personal
              information.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              What Are the Fees?
            </Typography>
            <Typography variant="body2" gutterBottom>
              Our platform charges a small fee for buying crypto, which covers
              the costs of processing the transaction and maintaining the
              platform. The fee is transparent and displayed before you confirm
              your purchase.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Can I Sell Crypto?
            </Typography>
            <Typography variant="body2" gutterBottom>
              Yes, you can sell crypto on our platform. The process is similar
              to buying crypto. Simply choose the cryptocurrency you want to
              sell, and complete the transaction to receive your funds.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              What Are the Limits?
            </Typography>
            <Typography variant="body2" gutterBottom>
              The buying and selling limits on our platform vary depending on
              your account verification level. To increase your limits, you can
              complete the verification process by providing the required
              documents.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const BuyCryptoPage = () => {
  return (
    <>
      <Header />
      <BuyCryptoNow />
      <BuyCryptoWithEase />
      <BuyCryptoWithPaymentMethods />
      <BuyCryptoEasilyAndSecurely />
      <BuyCryptoSecurely />
      <FAQSection />
      <BuyCryptoWithEase2 />
      <Footer />
    </>
  );
};

export default BuyCryptoPage;
