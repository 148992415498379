import React, { useState } from "react";
import {
  TextField,
  Paper,
  Button,
  Typography,
  Link,
  useMediaQuery,
  useTheme,
  Box,
  Container,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import logoSrc from "../../assets/xfx-logo-41.png";
import axios from "axios";
import {
  FORGOT_PASSWORD_URL,
  FORGOT_PASSWORD_OTP_URL,
} from "../../config/urls";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { ALERT_TYPES } from "../../config/alerts";
import { LoadingButton } from "@mui/lab";
import { extractErrorMessage } from "../../modules/utilities/responseHandlers";
import { useNavigate } from "react-router";
import backgroundImage from "../../assets/signup_background.png";

const StyledRoot = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: "600px",
  backgroundColor: "transparent",
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(4),
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
}));

const StyledForm = styled("form")(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(3),
}));

const StyledButton = styled(LoadingButton)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: "#0F172A",
  color: theme.palette.common.white,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const StyledGoogleButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.text.primary,
  "&:hover": {
    backgroundColor: theme.palette.grey[300],
  },
}));

function ForgotPassword() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [step, setStep] = useState(1); // 1 = Enter Email, 2 = Enter OTP and Reset Password
  const [formData, setFormData] = useState({
    email: "",
    otp_code: "",
    password: "",
    confirm_password: "",
  });
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSendCode = () => {
    if (!formData.email) {
      setMessage("Please provide an email");
      setSeverity(ALERT_TYPES.ERROR);
      setOpen(true);
      return;
    }

    setLoading(true);
    axios
      .post(FORGOT_PASSWORD_URL, { email: formData.email })
      .then((response) => {
        setLoading(false);
        setMessage("Reset password mail has been sent successfully.");
        setSeverity(ALERT_TYPES.SUCCESS);
        setOpen(true);
        setStep(2); // Move to next step
      })
      .catch((error) => {
        setLoading(false);
        setMessage(
          "Failed to send reset password email. Please try again later."
        );
        setSeverity(ALERT_TYPES.ERROR);
        setOpen(true);
      });
  };

  const handleResetPassword = () => {
    if (
      !formData.otp_code ||
      !formData.password ||
      !formData.confirm_password
    ) {
      setMessage("Please fill all fields");
      setSeverity(ALERT_TYPES.ERROR);
      setOpen(true);
      return;
    }

    if (formData.password !== formData.confirm_password) {
      setMessage("Passwords do not match");
      setSeverity(ALERT_TYPES.ERROR);
      setOpen(true);
      return;
    }

    setLoading(true);
    axios
      .post(FORGOT_PASSWORD_OTP_URL, {
        email: formData.email,
        otp_code: formData.otp_code,
        password: formData.password,
      })
      .then((response) => {
        setLoading(false);
        setMessage("Password reset successfully. Redirecting to login...");
        setSeverity(ALERT_TYPES.SUCCESS);
        setOpen(true);
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Failed to reset password. Please try again later.");
        setSeverity(ALERT_TYPES.ERROR);
        setOpen(true);
      });
  };

  const handleResendCode = () => {
    setLoading(true);
    axios
      .post(FORGOT_PASSWORD_URL, { email: formData.email })
      .then((response) => {
        setLoading(false);
        setMessage("Reset password mail has been sent successfully.");
        setSeverity(ALERT_TYPES.SUCCESS);
        setOpen(true);
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Failed to resend OTP. Please try again later.");
        setSeverity(ALERT_TYPES.ERROR);
        setOpen(true);
      });
  };

  return (
    <StyledRoot>
      <CssBaseline />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          md={6}
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            justifyContent: "flex-end",
            height: "100%",
            backgroundImage: `url(${logoSrc})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "471px 471px",
            backgroundPosition: "center",
          }}
        >
          <Typography sx={{ color: "#000", textAlign: "center" }}>
            Lets be sure its you!
          </Typography>
          <Typography sx={{ color: "#000", p: 5, textAlign: "center" }}>
            "Remember a calm mind is more powerful than an anxious one"
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
            marginY: "auto",
          }}
        >
          {!isMobile && (
            <Link
              href="/signup"
              underline="hover"
              sx={{
                position: "absolute",
                top: theme.spacing(2),
                right: theme.spacing(2),
                color: "#000",
                fontWeight: "bold",
              }}
            >
              Sign Up
            </Link>
          )}
          <StyledBox sx={{ paddingY: isMobile ? 5 : 15, paddingX: 5 }}>
            {isMobile && (
              <div>
                <img
                  src={logoSrc}
                  alt="Logo"
                  style={{ width: 150, height: 150 }}
                />
              </div>
            )}
            <Typography component="h4" variant="h4" sx={{ fontWeight: "bold" }}>
              Forgot Password
            </Typography>
            <Typography
              component="p"
              variant="subtitle"
              sx={{ color: "#475569" }}
            >
              Enter your email we will send you an OTP Code
            </Typography>
            <StyledForm noValidate>
              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                disabled={step == 2}
                value={formData.email}
                onChange={handleChange}
                autoComplete="email"
                autoFocus
              />
              {step == 2 && (
                <TextField
                  variant="outlined"
                  required
                  margin="dense"
                  fullWidth
                  id="otp_code"
                  label="O.T.P Code"
                  value={formData.otp_code}
                  onChange={handleChange}
                  name="otp_code"
                  autoComplete="otpCode"
                  autoFocus
                />
              )}
              {step == 2 && (
                <TextField
                  variant="outlined"
                  required
                  margin="dense"
                  fullWidth
                  id="password"
                  label="Password"
                  value={formData.password}
                  onChange={handleChange}
                  name="password"
                  type="password"
                  autoComplete="password"
                  autoFocus
                />
              )}
              {step == 2 && (
                <TextField
                  variant="outlined"
                  required
                  margin="dense"
                  fullWidth
                  id="confirmPassword"
                  type="password"
                  label="Confirm Password"
                  value={formData.confirm_password}
                  onChange={handleChange}
                  name="confirm_password"
                  autoComplete="confirm_password"
                  autoFocus
                />
              )}

              {step == 1 && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <StyledButton
                    sx={{ textTransform: "none", width: "100%", marginY: 1 }}
                    onClick={handleSendCode}
                    loading={isLoading}
                    variant="contained"
                  >
                    Send Reset Code
                  </StyledButton>
                </div>
              )}
              {step == 2 && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <StyledButton
                    sx={{ textTransform: "none", width: "100%", marginY: 1 }}
                    onClick={handleResetPassword}
                    loading={isLoading}
                    variant="contained"
                  >
                    Reset Password
                  </StyledButton>
                </div>
              )}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <LoadingButton
                  underline="none"
                  sx={{ color: "#000", textTransform: "none" }}
                  variant="body2"
                  onClick={handleResendCode}
                >
                  Didn't get a code? Click here to resend
                </LoadingButton>
              </div>
            </StyledForm>
          </StyledBox>
        </Grid>
      </Grid>
    </StyledRoot>
  );
}

export default ForgotPassword;
